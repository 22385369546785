import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useLayoutContext } from '../contexts/layoutContext';

gsap.registerPlugin(ScrollTrigger);

function useScrollEnter({
  ref,
  onEnter = () => {},
  onLeaveBack = () => {},
  onEnterBack = () => {},
  onLeave = () => {},
  onUpdate = (e) => {},
  id = 'scrollTrigger',
  once = false,
  triggerPos = 'bottom-=50px',
  triggerPosBot = 'top',
}) {
  const refreshTO = useRef(false);
  const timeline = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);

  const initTO = useRef(false);

  useEffect(() => {
    if (!ref?.current) return;
    if (hasMounted) return;

    // initTimeline();
    if (initTO.current) clearTimeout(initTO.current);
    initTO.current = setTimeout(() => {
      initTimeline();
    }, 10);
  }, []);

  // }, [ref, onEnter, onLeave, hasMounted, setHasMounted]);

  const initTimeline = () => {
    ScrollTrigger.update();
    setHasMounted(true);

    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: id,
        start: `top ${triggerPos}`,
        end: `bottom ${triggerPosBot}`,
        trigger: ref.current,
        toggleActions: 'play none none none',
        scrub: false,
        once: once,
        onEnter: () => onEnter(),
        onEnterBack: () => onEnterBack(),
        onLeave: () => onLeave(),
        onLeaveBack: () => onLeaveBack(),
        onUpdate: (e) => onUpdate(e),
      },
    });
  };

  useEffect(() => {
    // recalculate scroll positions after page has loaded
    // refreshTO.current = setTimeout(() => {
    //   ScrollTrigger.refresh();
    // }, 3000);

    return () => {
      if (initTO.current) clearTimeout(initTO.current);
      if (refreshTO.current) clearTimeout(refreshTO.current);
      const scrollTriggerRef = ScrollTrigger.getById(id);
      if (scrollTriggerRef) scrollTriggerRef.kill(true);
      if (timeline.current) timeline.current.kill();
    };
  }, []);
}

export default useScrollEnter;
