import { css } from '@emotion/react';

export const chatBaseStyles = (theme) => css`
  background: ${theme.palette.supply.salmon.main};
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 3rem;
  ${theme.breakpoints.up('md')} {
    height: 89vh;
  }

  .disclaimer {
    display: none;
    margin-top: auto;
    ${theme.breakpoints.up(theme.breakpoints.values.md)} {
      display: block;
    }
  }

  .msg-container {
    display: flex;
    flex-direction: column;
  }

  > div h5 {
    ${theme.breakpoints.down('sm')} {
      text-align: center;
    }
  }

  .disabled-chat-copy {
    a:visited {
      color: inherit;
    }
  }

  .em-send-btn-container {
    &:nth-of-type(2) {
      margin-left: auto;
      ${theme.breakpoints.down('sm')} {
        margin-top: 2rem;
      }
    }
  }

  .chat-input {
    fieldset {
      border: 2px solid;
    }
    .MuiOutlinedInput-root {
      border-radius: 16px;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #254a45;
    }
  }

  .mobile-btn-cont {
    align-self: center;
    text-align: right;
  }

  .mobile-chat-btn {
    background-color: ${theme.palette.text.primary};
    img {
      width: 20px;
      transform: rotate(180deg);
    }
  }

  .message-container {
    padding: 0 1.25rem;
    ${theme.breakpoints.up('sm')} {
      padding: 0 2.5rem;
    }
    ${theme.breakpoints.up('md')} {
      padding: 0 6rem;
    }
  }

  .message-interior {
    border: 2px solid;
    border-radius: 20px;
    overflow: auto;
    margin: 25px 0 0;
    height: 33vh;
    ${theme.breakpoints.up('sm')} {
      margin: 40px 0 0;
    }
    ${theme.breakpoints.up('md')} {
      height: calc(80vh - 195px);
    }
  }

  .loading-messages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .send-msg-btn {
    height: 69px;
    ${theme.breakpoints.up('sm')} {
      margin-top: 14px;
    }
  }

  .message-input-container {
    margin: 0;
    padding-bottom: 4.6rem;
    ${theme.breakpoints.up('sm')} {
      padding-bottom: 0;
      margin-top: 3rem;
    }
    form {
      margin: 2rem 0 2.5rem;
      width: 100%;
    }
  }

  .email-side-container {
    padding: 1rem 0.5rem 0;
    ${theme.breakpoints.up('md')} {
      padding: 1rem 2rem 0;
    }
    ${theme.breakpoints.up('md')} {
      height: calc(80vh + 84px);
      padding: 4.6rem 6rem 2.4rem;
    }
  }

  .email-btn {
    margin-top: auto;
    margin-bottom: 1.8rem;
    &.email-md {
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
      ${theme.breakpoints.up('tablet')} {
        margin-top: 3rem;
        margin-bottom: 0;
      }
    }
    &.email-sm {
      ${theme.breakpoints.up('md')} {
        display: none;
      }
    }
  }

  .close-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    ${'' /* ${theme.breakpoints.up('sm')} {
      right: -2rem;
      top: 51px;
      position: static;
    } */}
  }

  .slide-styles {
    overflow-x: hidden;
  }

  .email-side-interior {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    ${theme.breakpoints.up('md')} {
      text-align: left;
    }

    h5 {
      ${theme.breakpoints.down('sm')} {
        font-size: 2.3rem;
        margin-top: 1rem;
      }
      margin-bottom: 1rem;
      ${theme.breakpoints.up('tabletLandscape')} {
        margin-top: 2.75rem;
      }
    }

    p {
      text-align: center;
      ${theme.breakpoints.up('md')} {
        text-align: left;
      }
    }
  }
`;

export const customerMsgStyles = css`
  justify-content: end;
  margin: 1rem 2rem 1rem 1.5rem;
  max-width: 75%;
  line-height: 1;

  .sent-at {
    font-size: 0.7rem;
    line-height: 1;
    padding-bottom: 2px;
    display: inline-block;
  }
`;

export const agentMsgStyles = (theme) => css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1rem;
  align-items: flex-end;
  margin-left: auto;
  margin-bottom: 2rem;

  span {
    line-height: 1;
  }

  .agent-caption {
    font-size: 0.7rem;
    padding-left: 1rem;
  }

  .intro-msg {
    line-height: 1;
    position: relative;
    padding: 1rem;
    ${theme.breakpoints.up('sm')} {
      max-width: 80%;
    }
    &:after {
      content: '';
      background-color: ${theme.palette.text.primary};
      position: absolute;
      bottom: 0;
      left: 1rem;
      width: 50%;
      height: 2px;
    }
  }
`;

export const agentPhotoStyles = (theme, { photoUrl }) => css`
  background-position: center;
  // background-size: 35px;
  background-size: auto 100%;
  background-image: url(${photoUrl});
  background-repeat: no-repeat;
  height: 25px;
  width: 30px;
  display: flex;
  align-items: center;
  margin-left: 2px;
`;

export const agentPhotoContainer = css`
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-direction: row-reverse;
  span {
    padding-left: 0.5rem;
    padding-bottom: 2px;
  }
`;
