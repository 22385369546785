import { useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import ChatContainer from './chatContainer';
import styles from './chatButton.styles';

const ChatButton = ({ showChatModal, onClose, onOpen }) => {
  useEffect(() => {
    if (showChatModal) {
      document.documentElement.style.overflow = 'hidden';
    }
    return () => (document.documentElement.style.overflow = 'unset');
  }, [showChatModal]);

  return (
    <div css={styles}>
      <Button variant="contained" onClick={onOpen} className="chat-button">
        <Typography variant="body1"></Typography>Talk to&nbsp;
        <span className="btn-italic-text">a real person</span>
      </Button>
      {showChatModal && (
        <ChatContainer showChatModal={showChatModal} onCloseChat={onClose} />
      )}
    </div>
  );
};

export default ChatButton;
