import React, { useMemo } from 'react';
import { Dialog, Zoom, Box, Typography, Grid, Button } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
// import { closeChatBtnStyles } from '../../components/chat/chatButton.styles';
import styles from './orgsSupported.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const OrgsSupportDialog = ({ showOrgsModal, onClose }) => {
  const { contentfulGivingBackSection } = useStaticQuery(graphql`
    query ModalContentQuery {
      contentfulGivingBackSection {
        orgsModalTitle
        orgsModalCopy {
          orgsModalCopy
        }
        orgsModalLogos {
          description
          file {
            url
          }
        }
      }
    }
  `);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom in={showOrgsModal} ref={ref} {...props} />;
  });

  const theme = useTheme();
  const showSm = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm)
  );
  const showMd = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  const noOfCols = useMemo(() => (showMd ? 3 : showSm ? 2 : 1), [
    showSm,
    showMd,
  ]);

  const noOfRows = Math.ceil(
    contentfulGivingBackSection.orgsModalLogos.length / noOfCols
  );

  return (
    <Dialog
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.85)' },
      }}
      open={showOrgsModal}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      css={styles}
    >
      <div onClick={onClose} className="close-modal">
        <img src="/images/minimal-closechaticon.svg" />
      </div>
      <div className="main-content">
        <Grid
          container
          className="inner-top"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Typography variant="h4">
              {contentfulGivingBackSection.orgsModalTitle}
            </Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {contentfulGivingBackSection.orgsModalCopy.orgsModalCopy}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className="grid-img-grid"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* {contentfulGivingBackSection.orgsModalLogos.map((image) => (
          <Grid
            item
            xs={12}
            sm={12 / gridCols}
            key={image.file.url}
            className="grid-img-wrap"
          >
            <a href={image.description} target="_blank">
              <img src={image.file.url} className="grid-img" />
            </a>
          </Grid>
        ))} */}
          {[...Array(noOfRows)].map((o, i) =>
            [...Array(noOfCols)].map((o, j) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                // key={image.file.url}
                key={`orgs-grid-row-${i}-col${j}`}
                className={`grid-img-wrap ${
                  i === noOfRows - 1 ? 'last-row' : ''
                }`}
              >
                <div className="content">
                  {contentfulGivingBackSection.orgsModalLogos[
                    j + i * noOfCols
                  ] ? (
                    <a
                      href={
                        contentfulGivingBackSection.orgsModalLogos[
                          j + i * noOfCols
                        ].description
                      }
                      target="_blank"
                    >
                      <img
                        src={
                          contentfulGivingBackSection.orgsModalLogos[
                            j + i * noOfCols
                          ].file.url
                        }
                      />
                    </a>
                  ) : null}
                </div>
              </Grid>
            ))
          )}
        </Grid>
      </div>
    </Dialog>
  );
};

export default OrgsSupportDialog;
