import { css, keyframes } from '@emotion/react';

export const styles = (theme) => css`
  display: flex;
  position: relative;
  width: 80%;
  aspect-ratio: 1/0.75;
  margin: ${theme.spacing(4, 'auto', 0)};

  ${theme.breakpoints.up('sm')} {
    width: 50%;
  }
  ${theme.breakpoints.up('md')} {
    width: 100%;
    margin: ${theme.spacing(0)};
  }

  .circle,
  .triangle,
  .square {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 42%;
    transition: all 250ms ${theme.transitions.easing.outBack};
    transform-origin: center center;
    aspect-ratio: 1/1;

    .tick,
    p,
    span {
      z-index: 10;
    }

    p {
      font-size: 1.4rem;
      ${theme.breakpoints.up('md')} {
        font-size: 1.6rem;
      }
    }
  }

  .tick {
    width: 30px;
    margin: ${theme.spacing(0.6, 0)};
    flex-shrink: 0;

    path {
      transition: all 250ms ${theme.transitions.easing.outCubic};
      stroke-dasharray: 88px;
      stroke-dashoffset: 88px;
    }

    ${theme.breakpoints.up('sm')} {
      margin: ${theme.spacing(1, 0)};
      width: 70px;
    }
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(1, 0)};
      width: 50px;
    }
    ${theme.breakpoints.up('lg')} {
      margin: ${theme.spacing(1.25, 0)};
      width: 70px;
    }
  }

  .circle {
    background-color: ${theme.palette.supply.eraser.main};
    transform: translateX(-80%) rotate(-14deg) scale(0.8);
    opacity: 0;
    top: 10%;
    transition-delay: 0s;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    left: 50%;
    margin-left: -70px;

    .tick {
      path {
        transition-delay: 0.15s;
      }
    }

    ${theme.breakpoints.up('sm')} {
      width: 180px;
      height: 180px;
      margin-left: -90px;
    }

    ${theme.breakpoints.up('md')} {
      width: 160px;
      height: 160px;
      margin-left: -80px;
    }

    ${theme.breakpoints.up('lg')} {
      width: 180px;
      height: 180px;
      margin-left: -90px;
    }
  }

  .triangle {
    transform: rotate(12deg) scale(0.8);
    opacity: 0;
    left: 25%;
    top: 40px;
    transition-delay: 0.15s;
    width: ${230 * 0.5}px;
    height: ${200 * 0.5}px;
    left: 50%;
    margin-left: -${230 * 0.25}px;

    .title {
      margin-top: ${theme.spacing(8)};
    }

    .tick {
      margin path {
        transition-delay: 0.3s;
      }
    }
    .bg {
      position: absolute;
      top: 0;
      left: -10%;
      width: 120%;
      z-index: 0;
    }

    ${theme.breakpoints.up('sm')} {
      top: 90px;
      width: ${230 * 0.8}px;
      height: ${200 * 0.8}px;
      margin-left: -${230 * 0.4}px;
      .title {
        margin-top: ${theme.spacing(10)};
      }
    }

    ${theme.breakpoints.up('md')} {
      top: 90px;
      width: ${230 * 0.7}px;
      height: ${200 * 0.7}px;
      margin-left: -${230 * 0.35}px;
      .title {
        margin-top: ${theme.spacing(8)};
      }
    }

    ${theme.breakpoints.up('lg')} {
      width: ${230 * 0.8}px;
      height: ${200 * 0.8}px;
      margin-left: -${230 * 0.4}px;
      top: 90px;
      .title {
        margin-top: ${theme.spacing(10)};
      }
    }
  }

  .square {
    background-color: ${theme.palette.supply.green.main};
    left: 64%;
    top: 0%;
    left: 50%;
    width: 110px;
    height: 110px;
    margin-left: -50px;
    transform: translateX(80%) rotate(-20deg) scale(0.8);
    opacity: 0;
    transition-delay: 0.3s;

    .tick {
      path {
        transition-delay: 0.45s;
      }
    }

    ${theme.breakpoints.up('sm')} {
      width: 160px;
      height: 160px;
      margin-left: -80px;
    }

    ${theme.breakpoints.up('md')} {
      width: 130px;
      height: 130px;
      margin-left: -65px;
    }

    ${theme.breakpoints.up('lg')} {
      width: 160px;
      height: 160px;
      margin-left: -80px;
    }
  }
`;

export const activeStyles = (theme) => css`
  .circle {
    transform: translateX(-80%) rotate(0deg) scale(1);
    opacity: 1;
    ${theme.breakpoints.up('sm')} {
      transform: translateX(-100%) rotate(0deg) scale(1);
    }
    ${theme.breakpoints.up('md')} {
      transform: translateX(-80%) rotate(0deg) scale(1);
    }
    ${theme.breakpoints.up('lg')} {
      transform: translateX(-90%) rotate(0deg) scale(1);
    }
    transform-origin: center center;
    .tick {
      path {
        stroke-dashoffset: 0px;
      }
    }
  }
  .triangle {
    transform: rotate(0deg) scale(1);
    opacity: 1;
    .tick {
      path {
        stroke-dashoffset: 0px;
      }
    }
  }
  .square {
    transform: translateX(80%) rotate(-5deg) scale(1);
    opacity: 1;
    ${theme.breakpoints.up('sm')} {
      transform: translateX(100%) rotate(-5deg) scale(1);
    }
    ${theme.breakpoints.up('md')} {
      transform: translateX(80%) rotate(-5deg) scale(1);
    }
    ${theme.breakpoints.up('lg')} {
      transform: translateX(90%) rotate(-5deg) scale(1);
    }
    .tick {
      path {
        stroke-dashoffset: 0px;
      }
    }
  }
`;
