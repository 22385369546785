import React from 'react';
import Lottie from 'react-lottie';
import LottieArrow from '../../assets/squiggly-arrow.json';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LottieArrow,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ArrowAnimation = () => {
  const theme = useTheme();
  const medLess = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const height = medLess ? 90 : 212;
  const width = medLess ? 30 : 73;

  return <Lottie options={defaultOptions} height={height} width={width} />;
};

export default ArrowAnimation;
