import { css } from '@emotion/react';
import { alpha, darken, lighten } from '@material-ui/core';
import { arrowDiagonalOutIn } from '../../components/shared/animations.styles';
import { minimalPointerWhite } from '../../components/shared/shared.styles';

export const newChatBaseStyles = (theme) => css`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  top: 0;
  left: 0;

  .chat-body {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: ${theme.spacing(2, 2, '50px', 2)};
    transition: left 250ms ${theme.transitions.easing.outCubic};

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(4, 4, '75px', 4)};
    }
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(4, 4, '90px', 4)};
    }

    &.chat {
      left: -100%;
      &.is-active {
        left: 0%;
      }
    }

    &.email {
      overflow: auto;
      left: 100%;
      width: calc(100% - 2px);
      overflow-y: overlay;

      ::-webkit-scrollbar {
        width: 15px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
        margin: 2px 0 52px;
        ${theme.breakpoints.up('md')} {
          margin: 2px 0 92px;
        }
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${alpha(theme.palette.supply.darkGray, 0)};
        // transition: background-color 500ms ${theme.transitions.easing
          .inOutQuad};
        border-radius: 4px;
      }

      &.is-scrolling {
        ::-webkit-scrollbar-thumb {
          background-color: ${alpha(theme.palette.supply.darkGray, 1)};
        }
      }

      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.palette.supply.darkGray};
      }

      &.is-active {
        left: 0%;
      }
    }

    .message-interior {
      display: flex;
      position: relative;
      border: 1px solid ${theme.palette.supply.darkGray};
      overflow: auto;
      margin: ${theme.spacing(2, 0)};
      // height: 33vh;
      height: 100%;
      width: 100%;

      ${theme.breakpoints.up('md')} {
        margin: ${theme.spacing(4, 0)};
        overflow-y: overlay;

        ::-webkit-scrollbar {
          // width: 15px;
          width: 5px;
        }

        ::-webkit-scrollbar-track {
          background: transparent;
          // margin: 2px 0 2px 0;
        }

        ::-webkit-scrollbar-thumb {
          background-color: ${alpha(theme.palette.supply.darkGray, 0)};
          // border-radius: 4px;
        }

        &.is-scrolling {
          ::-webkit-scrollbar-thumb {
            background-color: ${alpha(theme.palette.supply.darkGray, 1)};
          }

          ::-webkit-scrollbar-thumb:hover {
            background: ${theme.palette.supply.darkGray};
          }
        }
      }

      .msg-container {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .loading-messages {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }

    .message-input-container {
      margin: 0;
      // padding-bottom: 4.6rem;
      // ${theme.breakpoints.up('sm')} {
      //   padding-bottom: 0;
      //   margin-top: 3rem;
      // }
      form {
        // margin: 2rem 0 2.5rem;
        width: 100%;
      }

      .disabled-chat-copy {
        margin-bottom: ${theme.spacing(4)};
        border: 1px solid ${theme.palette.supply.darkGray};
        padding: ${theme.spacing(2)};
        transform: rotate(-4deg);
        margin-top: -${theme.spacing(2)};
        background: ${darken(theme.palette.supply.salmon.main, 0.1)};
        ${theme.breakpoints.up('md')} {
          margin-top: -${theme.spacing(4)};
        }
      }

      .chat-input {
        pointer-events: none;
        fieldset {
          border: 1px solid ${theme.palette.supply.darkGray};
        }
        .MuiOutlinedInput-root {
          border-radius: 0px;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: ${lighten(theme.palette.supply.darkGray, 0.15)};
        }
      }

      .chat-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Whyte-Inktrap, Helvetica, sans-serif, sans-serif;
        font-size: 2rem;
        font-weight: normal;
        border: 1px solid ${theme.palette.supply.darkGray};
        border-left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        pointer-events: none;
        &:hover {
          color: ${theme.palette.supply.salmon.main};
          background-color: ${theme.palette.supply.darkGray};
          cursor: ${minimalPointerWhite};
        }
      }
    }

    .disclaimer {
      text-transform: none;
      font-size: 1rem;
      line-height: 1.6rem;
      color: ${lighten(theme.palette.supply.darkGray, 0.15)};
      margin: ${theme.spacing(2, 0)};
      ${theme.breakpoints.up('md')} {
        margin: ${theme.spacing(4, 0)};
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 50px;
    border-top: 1px solid ${theme.palette.supply.darkGray};

    ${theme.breakpoints.up('sm')} {
      height: 75px;
    }
    ${theme.breakpoints.up('md')} {
      height: 90px;
    }

    .button {
      width: 50%;
      // justify-content: space-between;
      background-color: ${theme.palette.supply.salmon.main};
      // height: 50px;
      overflow: hidden;

      &:hover {
        background-color: ${lighten(theme.palette.supply.salmon.main, 0.1)};
      }

      transform: translate(0, 0%);
      opacity: 1;

      &.close {
        .arrow-icon {
          transform: rotate(0deg);
        }
        ${theme.breakpoints.up('md')} {
          .arrow-icon {
            transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
          }
          &:hover {
            .arrow-icon {
              transform: rotate(180deg);
            }
          }
        }
      }

      &.email {
        border-right: 1px solid ${theme.palette.supply.darkGray};
        .arrow-icon {
          transform: rotate(45deg);
        }
        ${theme.breakpoints.up('md')} {
          &:hover {
            .arrow-icon {
              animation: ${arrowDiagonalOutIn} 0.5s 1 forwards;
            }
          }
        }
      }

      // ← ↑ → ↓

      ${theme.breakpoints.up('sm')} {
        height: 75px;
      }
      ${theme.breakpoints.up('md')} {
        height: 90px;
      }
    }
  }
`;

//
// message styles
//

export const newChatMsgStyles = (theme) => css`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.palette.supply.darkGray};
  padding: ${theme.spacing(2)};
`;

export const lastChatMsgStyles = (theme) => css`
  border-bottom: none;
`;

export const messageDataStyles = (theme) => css`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 20px;
  margin-bottom: ${theme.spacing(1)};
  .avatar {
    position: relative;
    width: 20px;
    height: 20px;
    margin: ${theme.spacing(0, 1, 0, 0)};
    svg {
      rect,
      circle {
        fill: ${theme.palette.supply.darkGray};

        &.border {
          fill: transparent;
          stroke: ${theme.palette.supply.darkGray};
        }
      }
    }
  }
  .label {
    font-size: 1rem;
    line-height: 1.6rem;
    color: ${lighten(theme.palette.supply.darkGray, 0.15)};
  }
`;

export const agentMessageDataStyles = (theme) => css`
  flex-direction: row-reverse;
  .avatar {
    margin: ${theme.spacing(0, 0, 0, 1)};
    background-position: center;
    background-size: auto 100%;
    background-image: url(/images/logo-chat3.png);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;

    svg {
      display: none;

      rect,
      circle {
        fill: ${theme.palette.supply.green.main};

        &.border {
          fill: transparent;
          stroke: ${theme.palette.supply.green.main};
        }
      }
    }
  }
`;

export const introMessageStyles = (theme) => css`
  background-color: ${theme.palette.supply.darkGray};
  color: ${theme.palette.supply.salmon.main};
`;

export const introMessageDataStyles = (theme) => css`
  .avatar {
    svg {
      rect,
      circle {
        fill: ${theme.palette.supply.green.main};

        &.border {
          fill: transparent;
          stroke: ${theme.palette.supply.green.main};
        }
      }
    }
  }
  .label {
    color: ${theme.palette.supply.salmon.main};
  }
`;
