import React, { useState } from 'react';

export const ChatContext = React.createContext({
  showChat: false,
  setShowChat: () => null,
});

export const ChatProvider = (props) => {
  const [showChat, setShowChat] = useState(false);

  return (
    <ChatContext.Provider
      value={{
        showChat,
        setShowChat,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const { showChat, setShowChat } = React.useContext(ChatContext);
  return {
    showChat,
    setShowChat,
  };
};
