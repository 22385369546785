import React, { useCallback, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import Tabs from './tabs';
import Accordion from './accordion';
import styles, { activeStyles, changingStyles } from './services.styles';
import ScrollContainer from '../../components/scroll-container';
import SlideInTitle from '../slidein-title';
import { useLayoutContext } from '../../contexts/layoutContext';

const Services = ({ servicesSection }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );
  const { changing } = useLayoutContext();
  const [active, setActive] = useState(false);

  return (
    <ScrollContainer
      id={2}
      styles={[
        styles,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
      onEnter={() => {
        setActive(true);
      }}
      onLeaveBack={() => {
        setActive(false);
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="flex-end"
              alignContent="flex-end"
              justifyContent={showMdAndUp ? 'flex-end' : 'flex-start'}
            >
              <Grid item md={4} xs={12} className="header">
                {/* <Typography variant="h1">{servicesSection.title}</Typography> */}
                <SlideInTitle id={2} active={active}>
                  {servicesSection.title.split(' ').map((title, index) => (
                    <Typography
                      key={`services-title-line-${index}`}
                      variant="h1"
                    >
                      {title}
                    </Typography>
                  ))}
                </SlideInTitle>
              </Grid>
              <Grid item md={3} xs={12}></Grid>
              <Grid item md={5} xs={12} className="mainCopy">
                <Typography variant="body2">
                  {servicesSection.description.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mainContent">
            {showMdAndUp ? (
              <Tabs items={servicesSection.serviceItem} />
            ) : (
              <Accordion items={servicesSection.serviceItem} />
            )}
          </Grid>
        </Grid>
      </Container>
    </ScrollContainer>
  );
};

export default Services;
