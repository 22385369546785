import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import styles, { activeStyles, changingStyles } from './partnerDesktop3.styles';
import { Grid, Typography } from '@material-ui/core';
import ScrollContainer from '../../components/scroll-container';
import { useLayoutContext } from '../../contexts/layoutContext';
import SlideInTitle from '../slidein-title';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useTransition, animated } from 'react-spring';
import { useTheme } from '@material-ui/core/styles';

gsap.registerPlugin(ScrollTrigger);

const WhoWePartnerWith = ({ content }) => {
  const theme = useTheme();
  const { changing } = useLayoutContext();
  const [active, setActive] = useState(false);
  const [section, setSection] = useState(0);
  const timeline = useRef();

  const marker1Ref = useRef();

  const requestRef = useRef();
  const [progress, setProgress] = useState(0);
  const previousTimeRef = useRef(0);
  const progressTarget = useRef(0);

  const colors = [
    theme.palette.supply.green.main,
    theme.palette.supply.eraser.main,
  ];

  const items = useMemo(() => {
    return content.talentclientToggleMinimalTheme.map((c, i) => ({
      id: i,
      title: c,
      color: colors[i],
    }));
  }, [content]);

  const fadingTextPropsTransition = useTransition(
    items[section],
    (item) => item.id,
    {
      from: {
        top: '0%',
        transform: 'translate3d(0,-100%,0)',
      },
      enter: {
        top: '0%',
        transform: 'translate3d(0,0,0)',
      },
      leave: {
        top: '0%',
        transform: 'translate3d(0,100%,0)',
      },
      config: {
        tension: 160,
        friction: 20,
        clamp: true,
        velocity: 0.026,
      },
    }
  );

  const titleParts = useMemo(() => {
    let parts = [];
    for (let i = 0; i < content.titleMinimalTheme.split(' ').length; i++) {
      let part = content.titleMinimalTheme.split(' ')[i];
      parts.push(
        <Typography key={`partner-with-title-line-${i}`} variant="h1">
          {part}
        </Typography>
      );
    }
    parts.push(
      <div className="partner_word-container">
        <div className="partner_word-inner-wrapper">
          {fadingTextPropsTransition.map(({ item, props, key }) => (
            <animated.div
              key={key}
              style={{ ...props, position: 'absolute' }}
              className="partner_fading-text"
            >
              <Typography variant="h1" style={{ color: item.color }}>
                {item.title}
              </Typography>
            </animated.div>
          ))}
        </div>
      </div>
    );
    return parts;
  }, [content, fadingTextPropsTransition]);

  const onUpdate = (e) => {
    progressTarget.current = e.progress * 2;
  };

  useEffect(() => {
    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: 'talentScroll',
        start: 'top top',
        end: 'bottom bottom',
        trigger: marker1Ref.current,
        toggleActions: 'play none none none',
        scrub: false,
        onUpdate: (e) => {
          setSection(e.progress > 0.5 ? 1 : 0);
          onUpdate(e);
        },
      },
    });

    return () => {
      cancelAnimationFrame(requestRef.current);
      const scrollTriggerRef = ScrollTrigger.getById('talentScroll');
      if (scrollTriggerRef) scrollTriggerRef.kill(true);
      if (timeline.current) timeline.current.kill();
    };
  }, []);

  const onEnter = useCallback(() => {
    setActive(true);
  }, []);

  const onLeave = useCallback(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    if (active) {
      requestRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(requestRef.current);
    }
  }, [active]);

  const animate = (time) => {
    if (!active) return;

    if (time - previousTimeRef.current > 1000 / 60) {
      setProgress(
        (prevProgress) =>
          prevProgress + (progressTarget.current - prevProgress) * 0.2
      );
      previousTimeRef.current = time;
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  const easeOutQuad = (t, b = 0, c = 1, d = 1) => {
    return -c * (t /= d) * (t - 2) + b;
  };

  const easeInOutQuad = (t, b = 0, c = 1, d = 1) => {
    if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
    return (-c / 2) * (--t * (t - 2) - 1) + b;
  };

  const relProgress = useMemo(() => {
    let p = Math.round(progress * 1000) / 1000;
    let part1 = p < 1 ? p : 1;
    let part2 = p > 1 ? p - 1 : 0;
    return [
      [part1, part2],
      [
        part1 < 1 / 3 ? part1 / (1 / 3) : 1,
        part1 < 2 / 3 ? part1 / (2 / 3) : 1,
        part1,
        // easeInOutQuad(part1 < 1 / 3 ? part1 / (1 / 3) : 1),
        // easeInOutQuad(part1 < 2 / 3 ? part1 / (2 / 3) : 1),
        // easeInOutQuad(part1),
      ],
      [
        part2 < 1 / 3 ? part2 / (1 / 3) : 1,
        part2 < 2 / 3 ? part2 / (2 / 3) : 1,
        part2,
        // easeInOutQuad(part2 < 1 / 3 ? part2 / (1 / 3) : 1),
        // easeInOutQuad(part2 < 2 / 3 ? part2 / (2 / 3) : 1),
        // easeInOutQuad(part2),
      ],
    ];
  }, [progress]);

  return (
    <ScrollContainer
      id={1}
      styles={[
        styles,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
      onEnter={onEnter}
      onEnterBack={onEnter}
      onLeave={onLeave}
      onLeaveBack={onLeave}
    >
      <div className="scroll-marker" ref={marker1Ref}></div>
      <div className="sticky-container">
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12} md={6}>
            <div className="sticky-title">
              <div>
                <SlideInTitle id={1} active={active}>
                  {titleParts.map((title, index) => (
                    <React.Fragment key={`partner-with-title-line-${index}`}>
                      {title}
                    </React.Fragment>
                  ))}
                </SlideInTitle>
              </div>
              <div
                className={`description ${
                  progress > 1 ? 'reveal-clients' : ''
                }`}
              >
                <Typography
                  variant="body2"
                  className={`partner-description talent`}
                >
                  {content.talentDescription.talentDescription}
                </Typography>
                <Typography
                  variant="body2"
                  className={`partner-description clients`}
                >
                  {content.clientDescription.clientDescription}
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={2}></Grid>

          <Grid item xs={12} md={4} className="right-container">
            {/* talent list */}

            <div
              className={`options talent`}
              style={{
                opacity: 1 - relProgress[2][0] * 2,
              }}
            >
              {content.talentItem.map((talent, i) => (
                <div
                  key={`talent-option-${i}`}
                  className={`option ${
                    relProgress[1][i] >= 0.95 ? 'active' : ''
                  }`}
                  // className={`option active`}
                  style={{
                    zIndex: 30 + content.talentItem.length - i,
                    transform: `translate3d(0, ${
                      (1 - relProgress[1][i]) * -((i + 1) * 100)
                    }%, 0)`,
                  }}
                >
                  <Typography variant="h5">{talent.jobCategory} </Typography>
                  <Typography variant="body2">
                    {talent.jobTitle.map((listItem, j) => (
                      <React.Fragment key={`work-with-part-1-${i}-${j}`}>
                        {listItem}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </div>
              ))}
            </div>

            {/* clients list */}

            <div className={`options clients`}>
              {content.clientItem.map((client, i) => (
                <div
                  key={`client-option-${i}`}
                  className={`option ${
                    relProgress[2][i] >= 0.95 ? 'active' : ''
                  }`}
                  // className={`option active`}
                  style={{
                    zIndex: 50 + content.talentItem.length - i,
                    transform: `translate3d(0, ${
                      (1 - relProgress[2][i]) * -((i + 1) * 100)
                    }%, 0)`,
                  }}
                >
                  <Typography variant="h5">{client.specialty} </Typography>
                  <Typography variant="body2">
                    {client.specialtyItem.map((listItem, j) => (
                      <React.Fragment key={`work-with-part-2-${i}-${j}`}>
                        {listItem}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </ScrollContainer>
  );
};

export default WhoWePartnerWith;
