import { memo, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useTransition, animated } from 'react-spring';

import boxStyles from './box.styles';

const styles = {
  cursor: 'grab',
  width: '630px',
  transform: 'rotate(4.5deg)',
};

export const Box = memo(function Box({ content, preview, isShown }) {
  const [index, setIndex] = useState(0);
  const [items] = useState([
    { title: '⛷ and 🍻', id: 0 },
    { title: '🏍 and 🥃', id: 1 },
    { title: '☕️ and 🏀', id: 2 },
    { title: '🛹 and 🍕', id: 3 },
    { title: '🏕 and 🥞', id: 4 },
  ]);

  const emojiPropsTransition = useTransition(items[index], (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 160,
      friction: 20,
      clamp: true,
      velocity: 0.026,
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => (state + 1) % items.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{ ...styles }}
      role={preview ? 'BoxPreview' : 'Box'}
      className={`rich-text-body-copy ${isShown || preview ? `shown` : ``}`}
      css={boxStyles}
    >
      {/* <span className="sticker-front"></span> */}
      <div className="sticker-content">
        {
          <div>
            <Typography
              variant="body1"
              // style={{ display: 'inline', marginBottom: '1rem' }}
              gutterBottom
              component="div"
            >
              {content.introPart1.introPart1} folks we
              <span
                style={{
                  position: 'relative',
                  width: '89px',
                  display: 'inline-block',
                  height: '19px',
                  marginLeft: '4px',
                }}
              >
                {emojiPropsTransition.map(({ item, props, key }) => (
                  <animated.div
                    key={key}
                    style={{
                      ...props,
                      position: 'absolute',
                      top: 0,
                      left: '2px',
                    }}
                  >
                    <Typography>{item.title}</Typography>
                  </animated.div>
                ))}
              </span>
              with, not just keywords in a search field.
            </Typography>

            {!!content.introPart2.introPart2 && (
              <Typography variant="body1">
                {content.introPart2.introPart2}
              </Typography>
            )}
          </div>
        }
      </div>
    </div>
  );
});
