import { css } from '@emotion/react';

const styles = (theme) => css`
  .chatbox-container {
    position: fixed;
    right: 0;
    bottom: -8px;
    z-index: 6;
    transform: translate(0px, 100%, 1px);
    transition: 0.5s ease-in-out;
    width: 100%;

    ${theme.breakpoints.up('sm')} {
      right: -40px;
      width: 300px;
      bottom: -75px;
    }
    ${theme.breakpoints.up('md')} {
      width: auto;
      bottom: -100px;
    }

    &.mobile-hide {
      ${theme.breakpoints.down('sm')} {
        transform: translateX(75%) !important;
        transition: 0.5s ease-in-out;
      }
    }

    .squiggly-arrow {
      position: absolute;
      top: -82px;
      right: 20px;
      z-index: 2;
      ${theme.breakpoints.up('sm')} {
        right: 50px;
      }
      ${theme.breakpoints.up('md')} {
        top: -194px;
        right: 80px;
      }
    }

    .chatbox {
      margin-left: auto;
      width: 100%;
      height: 80px;
      transform: rotate(-5.23deg) translateY(0);
      right: -33.5vw;
      position: relative;
      background-color: ${theme.palette.supply.salmon.main};
      transition: 0.25s ease-in-out;
      ${theme.breakpoints.up('sm')} {
        right: auto;
        height: 161px;
      }
      ${theme.breakpoints.up('md')} {
        height: 211px;
      }
      &:hover {
        transform: rotate(-5.23deg) translateY(10px);
        transition: 0.25s ease-in-out;
      }
    }
  }
`;

export default styles;
