import React, { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import styles from './tabs.styles';

const CustomTabs = ({ items }) => {
  const [activeTab, setActiveTab] = useState(0);

  const description = (item) => {
    let desc = item.description.description;

    if (desc.indexOf(item.linkText) > -1) {
      return (
        <>
          {desc.split(item.linkText)[0]}
          <a
            href={item.link}
            style={{ color: '#162C29' }}
            target="_blank"
            rel="noopener"
          >
            {item.linkText}
          </a>
          {desc.split(item.linkText)[1]}
        </>
      );
    }

    return item.description.description;
  };

  return (
    <div css={styles}>
      <Grid container className="leftContainer">
        <Grid item md={8} lg={9}>
          {items.map((item, index) => (
            <Button
              variant="contained"
              color="primary"
              key={`tab-${index}`}
              className={`tabButton ${activeTab === index ? 'active' : ''}`}
              onMouseEnter={() => {
                if (activeTab !== index) setActiveTab(index);
              }}
            >
              <Typography variant="h4">{item.title}</Typography>

              <div className={`iconWrapper`}>
                <div className={`icon`}>+</div>
                <div className={`icon arrow`}>→</div>
              </div>
            </Button>
          ))}
        </Grid>
        <Grid item container md={4} lg={3} className="rightContainer">
          {items.map((item, index) => (
            <div
              key={`tab-container-${index}`}
              className={`tabContents ${activeTab === index ? 'active' : ''}`}
            >
              <div className="imgWrapper">
                <img
                  className="tabImg"
                  src={item.iconImageMinimalTheme.file.url}
                />
              </div>
              <Typography variant="body1" className="tabCopy">
                {description(item)}
              </Typography>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomTabs;
