import React, { useRef, useState, useEffect, useCallback } from 'react';
import gsap from 'gsap';
import { Button, Container, Typography, Grid, Box } from '@material-ui/core';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ScrollContainer from '../scroll-container';
import styles, {
  sectionTitleSm,
  baseStyles,
  drawStyles,
  pebblesPadding,
} from './givingBack.styles';
import useScrollAnimation from '../../hooks/use-scroll-animation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import OrgsSupportedDialog from './orgsSupported';

gsap.registerPlugin(ScrollTrigger);

const GivingBackSection = ({ givingBackSection }) => {
  const theme = useTheme();
  const hideSm = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm)
  );
  const leftSide = useRef(null);
  const rightSide = useRef(null);
  const culturalRef = useRef(null);
  const diversityRef = useRef(null);

  useScrollAnimation(3, leftSide, [leftSide, rightSide]);

  const handleOnEnter = () => {
    gsap.from(diversityRef.current, {
      y: 200,
      opacity: 0,
      duration: 1,
      ease: 'back',
    });
    gsap.from(culturalRef.current, {
      scale: 0.3,
      rotation: 151,
      duration: 2,
      ease: 'elastic',
    });
  };

  const [showOrgsModal, setShowOrgsModal] = useState(false);

  const handleOpen = () => {
    setShowOrgsModal(true);
  };

  const handleClose = () => {
    setShowOrgsModal(false);
  };

  const [isActive, setIsActive] = useState(false);

  const onEnter = () => {
    setIsActive(true);
  };

  const onLeave = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (isActive) handleOnEnter();
  }, [isActive]);

  return (
    <ScrollContainer ignore={true} onEnter={onEnter} onLeaveBack={onLeave}>
      <Container
        maxWidth={false}
        className="section-padding"
        style={{
          backgroundColor: '#E7C7AC',
          position: 'relative',
          zIndex: '0',
        }}
        css={pebblesPadding}
      >
        <Container maxWidth="lg" className="side-padding">
          <div css={styles}>
            <Typography variant="caption" css={sectionTitleSm}>
              Why Us? &ndash; 002
            </Typography>
            <Grid
              container
              className={isActive ? 'draw' : ''}
              css={[baseStyles, drawStyles]}
            >
              <Grid
                item
                xs={12}
                md={6}
                className="giving-back-section"
                ref={leftSide}
              >
                <Typography variant="h5" component="h2">
                  {givingBackSection.leftSideTitle}
                </Typography>
                <Typography variant="body1">
                  {givingBackSection.leftSideCopy.leftSideCopy}
                </Typography>
                <Button className="modal-link orgs-btn" onClick={handleOpen}>
                  {givingBackSection.orgsModalLinkText}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="pushing-diversity"
                ref={rightSide}
              >
                <Box className="badges-container">
                  {givingBackSection.badges.map((badge, i) => (
                    <img
                      ref={i ? culturalRef : diversityRef}
                      key={badge.id}
                      src={badge.file.url}
                    />
                  ))}
                </Box>
                <Box className="diversity-header-section">
                  <Typography
                    variant="h5"
                    component="h2"
                    className="title-right"
                  >
                    {hideSm && <img src={`images/arrow.svg`} />}
                    {givingBackSection.rightSideTitle}
                  </Typography>
                </Box>
                <Typography variant="body1" className="copy-right">
                  {givingBackSection.rightSideCopy.rightSideCopy}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Container>

      {showOrgsModal && (
        <OrgsSupportedDialog
          onClose={handleClose}
          showOrgsModal={showOrgsModal}
        />
      )}
    </ScrollContainer>
  );
};

export default GivingBackSection;
