import { useTheme } from '@material-ui/core/styles';
import { Typography, Button, Grid } from '@material-ui/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import styles, { activeStyles, changingStyles } from './mobile.styles';
import SlideInTitle from '../slidein-title';

gsap.registerPlugin(ScrollTrigger);

const colors = ['orange', 'cobalt', 'green', 'eraser', 'salmon'];

const ToolsMobile = ({ content, active, changing, handlePopoverOpen }) => {
  const theme = useTheme();

  return (
    <div
      css={[
        styles,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
    >
      <div className="sticky-wrapper">
        <Grid container className="top">
          <Grid item xs={10} md={8} className="header">
            {/* <Typography variant="h1">{content.titleMinimalTheme}</Typography> */}

            <SlideInTitle id={5} active={active}>
              {content.titleMinimalTheme.split(' ').map((title, index) => (
                <Typography key={`tools-title-line-${index}`} variant="h1">
                  {title}
                </Typography>
              ))}
            </SlideInTitle>
          </Grid>
          <Grid item xs={2} md={4}></Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Typography variant="body2" className="intro">
              {content.description.description}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={6} lg={8}></Grid>
        </Grid>

        <div className="toolsScroller">
          {content.toolLinkItem.map((tool, i) => (
            <div key={`tool-${i}`} className="tool">
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.4215 32.4909L18.678 21.1473L28.2149 26.82L31.5187 20.9663L28.4691 19.6115L20.841 16.0065C23.9385 14.3353 26.4893 12.9176 28.454 11.69L31.1157 10.0267L27.7892 4.70318L18.1798 11.3246L18.4661 0.21875L11.8437 0.479538L12.0731 3.24415L12.1206 4.44822L12.6982 11.5776L5.21275 7.35476L2.9315 5.96036L0 11.8457L9.94102 16.4728L0.0307269 22.0486L3.35725 27.3722L6.01902 25.7089C8.17379 24.3624 10.5029 22.7308 13.1092 20.8379C13.0377 22.5568 12.9503 25.5194 12.8945 29.5198L12.8229 32.6488L19.4215 32.4909Z"
                  fill={
                    theme.palette.supply[colors[(i + 1) % colors.length]].main
                  }
                />
              </svg>
              <Typography variant="h5">{tool.title}</Typography>
              <Typography variant="body2">
                {tool.description.description}
              </Typography>

              {content.toolLinkItem[i].linkActive ? (
                <Button
                  variant="contained"
                  color="secondary"
                  href={content.toolLinkItem[i].link}
                  target="_blank"
                >
                  {content.toolLinkItem[i].linkText}
                </Button>
              ) : (
                <Button variant="contained" color="secondary" disabled>
                  {content.toolLinkItem[i].linkText}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolsMobile;
