import { useEffect, useRef, useState, useMemo } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { useLayoutContext } from '../contexts/layoutContext';
import { useScrollContext } from '../contexts/scrollContext';

gsap.registerPlugin(ScrollTrigger);

const DURATION = 1;
const ID = `scrollTrigger`;

//the scroll trigger hook receives a wrapper element and an array of section elements
//when the wrapper element enters the viewport, the section elements will animate in with a staggered delay
//you can pass the same element as the element and the wrapper, if you want an element to be it's own trigger
function useScrollAnimation(
  id,
  wrapperRef,
  sectionRefs = [],
  callbacks,
  options = {}
) {
  const documentHidden = useRef(false);
  const refreshTO = useRef(false);
  const timeline = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const { changing } = useLayoutContext();
  const { activeSection } = useScrollContext();

  const landingTransition = useMemo(() => {
    return id && activeSection === id && changing;
  }, [changing, activeSection]);

  const { delay } = options;
  const duration = options.duration !== undefined ? options.duration : DURATION;
  const staggerDelay =
    options.staggerDelay !== undefined ? options.staggerDelay : 0.15;
  const startPercent = options.startPercent || 50;
  const { onUpdate, onEnter, onEnterBack, onLeave, onLeaveBack } =
    callbacks || {};

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (hasMounted) return;
    ScrollTrigger.update();
    setHasMounted(true);

    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: ID,
        start: `top ${
          typeof startPercent === 'number' ? startPercent + `%` : startPercent
        }`,
        end: 'bottom bottom',
        trigger: wrapperRef.current,
        toggleActions: 'play none none none',
        scrub: false,
        once: true,
        onEnter: onEnter ? ({ progress }) => onEnter(progress) : () => {},
        onEnterBack: onEnterBack ? onEnterBack : () => {},
        onLeave: onLeave ? onLeave : () => {},
        onLeaveBack: onLeaveBack ? onLeaveBack : () => {},
      },
    });

    if (!sectionRefs || sectionRefs.length === 0) return;

    for (let i = 0; i < sectionRefs.length; i++) {
      let offset = Math.round(Math.random() * 6) - 3;
      gsap.set(sectionRefs[i].current, {
        y: -150,
        opacity: 0,
        // rotate: offset,
      });
    }

    const animation = timeline.current.staggerTo(
      [...sectionRefs.map((ref) => ref.current)],
      duration,
      {
        stagger: staggerDelay,
        y: 0,
        opacity: 1,
        rotate: 0,
        delay: () => {
          // let randomOffset = Math.random() * 0.3 - 0.15;
          let landingOffset = landingTransition ? 0.3 : 0;
          // return (delay || 0) + randomOffset + landingOffset;
          return (delay || 0) + landingOffset;
        },
        ease: 'expo.out',
        onUpdate: onUpdate
          ? () => {
              onUpdate(animation.progress());
            }
          : () => {},
      }
    );
  }, [
    sectionRefs,
    wrapperRef,
    onUpdate,
    onEnter,
    onEnterBack,
    onLeave,
    onLeaveBack,
    delay,
    duration,
    staggerDelay,
    startPercent,
    hasMounted,
    setHasMounted,
  ]);

  useEffect(() => {
    // recalculate scroll positions after page has loaded
    // refreshTO.current = setTimeout(() => {
    //   ScrollTrigger.refresh();
    // }, 3000);

    return () => {
      if (refreshTO.current) clearTimeout(refreshTO.current);
      const scrollTriggerRef = ScrollTrigger.getById(ID);
      if (scrollTriggerRef) scrollTriggerRef.kill(true);
      if (timeline.current) timeline.current.kill();
    };
  }, []);
}

export default useScrollAnimation;
