import { css, keyframes } from '@emotion/react';
import {
  minimalInitiallyHidden,
  minimalRevealed,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: flex;
  background-color: ${theme.palette.background.default};
  padding: ${theme.spacing(2, 2, 12, 2)};
  min-height: 100vh;

  .header {
    margin: ${theme.spacing(10, 0, 4, 0)};
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(0)};
    }
  }

  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(10, 4, 16, 4)};
  }

  .mainCopy {
    ${minimalInitiallyHidden}

    margin-bottom: ${theme.spacing(8)};

    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacing(1)};
    }
  }

  .mainContent {
    ${minimalInitiallyHidden}
  }
`;

export const activeStyles = (theme) => css`
  .mainCopy {
    ${minimalRevealed}
  }

  .mainContent {
    ${minimalRevealed}
    transition-delay: 500ms;
  }
`;

export const changingStyles = (theme) => css`
  .mainCopy {
    transition-delay: 700ms;
  }

  .mainContent {
    transition-delay: 800ms;
  }
`;

export default styles;
