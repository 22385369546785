import React, {
  useEffect,
  useRef,
  useState,
  createRef,
  useCallback,
  useMemo,
} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useLayoutContext } from '../../contexts/layoutContext';
import styles, { iosStyles } from './heroSection.styles';
import { useTransition, animated } from 'react-spring';
import { useTheme } from '@material-ui/core/styles';
import ScrollContainer from '../../components/scroll-container';

import Logo from './logo';

gsap.registerPlugin(ScrollTrigger);
const SCROLL_TRIGGER_ID = `heroAnimation`;

const HeroSection = ({ heroSection }) => {
  const [showLogo, setShowLogo] = useState(false);
  const [index, setIndex] = useState(0);
  const [eIndex, setEIndex] = useState(0);
  const {
    firstAnimateIn,
    setFirstLoadOut,
    firstLoadIn,
    setFirstAnimateIn,
  } = useLayoutContext();

  const changeInterval = useRef(null);
  const timeline = useRef(null);
  const wrapperRef = useRef(null);
  const theme = useTheme();

  const isIos = useMemo(
    () =>
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );

  const showMdAndUp = useMediaQuery(
    // theme.breakpoints.up(theme.breakpoints.values.md)
    theme.breakpoints.up(theme.breakpoints.values.sm)
  );

  let colors = [
    theme.palette.supply.salmon.main,
    theme.palette.supply.cobalt.main,
    theme.palette.supply.orange.main,
  ];

  const [items] = useState(
    heroSection.titleChangingParts.map((part, i) => ({
      id: i,
      color: colors[i],
      title: part,
    }))
  );

  const fadingTextPropsTransition = useTransition(
    items[index],
    (item) => item.id,
    {
      from: {
        top: '0%',
        transform: 'translate3d(0%,-100%,0)',
      },
      enter: {
        top: '0%',
        transform: 'translate3d(0%,0,0)',
      },
      leave: {
        top: '0%',
        transform: 'translate3d(0%,100%,0)',
      },
      config: {
        tension: 160,
        friction: 20,
        clamp: true,
        velocity: 0.026,
      },
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setEIndex((state) => (state + 1) % emojis.length);
    }, 3000);

    return () => {
      clearInterval(interval);
      if (changeInterval.current) clearInterval(changeInterval.current);
    };
  }, []);

  const initInterval = () => {
    if (changeInterval.current) clearInterval(changeInterval.current);
    changeInterval.current = setInterval(() => {
      setIndex((state) => (state + 1) % items.length);
    }, 3000);
  };

  const afterIntroAnim = () => {
    initInterval();
    setFirstAnimateIn(false);
    setFirstLoadOut(false);
  };

  useEffect(() => {
    if (!firstLoadIn) doInAnimation();

    return () => {
      if (!timeline.current) return;
      timeline.current.kill();
    };
  }, []);

  useEffect(() => {
    if (!firstLoadIn) doInAnimation();
  }, [firstLoadIn]);

  const doInAnimation = useCallback(() => {
    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: SCROLL_TRIGGER_ID,
        start: 'top 70%',
        end: 'bottom bottom',
        trigger: wrapperRef.current,
      },
    });

    let sections = [
      [...headlineWords.current.map((ref) => ref.current)],
      [...leftRefs.current.map((ref) => ref.current)],
    ];

    timeline.current
      .fromTo(
        sections[showMdAndUp ? 0 : 1],
        {
          y: -window.innerHeight,
          opacity: 1,
        },
        {
          stagger: {
            amount: -0.4,
          },
          // duration: 1.8,
          duration: 1.4,
          delay: 0,
          y: 0,
          opacity: 1,
          ease: 'power4.out',
        }
      )
      .call(
        () => {
          if (showMdAndUp) setShowLogo(true);
        },
        null,
        '-=0.6'
      )
      .fromTo(
        sections[showMdAndUp ? 1 : 0],
        {
          y: -window.innerHeight,
          opacity: 1,
        },
        {
          stagger: {
            amount: -0.4,
          },
          // duration: 1.8,
          duration: 1.4,
          y: 0,
          delay: showMdAndUp ? 0 : 0.2,
          opacity: 1,
          ease: 'power4.out',
          onComplete: () => {
            afterIntroAnim();
          },
        },
        showMdAndUp ? '-=1.6' : '-=1.6'
      )
      .call(
        () => {
          if (!showMdAndUp) setShowLogo(true);
        },
        null,
        '-=0.6'
      )
      .fromTo(
        mobileLogoRef.current,
        {
          y: -window.innerHeight,
          opacity: 1,
        },
        {
          // duration: 1.8,
          duration: 1.4,
          y: 0,
          // delay: 0.2,
          opacity: 1,
          ease: 'power4.out',
        },
        '-=1.2'
      );
  }, [showMdAndUp]);

  const reg = new RegExp(/\s/g, '');
  const headlineSegments = heroSection.headline.split(reg);
  const headlineWords = useRef(headlineSegments.map(() => createRef()));
  const leftRefs = useRef([...Array(5)].map(() => createRef()));
  const mobileLogoRef = useRef();

  const [emojis] = useState([
    { title: `⛷  and  🍻`, id: 0 },
    { title: `🏍  and  🥃`, id: 1 },
    { title: `☕️  and  🏀`, id: 2 },
    { title: `🛹  and  🍕`, id: 3 },
    { title: `🏕  and  🥞`, id: 4 },
  ]);

  const emojiPropsTransition = useTransition(
    emojis[eIndex],
    (emoji) => emoji.id,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: {
        tension: 160,
        friction: 20,
        clamp: true,
        velocity: 0.026,
      },
    }
  );

  const heroIntro = () => (
    <>
      <Typography
        variant="body1"
        component="div"
        className="hero-intro"
        ref={leftRefs.current[2]}
        style={{ marginBottom: theme.spacing(4) }}
      >
        {heroSection.introPart1.introPart1}
        Folks we{' '}
        <div
          style={{
            position: 'relative',
            // width: '76px',
            width: '86px',
            display: 'inline-block',
            height: '20px',
          }}
        >
          {/* <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}> */}
          {emojiPropsTransition.map(({ item, props, key }) => (
            <animated.div
              key={key}
              style={{
                ...props,
                position: 'absolute',
                top: '2px',
                width: '100%',
                textAlign: 'center',
                wordSpacing: '3px',
              }}
            >
              <div>{item.title}</div>
            </animated.div>
          ))}
          {/* </div> */}
        </div>{' '}
        with.
      </Typography>
      {!!heroSection.introPart2.introPart2 && (
        <Typography variant="body1" ref={leftRefs.current[3]}>
          {heroSection.introPart2.introPart2}
        </Typography>
      )}
    </>
  );

  const heroTitle = () => (
    <div className="rich-text-section">
      <Typography
        variant="h1"
        style={{
          textIndent: '-9000px',
          height: 0,
          visibility: 'hidden',
        }}
      >
        The Supply / Curated Digital Talent
      </Typography>
      <Typography variant="h3" component="div">
        {heroSection.titleTopParts.map((topPart, i) => (
          <div
            key={`top-part-${i}`}
            className="word-container"
            ref={headlineWords.current[i]}
          >
            {topPart}
          </div>
        ))}

        <div
          className="word-container scrolling-text"
          ref={headlineWords.current[heroSection.titleTopParts.length]}
        >
          <div className="word-inner-wrapper">
            <div>
              {fadingTextPropsTransition.map(({ item, props, key }) => (
                <animated.div
                  key={key}
                  style={{ ...props, position: 'absolute' }}
                  className="fading-text"
                >
                  <Typography variant="h3" style={{ color: item.color }}>
                    {item.title}
                  </Typography>
                </animated.div>
              ))}
            </div>
          </div>
        </div>

        {heroSection.titleBottomParts.map((bottomPart, i) => (
          <div
            key={`bottom-part-${i}`}
            className="word-container"
            ref={
              headlineWords.current[heroSection.titleTopParts.length + 1 + i]
            }
          >
            {bottomPart}
          </div>
        ))}
      </Typography>
    </div>
  );

  return (
    <ScrollContainer id={0}>
      <Container
        ref={wrapperRef}
        disableGutters
        maxWidth={false}
        css={[styles, isIos ? iosStyles : null]}
        id="section0"
      >
        <Grid
          container
          direction={showMdAndUp ? 'row' : 'column-reverse'}
          justifyContent="space-between"
          alignItems="stretch"
          spacing={0}
          className="grid"
        >
          <Grid item xs={12} sm={4}>
            <div className="left-col">
              <div className="top" ref={leftRefs.current[0]}>
                {showMdAndUp && <Logo active={showLogo} />}
              </div>

              <div className="bottom">
                <div className="bottom-border" ref={leftRefs.current[1]}></div>
                {heroIntro()}
              </div>
            </div>
          </Grid>
          <Grid item container xs={12} sm={8} direction="column">
            <div className="right-col">
              <div className="top">
                {/* {!showMdAndUp && <div className="logo"></div>} */}
                <div style={{ position: 'relative' }} ref={mobileLogoRef}>
                  {!showMdAndUp && <Logo active={showLogo} />}
                </div>
                {heroTitle()}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </ScrollContainer>
  );
};

export default HeroSection;
