import { css } from '@emotion/react';
import {
  minimalCursor,
  minimalCursorWhite,
  minimalRevealed,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: block;
  position: relative;
  background-color: ${theme.palette.supply.darkGray};
  color: ${theme.palette.supply.cream.main};
  padding: ${theme.spacing(2, 2, 12, 2)};
  cursor: ${minimalCursorWhite};
  min-height: 100vh;

  ${theme.breakpoints.up('md')} {
    padding: 0;
  }

  .mobile-group {
    .header {
      margin: ${theme.spacing(10, 0, 8, 0)};
    }

    .parts {
      margin-top: ${theme.spacing(8)};
      border: 1px solid ${theme.palette.supply.cream.main};

      .part {
        padding: ${theme.spacing(5, 3, 3, 3)};
        border-bottom: 1px solid ${theme.palette.supply.cream.main};

        h4 {
          margin-bottom: ${theme.spacing(3)};
        }
      }
    }
  }
`;

// minimalRevealed

export const activeStyles = (theme) => css`
  .sticky-title {
    .description {
      ${minimalRevealed};
    }
  }
`;

export const changingStyles = (theme) => css`
  .sticky-title {
    .description {
      transition-delay: 700ms;
    }
  }
`;

export const popoverStyles = (theme) => css`
  text-align: center;
  cursor: ${minimalCursorWhite};
  .MuiPopover-paper {
    cursor: ${minimalCursor};
  }
`;

export default styles;
