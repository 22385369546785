import { css, keyframes } from '@emotion/react';
import {
  minimalCursor,
  minimalCursorWhite,
  minimalPointerWhite,
  minimalInitiallyHidden,
  minimalRevealed,
} from '../../components/shared/shared.styles';
import { darken } from '@material-ui/core';

const baseDelay = 0.4;

const styles = (theme) => css`
  display: block;
  position: relative;
  background-color: ${theme.palette.supply.darkGray};
  color: ${theme.palette.supply.cream.main};
  cursor: ${minimalCursorWhite};
  height: 420vh;

  .scroll-marker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 400vh;
    z-index: -1;
    pointer-events: none;
  }

  .info-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(/images/minimal-info.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: ${theme.spacing(1)};
    cursor: ${minimalPointerWhite};
  }

  .sticky-container {
    position: sticky;
    width: 100vw;
    height: 100vh;
    top: 0;
    overflow: hidden;
  }

  .sticky-title {
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${theme.palette.supply.cream.main};
    top: 0;
    left: 0;
    padding: ${theme.spacing(6, 0, 6, 4)};
    height: 100%;

    .partner_word-container {
      position: relative;
      display: flex;
      height: ${75 * theme.ui.lineHeight.m}px;
      width: 100%;

      .partner_word-inner-wrapper {
        position: relative;
        white-space: nowrap;
        width: 100%;
        height: ${75 * theme.ui.lineHeight.m}px;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
      }

      ${theme.breakpoints.up('sm')} {
        height: ${95 * theme.ui.lineHeight.m}px;
        .partner_word-inner-wrapper {
          height: ${95 * theme.ui.lineHeight.m}px;
        }
      }
      ${theme.breakpoints.up('md')} {
        height: ${120 * theme.ui.lineHeight.m}px;
        .partner_word-inner-wrapper {
          height: ${120 * theme.ui.lineHeight.m}px;
        }
      }
      ${theme.breakpoints.up('lg')} {
        height: ${150 * theme.ui.lineHeight.m}px;
        .partner_word-inner-wrapper {
          height: ${150 * theme.ui.lineHeight.m}px;
        }
      }

      .partner_fading-text {
        display: flex;
        position: relative;
        width: 100%;
        flex-wrap: nowrap;
        height: ${75 * theme.ui.lineHeight.m}px;
        transform: translate3d(0, 0, 1px);
        will-change: transform;

        ${theme.breakpoints.up('sm')} {
          height: ${95 * theme.ui.lineHeight.m}px;
        }
        ${theme.breakpoints.up('md')} {
          height: ${120 * theme.ui.lineHeight.m}px;
        }
        ${theme.breakpoints.up('lg')} {
          height: ${150 * theme.ui.lineHeight.m}px;
        }
      }
    }

    .description {
      position: relative;
      ${minimalInitiallyHidden}

      .partner-description {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 1;
        transform: translate3d(0, 0px, 0);
        transition: all 500ms ${theme.transitions.easing.outQuad};
        transition-delay: ${baseDelay}s;

        &.clients {
          opacity: 0;
          transform: translate3d(0, -20px, 0);
          transition-timing-function: ${theme.transitions.easing.inQuad};
          transition-delay: 0s;
        }
      }

      &.reveal-clients {
        .partner-description {
          &.talent {
            opacity: 0;
            transform: translate3d(0, 20px, 0);
            transition-timing-function: ${theme.transitions.easing.inQuad};
            transition-delay: 0s;
          }
          &.clients {
            opacity: 1;
            transform: translate3d(0, 0px, 0);
            transition-timing-function: ${theme.transitions.easing.outQuad};
            transition-delay: ${baseDelay}s;
          }
        }
      }
    }
  }

  .right-container {
    position: relative;
    .options {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      .option {
        position: relative;
        width: 100%;
        height: 33.33%;
        padding: ${theme.spacing(2, 4)};
        z-index: 10;
        background: ${theme.palette.supply.darkGray};
        transition: background 200ms ${theme.transitions.easing.inOutSine};
        border-left: 1px solid ${theme.palette.supply.cream.main};
        border-bottom: 1px solid ${theme.palette.supply.cream.main};
        background: ${theme.palette.supply.darkGray};
        overflow: hidden;

        @media (min-height: 768px) {
          padding: ${theme.spacing(4)};
        }

        h5 {
          margin-bottom: ${theme.spacing(2)};
          opacity: 0;
          transform: translateY(-20px);
          transition: opacity 500ms ${theme.transitions.easing.inQuad},
            transform 500ms ${theme.transitions.easing.inQuad},
            color 200ms ${theme.transitions.easing.inOutSine};
          transition-delay: 100ms;

          @media (min-height: 768px) {
            margin-bottom: ${theme.spacing(3)};
          }
        }

        p {
          opacity: 0;
          transform: translateY(-20px);
          transition: all 500ms ${theme.transitions.easing.inQuad};
          transition-delay: 0ms;
        }

        &.active {
          h5 {
            transition: opacity 500ms ${theme.transitions.easing.outQuad},
              transform 500ms ${theme.transitions.easing.outQuad},
              color 200ms ${theme.transitions.easing.inOutSine};
            transition-delay: 0ms;
            opacity: 1;
            transform: translateY(0px);
          }
          p {
            transition: all 500ms ${theme.transitions.easing.outQuad};
            transition-delay: 100ms;
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      &.talent {
        .option.active {
          &:hover {
            background: ${darken(theme.palette.supply.darkGray, 0.2)};
            h5 {
              color: ${theme.palette.supply.green.main};
            }
          }
        }
      }
      &.clients {
        .option.active {
          &:hover {
            background: ${darken(theme.palette.supply.darkGray, 0.2)};
            h5 {
              color: ${theme.palette.supply.eraser.main};
            }
          }
        }
      }
    }
  }
`;

// minimalRevealed

export const activeStyles = (theme) => css`
  .sticky-title {
    .description {
      ${minimalRevealed};
    }
  }
`;

export const changingStyles = (theme) => css`
  .sticky-title {
    .description {
      transition-delay: 700ms;
    }
  }
`;

export const popoverStyles = (theme) => css`
  text-align: center;
  cursor: ${minimalCursorWhite};
  .MuiPopover-paper {
    cursor: ${minimalCursor};
  }
`;

export default styles;
