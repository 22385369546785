import React from 'react';
import { css } from '@emotion/react';

const styles = (theme) => css`
  width: 40px;
  margin: ${theme.spacing(0, 2, 1, 0)};
  margin-left: -30px;
  transition: all 800ms ${theme.transitions.easing.outCubic};

  ${theme.breakpoints.up('sm')} {
    width: 60px;
    margin: ${theme.spacing(0, 2, 0.5, 0)};
    margin-left: -30px;
  }
  ${theme.breakpoints.up('md')} {
    width: 70px;
    margin-left: -50px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 80px;
    margin-left: -50px;
  }

  line,
  path {
    fill: none;
    stroke: ${theme.palette.supply.darkGray};
    stroke-width: 9;
    transform-origin: center right;
    transition: all 800ms ${theme.transitions.easing.outCubic};
  }
  path {
    stroke-dasharray: 48px;
    &.top-curve,
    &.bottom-curve {
      stroke-dashoffset: 48px;
    }
  }
  line {
    transform: scaleX(0);
  }
`;

const activeStyles = (theme) => css`
  margin-left: 0px !important;
  transition-delay: 1000ms;

  path {
    transition-delay: 700ms;
    &.top-curve {
      stroke-dashoffset: 96px;
    }
    &.bottom-curve {
      stroke-dashoffset: 0px;
    }
  }

  line {
    transform: scaleX(1);
    transition-delay: 1000ms;
  }
`;

const arrow = ({ active }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 54"
      css={[styles, active ? activeStyles : null]}
    >
      <line className="line" x1="0.8" y1="26.7" x2="100" y2="26.7" />
      <path
        className="bottom-curve"
        d="M99.7,26.7c-20.8,0-33.3,13.2-33.3,26.5"
      />
      <path className="top-curve" d="M66.3,0.2c0,13.3,12.6,26.5,33.3,26.5" />
    </svg>
  );
};

export default arrow;
