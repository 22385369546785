import React, { useRef } from 'react';
import { Box, Container, Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import styles from './whyUs.styles';
import PebbleStats from './pebbleStats';
import { pt4, pb4 } from '../layout/margin-padding-utils.styles';
import useScrollAnimation from '../../hooks/use-scroll-animation';
import { useLayoutContext } from '../../contexts/layoutContext';
import { useScrollContext } from '../../contexts/scrollContext';

const WhyUsSection = ({ whyUsSection }) => {
  const wrapper = useRef(null);
  const mWrapper = useRef(null);
  const headline = useRef(null);
  const description = useRef(null);
  const introCopy = useRef(null);
  const roldxImg = useRef(null);

  const { changing } = useLayoutContext();
  const { activeSection } = useScrollContext();

  const theme = useTheme();
  const showDesktop = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );
  const showPebbles = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.tabletLandscape)
  );

  useScrollAnimation(3, wrapper, [headline, description, introCopy], null, {
    delay: changing && activeSection === 3 ? 0.5 : 0,
  });
  useScrollAnimation(3, mWrapper, [roldxImg]);

  return (
    <Container maxWidth={false} css={styles}>
      <Container maxWidth="lg" className="side-padding">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} className="title-container">
            <Typography variant="caption" className="section-title-sm">
              Why Us? &ndash; 001
            </Typography>
            <Typography variant="h5" component="h2" className="why-us-title">
              <span ref={headline}>{whyUsSection.title}</span>
            </Typography>
            <div ref={wrapper}></div>
          </Grid>
        </Grid>
        <Grid container className="border-container top-section">
          <Grid item xs={12} md={6} className="upper-container">
            <div
              ref={description}
              className="why-us-intro-title"
              dangerouslySetInnerHTML={{
                __html: whyUsSection.description.childMarkdownRemark.html,
              }}
            />
            <div ref={mWrapper}></div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="intro-copy"
            ref={introCopy}
            css={[pt4, pb4]}
          >
            <Typography variant="body1">
              {whyUsSection.descriptionTwo.descriptionTwo}
            </Typography>
          </Grid>
          <Grid item xs={12} className="second-section">
            <div className="video-container">
              {showDesktop ? (
                <video loop autoPlay={true} muted width="100%" ref={roldxImg}>
                  <source
                    src={whyUsSection.roldxVideoDesktop.file.url}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  src={whyUsSection.roldxMobileImage.file.url}
                  alt="Roldx"
                  ref={roldxImg}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      {showPebbles ? (
        <PebbleStats data={whyUsSection} />
      ) : (
        <Box className="pebbles-mobile">
          <img src={`images/pebbles-mobl.png`} />
        </Box>
      )}
    </Container>
  );
};

export default WhyUsSection;
