import { css } from '@emotion/react';

const styles = (theme) => css`
  position: relative;
  .MuiDialog-paper {
    border-radius: 20px;
    border: 3px solid;
    background-color: ${theme.palette.supply.cream.main};
  }

  .close-modal {
    align-self: flex-end;
    cursor: pointer;
    ${theme.breakpoints.down('sm')} {
      margin-top: 12px;
    }
  }

  .grid-img-grid {
    ${theme.breakpoints.up('md')} {
      width: 70%;
      margin: 30px auto;
      max-width: 836px;
    }
  }

  .grid-img-wrap {
    display: flex;
    justify-content: center;
    padding: 30px;
    ${theme.breakpoints.up('md')} {
      padding: 25px;
    }
  }

  .grid-img {
    max-width: 140px;
    vertical-align: middle;
  }

  .inner-top {
    padding: 0 1rem 5rem;
    text-align: center;
    ${theme.breakpoints.up('md')} {
      padding: 0 10rem;
    }
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 4rem;
  }

  .orgs-copy {
    margin-top: 5rem;
    margin-bottom: 0;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 6px;
    background-color: ${theme.palette.text.primary};
    display: inline-block;
    margin: 0 6px;
  }
`;

export default styles;
