import { css, keyframes } from '@emotion/react';
import {
  minimalPointer,
  minimalPointerWhite,
} from '../../components/shared/shared.styles';

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opcaity: 0.5;
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const styles = (theme) => css`
  position: relative;
  transform: translate3d(0, 0, 1px);

  .MuiDialog-paper {
    max-width: 790px;
    border-radius: 0;
    background-color: ${theme.palette.supply.cream.main};
    overflow: visible;
    margin: ${theme.spacing(10, 4)};
    max-height: calc(100% - ${theme.spacing(20)});
    ${theme.breakpoints.up('md')} {
      max-height: 650px;
      margin: ${theme.spacing(4)};
    }
  }

  .main-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: ${theme.spacing(6, 2, 6, 2)};

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(8, 6, 8, 8)};
    }

    ${theme.breakpoints.up('md')} {
      width: calc(100% - 2px);
      padding: ${theme.spacing(12, 10, 12, 12)};
      overflow-y: overlay;

      ::-webkit-scrollbar {
        width: 15px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
        margin: 2px 0;
      }

      ::-webkit-scrollbar-thumb {
        background: ${theme.palette.supply.darkGray};
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.palette.supply.darkGray};
      }
    }
  }

  .close-modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -40px;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.palette.supply.cream.main};
    border-radius: 10px;
    cursor: pointer;
    padding: 0;
    animation: ${fadeUp} 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    animation-delay: 0.2s;
    opacity: 0;
    transform: translateY(20px);
    cursor: ${minimalPointerWhite};

    ${theme.breakpoints.up('md')} {
      top: -60px;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 40px;
    }
  }

  .grid-img-grid {
  }

  .grid-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(6)};
    border-top: 1px solid ${theme.palette.supply.darkGray};
    border-left: 1px solid ${theme.palette.supply.darkGray};
    border-right: 1px solid ${theme.palette.supply.darkGray};

    ${theme.breakpoints.up('sm')} {
      border-right: none;
      &:nth-of-type(2n) {
        border-right: 1px solid ${theme.palette.supply.darkGray};
      }
    }

    ${theme.breakpoints.up('md')} {
      border-right: none;
      &:nth-of-type(2n) {
        border-right: none;
      }
      &:nth-of-type(3n) {
        border-right: 1px solid ${theme.palette.supply.darkGray};
      }
    }

    &.last-row {
      border-bottom: 1px solid ${theme.palette.supply.darkGray};
    }

    .content {
      width: 100%;
      aspect-ratio: 1/1;

      > a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: ${minimalPointer};

        img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }
  }

  .inner-top {
    h4 {
      margin-bottom: ${theme.spacing(4)};
    }
    p {
      margin-bottom: ${theme.spacing(6)};
    }
  }
`;

export default styles;
