import React, { useEffect, useRef, createRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Popover, Box, Container, Grid, Typography } from '@material-ui/core';
import styles from './tools.styles';
import { pb25, pb4, mt6 } from '../layout/margin-padding-utils.styles';
import useScrollAnimation from '../../hooks/use-scroll-animation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ScrollContainer from '../scroll-container';

gsap.registerPlugin(ScrollTrigger);

const ToolsSection = ({ toolsSection }) => {
  const reg = new RegExp(/\s/g, '');
  const parts = toolsSection.title.split(reg);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const hideMed = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const showMed = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.lg)
  );
  const isMobile = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const toolsTitleRef = useRef(null);
  const descRef = useRef(null);
  const items = useRef(toolsSection.toolLinkItem.map(() => createRef()));

  useScrollAnimation(5, descRef, [descRef]);

  items.current.forEach((item) => {
    useScrollAnimation(5, item, [item]);
  });

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [expanded, setExpanded] = useState(false);

  const onEnter = () => {
    setExpanded(true);
  };
  const onLeave = () => {
    setExpanded(false);
  };

  const titleTransform = () => {
    return (
      <>
        {parts.map((part, index) => {
          if (index === 2)
            return (
              <div key={index}>
                <span
                  className={`line ${expanded ? 'line-animate' : ''}`}
                ></span>
                <div>{part}</div>
              </div>
            );
          return <div key={index}>{part}</div>;
        })}
      </>
    );
  };

  return (
    <ScrollContainer
      id={5}
      onEnter={onEnter}
      onEnterBack={onEnter}
      onLeave={onLeave}
      onLeaveBack={onLeave}
    >
      <Container
        maxWidth={false}
        className="section-padding"
        id="section5"
        css={styles}
      >
        <Container maxWidth="lg" className="side-padding">
          <Grid container style={{ overflow: 'hidden' }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="tools-header-border"
              css={pb4}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  className="tools-title"
                  css={pb25}
                  ref={toolsTitleRef}
                >
                  {showMed ? titleTransform() : toolsSection.title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography
                  variant="body2"
                  ref={descRef}
                  className="tools-description"
                >
                  {toolsSection.description.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={isMobile ? 10 : 4}
              style={{ marginTop: '5.5rem !important' }}
              justifyContent="center"
              alignItems="center"
            >
              {toolsSection.toolLinkItem.map((toolItem, index) => (
                <Grid
                  item
                  xs={12}
                  md={5}
                  key={toolItem.id}
                  className="tool-item-container"
                  ref={items.current[index]}
                >
                  <img src={toolItem.icon.file.url} className="star" />
                  <Typography variant="h4" className="tool-name">
                    {toolItem.title}
                  </Typography>
                  <Typography variant="body1">
                    {toolItem.description.description}
                  </Typography>
                  <Typography variant="body1" className="tool-link">
                    {/* {toolItem.link === '/salaries' ? (
                      <span
                        pt={3}
                        aria-owns={open ? 'mouse-over-popover2' : undefined}
                        aria-haspopup="true"
                        onClick={handlePopoverOpen}
                        onClose={handlePopoverClose}
                      >
                        {toolItem.linkText}
                      </span>
                    ) : (
                    )} */}
                    <a href={toolItem.link} target="_blank">
                      {toolItem.linkText}
                    </a>
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Popover
              id="mouse-over-popover2"
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
            >
              <Box p={3} style={{ backgroundColor: '#A0AEAA' }}>
                <Typography>
                  Coming Soon - Fine tuning some of the data to make this tool
                  as helpful, current and accurate as possible.
                </Typography>
                <img
                  src={`/images/sal-cover.jpg`}
                  style={{ width: '100%', marginTop: '1rem' }}
                />
              </Box>
            </Popover>
          </Grid>
        </Container>
      </Container>
    </ScrollContainer>
  );
};

export default ToolsSection;
