import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, Button } from '@material-ui/core';
import styles from './part1.styles';

const WhyUsPart1 = ({ content }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  return (
    <Grid item xs={12} css={styles}>
      <Grid
        container
        alignItems="flex-end"
        alignContent="flex-end"
        justifyContent={showMdAndUp ? 'flex-end' : 'flex-start'}
      >
        <Grid item md={4} xs={12}>
          <Typography variant="caption" className="caption">
            {content.title} — 001
          </Typography>
          <Typography variant={'h2'}>{content.headerMinimalTheme}</Typography>
        </Grid>
        <Grid item md={3} xs={12}></Grid>
        <Grid item md={5} xs={12}>
          <Typography variant="body2">
            {content.descriptionTwo.descriptionTwo}
          </Typography>
        </Grid>
        <Grid item xs={12} className="video-container">
          {showMdAndUp ? (
            <video loop autoPlay={true} muted width="100%">
              <source
                src={content.roldxVideoDesktop.file.url}
                type="video/mp4"
              />
            </video>
          ) : (
            <img src={content.roldxMobileImage.file.url} alt="Roldx" />
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            variant="contained"
            color="default"
            size="large"
            href={content.link}
            target="_blank"
          >
            {content.buttonCopy}
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default WhyUsPart1;
