import { css } from '@emotion/react';
import { minimalCursorWhite } from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.supply.darkGray};
  color: ${theme.palette.supply.cream.main};
  padding: ${theme.spacing(2, 2, 12, 2)};
  cursor: ${minimalCursorWhite};

  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(10, 4, 16, 4)};
  }

  .innerWrapper {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    border: 1px solid ${theme.palette.supply.cream.main};
    padding: ${theme.spacing(4, 2)};
    min-height: 80vh;
    overflow: hidden;

    .innerContent {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      z-index: 10;
    }

    .copy {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      p {
        text-align: center;
        margin: ${theme.spacing(2, 0)};
      }

      ${theme.breakpoints.up('md')} {
        justify-content: center;
        p {
          margin: ${theme.spacing(2, 'auto')};
        }
      }
    }

    ${theme.breakpoints.up('md')} {
      min-height: none;
      aspect-ratio: 1/0.666;
      padding: ${theme.spacing(8)};

      p {
        max-width: 70%;
      }
    }
  }
`;

export default styles;
