import { memo, useEffect, useRef, useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Box } from './Box';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useLayoutContext } from '../../contexts/layoutContext';

gsap.registerPlugin(ScrollTrigger);
const SCROLL_TRIGGER_ID = `draggableInAnimation`;

function getStyles(left, top, isDragging) {
  const transform = `translate3d(${left}px, ${top}px, 0)`;
  return {
    position: 'absolute',
    top: '6vw',
    left: '5vw',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
  };
}

export const DraggableBox = memo(function DraggableBox(props) {
  const { id, content, left, top, isShown } = props;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      item: { id, left, top, content, type: 'box' },
      collect: (DragSourceMonitor) => ({
        isDragging: DragSourceMonitor.isDragging(),
      }),
    }),
    [id, left, top, content, isDragging]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const { firstLoadIn } = useLayoutContext();

  const dragInRef = useRef(null);
  const timeline = useRef(null);

  const doInAnimation = useCallback(() => {
    //set up the timeline animations using scroll trigger
    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: SCROLL_TRIGGER_ID,
        start: 'top 70%',
        end: 'bottom bottom',
        trigger: dragInRef.current,
      },
    });

    timeline.current.fromTo(
      dragInRef.current,
      {
        y: -window.innerHeight * 1.5,
        opacity: 1,
        rotate: 25,
      },
      {
        duration: 1.5,
        delay: 0.4,
        y: 0,
        opacity: 1,
        rotate: 0,
        ease: 'power4.out',
      }
    );
  }, []);

  useEffect(() => {
    if (!firstLoadIn) doInAnimation();
  }, [firstLoadIn]);

  useEffect(() => {
    if (!firstLoadIn) doInAnimation();

    return () => {
      if (!timeline.current) return;
      timeline.current.kill();
      const scrollTriggerRef = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (scrollTriggerRef) {
        scrollTriggerRef.kill(true);
      }
    };
  }, []);

  return (
    <div
      ref={drag}
      style={getStyles(left, top, isDragging)}
      role="DraggableBox"
    >
      <div ref={dragInRef}>
        <Box isShown={isShown} content={content} />
      </div>
    </div>
  );
});
