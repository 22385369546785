import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Box,
  Slide,
  Fab,
  Popover,
} from '@material-ui/core';
import { CustomerMessage, AgentMessage } from './chatUtils';
import EmailContainer from '../email/email-container';
import { chatBaseStyles } from './chat.styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import gsap from 'gsap/gsap-core';

const ChatMessages = ({ messages = [], customerId, scrollToRef }) => {
  return (
    <div className="msg-container">
      {messages.map((message, idx) => {
        const key = message.id || idx;
        const isMe =
          message.customer_id === customerId ||
          (!!message.sent_at && message.type === 'customer');

        const isAgent =
          (message.source === 'slack' || message.type === 'bot') && !isMe;

        return !isAgent ? (
          <CustomerMessage key={key} message={message} />
        ) : (
          <AgentMessage key={key} message={message} />
        );
      })}

      <div key="scroll-el" ref={scrollToRef} />
    </div>
  );
};

const Chat = ({ state, onSendMessage, scrollToRef, chatCloseButton, copy }) => {
  copy.disableChatToggle = true;

  const [message, setMessageBody] = useState('');
  const [checked, setChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { messages = [], customerId, isLoaded } = state;
  const emailTitleRef = useRef(null);
  const emailDescriptionRef = useRef(null);
  const emailButtonRef = useRef(null);
  const chatDisclaimerRef = useRef(null);
  const formRef = useRef(null);
  const sendButtonRef = useRef(null);
  const closeRef = useRef(null);
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  const showTabletAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.tablet)
  );

  const handleChangeMessage = (e) => setMessageBody(e.target.value);
  const animateTalkSection = useCallback(() => {
    gsap.from(formRef.current, {
      x: -50,
      opacity: 0,
      ease: 'power4.out',
      duration: 0.75,
      delay: 0.3,
    });
    gsap.from(sendButtonRef.current, {
      x: -50,
      opacity: 0,
      ease: 'power4.out',
      duration: 0.75,
      delay: 0.4,
    });

    gsap.from(emailTitleRef.current, {
      x: -300,
      opacity: 0,
      ease: 'power4.out',
      duration: 0.75,
      delay: 0.5,
    });
    gsap.from(emailDescriptionRef.current, {
      y: 100,
      opacity: 0,
      ease: 'power3.out',
      duration: 0.75,
      delay: 0.6,
    });
    gsap.from(chatDisclaimerRef.current, {
      x: -100,
      opacity: 0,
      ease: 'power3.out',
      duration: 0.75,
      delay: 0.8,
    });
    gsap.from(emailButtonRef.current, {
      x: 100,
      opacity: 0,
      ease: 'power3.out',
      duration: 0.6,
      delay: 0.75,
    });
    gsap.from(closeRef.current, { opacity: 0, duration: 0.75, delay: 1 });
  }, [checked]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    animateTalkSection();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAnchorEl(sendButtonRef.current);
    }, 670);
  }, [sendButtonRef]);

  const open = Boolean(anchorEl);

  const handleSendMessage = (e) => {
    onSendMessage({ body: message });
    setMessageBody('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage();
  };

  return (
    <div style={{ backgroundColor: '#FEB29C' }}>
      {!checked ? (
        <Slide
          direction="right"
          in={!checked}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 400, exit: 400 }}
        >
          <Grid container css={chatBaseStyles}>
            <div className="close-container" ref={closeRef}>
              {chatCloseButton}
            </div>
            <Grid item xs={12} md={6} className="email-side-container">
              <Box className="email-side-interior">
                <Typography ref={emailTitleRef} variant="h5">
                  {copy.chatHeadline}
                </Typography>
                <Typography ref={emailDescriptionRef} variant="body1">
                  {copy.chatDescription.chatDescription}
                </Typography>
                <Typography
                  ref={chatDisclaimerRef}
                  variant="caption"
                  className="disclaimer"
                >
                  {copy.chatDisclaimer.chatDisclaimer}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className="email-btn email-md"
                  ref={emailButtonRef}
                  fullWidth
                  onClick={() => setChecked(true)}
                  startIcon={
                    <img
                      src={`/images/arrow-left-email-btn.svg`}
                      style={{ marginRight: 'auto' }}
                    />
                  }
                >
                  {showTabletAndUp ? 'Email instead' : 'Email us'}
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} className="message-container">
              <div className="message-interior">
                {isLoaded ? (
                  <ChatMessages
                    messages={messages}
                    customerId={customerId}
                    scrollToRef={scrollToRef}
                  />
                ) : (
                  <div className="loading-messages">
                    <CircularProgress />
                  </div>
                )}
              </div>
              <Grid container className="message-input-container">
                <form onSubmit={handleSubmit} ref={formRef}>
                  <Grid container>
                    <Grid item xs={showMdAndUp ? 12 : 10}>
                      <TextField
                        fullWidth
                        value={message}
                        placeholder="Talky Talk."
                        onChange={handleChangeMessage}
                        variant="outlined"
                        className="chat-input"
                      />
                    </Grid>
                    {!showMdAndUp && (
                      <Grid item xs={2} className="mobile-btn-cont">
                        <Fab
                          size="small"
                          onClick={(e) => handleSendMessage(e)}
                          className="mobile-chat-btn"
                          ref={sendButtonRef}
                        >
                          <img src={`/images/arrow-right-wht-chat.svg`} />
                        </Fab>
                      </Grid>
                    )}
                  </Grid>
                </form>
                <Grid container>
                  {showMdAndUp ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className="em-send-btn-container"
                      >
                        {copy.disableChatToggle ? (
                          <Box className="d-pop-vis">
                            <Popover
                              id="disabled-chat-popover"
                              // style={{ pointerEvents: 'none' }}
                              open={open}
                              onClose={handlePopoverClose}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                              }}
                            >
                              <Box p={3} style={{ backgroundColor: '#A0AEAA' }}>
                                <Typography
                                  variant="body1"
                                  className="disabled-chat-copy"
                                >
                                  {/* {copy.chatDisabledCopy.chatDisabledCopy}
                                  &nbsp;
                                  <a
                                    href="mailto:info@thesupply.com"
                                    target="_blank"
                                  >
                                    info@thesupply.com
                                  </a> */}
                                  We're working on some AI chatbot fun, for now,
                                  use the "email instead" button please.
                                </Typography>
                              </Box>
                            </Popover>
                            <Button
                              fullWidth
                              className="send-msg-btn"
                              variant="contained"
                              color="primary"
                              ref={sendButtonRef}
                              disabled
                              aria-owns={'disable-chat-popover'}
                              aria-haspopup="true"
                            >
                              Send
                            </Button>
                          </Box>
                        ) : (
                          <Button
                            fullWidth
                            onClick={(e) => handleSendMessage(e)}
                            className="send-msg-btn"
                            variant="contained"
                            color="primary"
                            ref={sendButtonRef}
                          >
                            Send
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={12} className="em-send-btn-container">
                        <Button
                          variant="contained"
                          color="secondary"
                          className="email-btn email-sm"
                          fullWidth
                          onClick={() => setChecked(true)}
                          startIcon={
                            <img
                              src={`/images/arrow-left-email-btn.svg`}
                              style={{ marginRight: 'auto' }}
                            />
                          }
                        >
                          Email us
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid container>
                      <Grid
                        item
                        xs={10}
                        style={{ alignSelf: 'center' }}
                        onClick={() => setChecked(true)}
                      >
                        <img
                          src={`/images/arrow-left-email-btn.svg`}
                          style={{
                            display: 'inline-block',
                            width: '30px',
                            marginRight: '1.25rem',
                            marginBottom: '-2px',
                          }}
                        />
                        <Typography variant="caption">
                          Email us instead?
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Slide>
      ) : null}
      {checked ? (
        <Slide
          direction="left"
          in={checked}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 400, exit: 400 }}
        >
          <div>
            <EmailContainer setChecked={setChecked} />
          </div>
        </Slide>
      ) : null}
    </div>
  );
};

export default Chat;
