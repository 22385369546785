import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import InfoButton from './infoButton';
import SlideInTitle from '../slidein-title';
import { useTheme } from '@material-ui/core/styles';

const WhoWePartnerWithMobile = ({ content, active }) => {
  const theme = useTheme();

  const partsGrid = (items) => (
    <div className="parts">
      {items.map((item, i) => (
        <div className="part" key={`work-with-${i}`}>
          <Typography variant="h4">
            {item.jobCategory || item.specialty}
            {i === 0 ? (
              <InfoButton
                content={content.questionTooltipCopy.questionTooltipCopy}
              />
            ) : null}
          </Typography>
          <Typography variant="body2">
            {(item.jobTitle || item.specialtyItem).map((listItem, j) => (
              <React.Fragment key={`title-part-${i}-${j}`}>
                {listItem}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </div>
      ))}
    </div>
  );

  const titleParts = useMemo(() => {
    return [
      ...content.titleMinimalTheme.split(' '),
      content.talentclientToggleMinimalTheme[0],
    ];
  }, [content]);

  return (
    <>
      <div className="mobile-group">
        <div className="header">
          <SlideInTitle id={1} active={active}>
            {titleParts.map((part, index) => (
              <Typography
                key={`partner-with-title-line-${index}`}
                variant="h1"
                style={{
                  color:
                    index === titleParts.length - 1
                      ? theme.palette.supply.green.main
                      : null,
                }}
              >
                {part}
              </Typography>
            ))}
          </SlideInTitle>
        </div>
        <Typography variant="body2" className="description">
          {content.talentDescription.talentDescription}
        </Typography>

        {partsGrid(content.talentItem)}
      </div>
      <div className="mobile-group">
        <div className="header">
          <SlideInTitle id={1} active={active}>
            <Typography
              key={`partner-with-title-line-part2`}
              variant="h1"
              style={{ color: theme.palette.supply.eraser.main }}
            >
              {content.talentclientToggleMinimalTheme[1]}
            </Typography>
          </SlideInTitle>
        </div>
        <Typography variant="body2" className="description">
          {content.clientDescription.clientDescription}
        </Typography>

        {partsGrid(content.clientItem)}
      </div>
    </>
  );
};

export default WhoWePartnerWithMobile;
