import { css } from '@emotion/react';

const styles = (theme) => css`
  position: relative;
  .MuiDialog-paper {
    border-radius: 20px;
    border: 3px solid;
    background-color: ${theme.palette.supply.cream.main};
    max-width: 900px;
  }

  .close-modal {
    cursor: pointer;
    align-self: flex-end;
    ${theme.breakpoints.down('sm')} {
      margin-top: 12px;
    }
  }

  .grid-img-grid {
    ${theme.breakpoints.up('md')} {
      width: 70%;
      margin: auto;
      max-width: 836px;
    }
  }

  .grid-img-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .grid-img {
    max-width: 189px;
    vertical-align: middle;
  }

  .inner-top {
    padding: 0 2rem;
    text-align: center;
    ${theme.breakpoints.up('sm')} {
      padding: 0 2rem 4rem;
    }
    ${theme.breakpoints.up('md')} {
      padding: 0 15rem;
    }
  }

  .bubble-area {
    padding-bottom: 5rem;
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 4rem;
  }

  .orgs-copy {
    margin-top: 5rem;
    margin-bottom: 0;

    h5 {
      font-size: 4.5rem;
      font-weight: normal;
      margin-bottom: 2rem;
    }
  }

  .network-item {
    text-align: center;
    margin: 1rem 0;
    ${theme.breakpoints.up('sm')} {
      margin: 3rem 0;
    }
    h6 {
      font-size: 1.5rem;
      padding: 7px 15px;
      color: #fff;
      border-radius: 30px;
      cursor: pointer;
      z-index: 2;
      position: relative;
      white-space: nowrap;
      display: inline-block;
      text-align: center;
      background-color: ${theme.palette.supply.eraser.main};
      transform: rotate(-6.5deg);
      ${theme.breakpoints.up('sm')} {
        transform: rotate(5deg);
      }
    }

    .collaboration {
      transform: rotate(5deg);
      background-color: ${theme.palette.supply.cobalt.main};
    }
    .community {
      background-color: ${theme.palette.supply.salmon.main};
      transform: rotate(5deg);
      ${theme.breakpoints.up('sm')} {
        transform: rotate(-6.5deg);
      }
    }
    .tips {
      transform: rotate(-6.5deg);
      background-color: ${theme.palette.supply.orange.main};
    }
  }

  .chat-copy {
    margin-top: 2rem;
  }

  .n120 {
    ${theme.breakpoints.up('md')} {
      margin-top: -120px;
    }
  }

  .n20 {
    ${theme.breakpoints.up('md')} {
      margin-bottom: -38px;
    }
  }
`;

export default styles;
