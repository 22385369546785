import React from 'react';
import { Dialog, Zoom, Typography, Grid, Button } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { closeChatBtnStyles } from '../chat/chatButton.styles';
import styles from './networkModal.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const NetworkModal = ({ showNetworkModal, onClose }) => {
  const { contentfulOurNetworkModal } = useStaticQuery(graphql`
    query NetworkModalContentQuery {
      contentfulOurNetworkModal {
        title
        bodyCopy {
          bodyCopy
        }
        bodyCopyPart2 {
          bodyCopyPart2
        }
      }
    }
  `);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom in={showNetworkModal} ref={ref} {...props} />;
  });

  const theme = useTheme();
  const showMed = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  return (
    <Dialog
      BackdropProps={{
        style: { backgroundColor: 'rgba(255, 255, 255, 0.85)' },
      }}
      open={showNetworkModal}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      css={styles}
    >
      <Button
        css={closeChatBtnStyles}
        onClick={onClose}
        className="close-modal"
        color="primary"
        size="small"
        variant="text"
        endIcon={<img src="/images/closechaticon.svg" />}
      >
        {showMed && <Typography variant="body1">Close</Typography>}
      </Button>
      <Grid
        container
        className="inner-top"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className="orgs-copy">
          <Typography variant="h5">
            {contentfulOurNetworkModal.title}
          </Typography>
          <Typography variant="body1">
            {contentfulOurNetworkModal.bodyCopy.bodyCopy}
          </Typography>
          {!!contentfulOurNetworkModal.bodyCopyPart2.bodyCopyPart2 && (
            <Typography variant="body1" className="chat-copy">
              {contentfulOurNetworkModal.bodyCopyPart2.bodyCopyPart2}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        className="bubble-area"
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={6} md={3} className="n120">
          <div className="network-item">
            <Typography variant="h6" className="collaboration">
              Collaborations
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} md={3} className="n20">
          <div className="network-item">
            <Typography variant="h6" className="tips">
              Insider Tips
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} md={3} className="c1 n20">
          <div className="network-item">
            <Typography variant="h6" className="community">
              Community
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={3} className="n120">
          <div className="network-item">
            <Typography variant="h6">Partnerships</Typography>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NetworkModal;
