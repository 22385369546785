import React, {
  useEffect,
  useRef,
  useState,
  createRef,
  useCallback,
} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { DragContainer } from '../draggable-box/DragContainer';
import { CustomDragLayer } from '../draggable-box/CustomDragLayer';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useLayoutContext } from '../../contexts/layoutContext';
import { useTransition, animated } from 'react-spring';
import styles, {
  backgroundStyles,
  backgroundDotStyles,
  dragStyles,
} from './heroSection.styles';
// import Ticker from './ticker';

import ScrollContainer from '../scroll-container';

gsap.registerPlugin(ScrollTrigger);
const SCROLL_TRIGGER_ID = `heroAnimation`;

const HeroSection = ({ heroSection }) => {
  const [index, setIndex] = useState(0);
  const [eIndex, setEIndex] = useState(0);
  const {
    firstAnimateIn,
    setFirstLoadOut,
    firstLoadIn,
    setFirstAnimateIn,
  } = useLayoutContext();

  const [items] = useState(
    heroSection.titleChangingParts.map((part, i) => ({
      id: i,
      title: part,
    }))
  );

  const fadingTextPropsTransition = useTransition(
    items[index],
    (item) => item.id,
    {
      from: {
        opacity: 0,
        top: '100%',
        transform: 'translate3d(-50%,100%,0) rotate(17deg)',
      },
      enter: {
        opacity: 1,
        top: '0%',
        transform: 'translate3d(-50%,0,0) rotate(0deg)',
      },
      leave: {
        opacity: 0,
        top: '0%',
        transform: 'translate3d(-50%,100%,0) rotate(0deg)',
      },
      config: {
        tension: 160,
        friction: 20,
        clamp: true,
        velocity: 0.026,
      },
    }
  );

  const [emojis] = useState([
    { title: '⛷  and  🍻', id: 0 },
    { title: '🏍  and  🥃', id: 1 },
    { title: '☕️  and  🏀', id: 2 },
    { title: '🛹  and  🍕', id: 3 },
    { title: '🏕  and  🥞', id: 4 },
  ]);

  const emojiPropsTransition = useTransition(
    emojis[eIndex],
    (emoji) => emoji.id,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: {
        tension: 160,
        friction: 20,
        clamp: true,
        velocity: 0.026,
      },
    }
  );

  const heroCopyRef = useRef();
  const [boxPos, setBoxPos] = useState(null);
  const [showBox, setShowBox] = useState(false);
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  //for animations
  const changeInterval = useRef(null);
  const timeline = useRef(null);
  const wrapperRef = useRef(null);
  const logoRef = useRef(null);
  const cloverRef = useRef(null);
  const brnStarRef = useRef(null);
  const draggableBoxRef = useRef(null);

  const reg = new RegExp(/\s/g, '');
  const headlineSegments = heroSection.headline.split(reg);
  const headlineWords = useRef(headlineSegments.map(() => createRef()));

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex((state) => (state + 1) % items.length);
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setEIndex((state) => (state + 1) % emojis.length);
    }, 3000);

    return () => {
      clearInterval(interval);
      if (changeInterval.current) clearInterval(changeInterval.current);
    };
  }, []);

  const initInterval = () => {
    if (changeInterval.current) clearInterval(changeInterval.current);
    changeInterval.current = setInterval(() => {
      setIndex((state) => (state + 1) % items.length);
    }, 3000);
  };

  const afterIntroAnim = () => {
    initInterval();
    setFirstAnimateIn(false);
    setFirstLoadOut(false);
  };

  useEffect(() => {
    if (!firstLoadIn) doInAnimation();

    return () => {
      if (!timeline.current) return;
      timeline.current.kill();
      const scrollTriggerRef = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (scrollTriggerRef) {
        scrollTriggerRef.kill(true);
      }
    };
  }, []);

  useEffect(() => {
    if (!firstLoadIn) doInAnimation();
  }, [firstLoadIn]);

  const doInAnimation = useCallback(() => {
    //set up the timeline animations using scroll trigger
    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: SCROLL_TRIGGER_ID,
        start: 'top 70%',
        end: 'bottom bottom',
        trigger: wrapperRef.current,
      },
    });

    for (let i = 0; i < headlineWords.current.length; i++) {
      let part = headlineWords.current[i].current;

      if (part) {
        gsap.set(part, {
          y: -window.innerHeight,
          rotate:
            i % 2 === 0 ? -10 - Math.random() * 10 : 10 + Math.random() * 10,
          opacity: 1,
        });
      }
    }

    timeline.current.to([...headlineWords.current.map((ref) => ref.current)], {
      stagger: {
        amount: -0.4,
      },
      duration: 1.5,
      delay: 0.6,
      y: 0,
      opacity: 1,
      rotate: 0,
      ease: 'expo.out',
      onComplete: () => {
        setShowBox(true);
      },
    });

    timeline.current.to(
      logoRef.current,
      {
        duration: 0.5,
        scale: 1,
        rotate: 0,
        x: 0,
        y: 0,
        opacity: 1,
        ease: 'back.out',
      },
      '-=.5'
    );

    timeline.current.fromTo(
      cloverRef.current,
      {
        x: window.innerWidth / 3,
        opacity: 0,
        rotate: 800,
      },
      {
        duration: 0.5,
        x: 0,
        scale: 1,
        opacity: 1,
        rotate: 21,
        ease: 'power2.out',
      },
      '-=.2'
    );

    timeline.current.to(
      brnStarRef.current,
      {
        duration: 0.25,
        x: 0,
        scale: 1,
        opacity: 1,
        rotate: '301deg',
        ease: 'expo.out',
        onComplete: () => {
          afterIntroAnim();
        },
      },
      '-=.2'
    );
  }, []);

  useEffect(() => {
    const { left } = heroCopyRef.current.getBoundingClientRect();
    const { offsetHeight } = heroCopyRef.current;
    setBoxPos({ top: offsetHeight + 177, left: left + 10 });
  }, [heroCopyRef]);

  return (
    <ScrollContainer id={0}>
      <div style={{ position: 'relative' }} ref={wrapperRef}>
        {boxPos && showMdAndUp && (
          <div css={dragStyles} ref={draggableBoxRef}>
            <DragContainer
              content={heroSection}
              boxPos={boxPos}
              isShown={showBox}
            />
            <CustomDragLayer />
          </div>
        )}
        <Container maxWidth={false} css={styles} id="section0">
          {/* {showMdAndUp && <Ticker />} */}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="top-images"
          >
            <img
              src={`images/star-brn.svg`}
              className="brn-star"
              ref={brnStarRef}
            />
            <Grid item xs={12}>
              <div className="hero-logo top-image">
                <div className="img-wrapper" ref={logoRef}>
                  <img src={`images/logo-no-text.svg`} alt="The Supply" />
                </div>
              </div>
            </Grid>
          </Grid>
          <Container maxWidth="lg" className="side-padding">
            <Grid container css={backgroundStyles}>
              <Grid
                item
                xs={12}
                className="rich-text-section"
                ref={heroCopyRef}
              >
                <Typography
                  variant="h1"
                  style={{
                    textIndent: '-9000px',
                    height: 0,
                    visibility: 'hidden',
                  }}
                >
                  The Supply / Curated Digital Talent
                </Typography>
                <Typography variant="h2" component="div" className="main-title">
                  <span ref={headlineWords.current[0]}>
                    {heroSection.titleTopParts.join(' ')}
                  </span>
                  <div
                    ref={headlineWords.current[1]}
                    className="word-container"
                  >
                    {fadingTextPropsTransition.map(({ item, props, key }) => (
                      <animated.div
                        key={key}
                        style={{ ...props, position: 'absolute' }}
                        className="fading-text"
                      >
                        <Typography className="bogue-font">
                          {item.title}
                        </Typography>
                      </animated.div>
                    ))}
                  </div>
                  {/* <span ref={headlineWords.current[2]}>
                    {heroSection.titleBottomParts.join(' ')}
                  </span> */}
                  <div className="" ref={headlineWords.current[2]}>
                    <span>{heroSection.withA}</span>{' '}
                    <span className="bogue-font">
                      {heroSection.endOfHeadline}
                    </span>
                  </div>
                </Typography>

                <div className="mobile-dragbox">
                  <div>
                    {
                      <div>
                        <Typography
                          variant="body1"
                          style={{ display: 'inline-block' }}
                          gutterBottom
                          component="div"
                        >
                          {heroSection.introPart1.introPart1} folks we
                          <div
                            style={{
                              position: 'relative',
                              width: '84px',
                              display: 'inline-block',
                              height: '19px',
                              marginLeft: '4px',
                            }}
                          >
                            {emojiPropsTransition.map(
                              ({ item, props, key }) => (
                                <animated.div
                                  key={key}
                                  style={{
                                    ...props,
                                    position: 'absolute',
                                    top: 0,
                                    left: '2px',
                                  }}
                                >
                                  <div>{item.title}</div>
                                </animated.div>
                              )
                            )}
                          </div>
                          with, not just keywords in a search field.
                        </Typography>
                        {!!heroSection.introPart2.introPart2 && (
                          <Typography variant="body1">
                            {heroSection.introPart2.introPart2}
                          </Typography>
                        )}
                      </div>
                    }
                  </div>
                </div>
                <span className="clover" ref={cloverRef}></span>
              </Grid>
            </Grid>
          </Container>
        </Container>
        <div css={backgroundDotStyles}></div>
      </div>
    </ScrollContainer>
  );
};

export default HeroSection;
