import { css, keyframes } from '@emotion/react';

const styles = (theme) => css`
  border: 1px solid ${theme.palette.supply.darkGray};
  padding: ${theme.spacing(6, 2)};
  border-top: none;

  h2 {
    margin: ${theme.spacing(4, 0)};
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(4, 0, 0)};
    }
  }

  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(10, 8)};
  }

  .caption {
    display: block;
    margin: ${theme.spacing(0, 0, 6, 0.5)};
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(0, 0, 4, 1.5)};
    }
  }

  button {
    margin-top: ${theme.spacing(4)};
  }

  .imageContainer {
    text-align: center;
  }

  ${theme.breakpoints.up('md')} {
    h2 {
      margin-bottom: ${theme.spacing(8)};
    }

    .pushingDiversity {
      h2 {
        margin: ${theme.spacing(8, 0)};
      }
      br {
        display: none;
      }
    }
    button {
      width: auto;
      margin-top: 0;
    }
  }
`;

export default styles;
