import React, { useState, useEffect, useRef } from 'react';
import { useScrollContext } from '../../contexts/scrollContext';
import { useLayoutContext } from '../../contexts/layoutContext';
import useScrollEnter from '../../hooks/use-scroll-enter';

const ScrollContainer = ({
  children,
  id,
  styles,
  ignore,
  onEnter = () => {},
  onEnterBack = () => {},
  onLeave = () => {},
  onLeaveBack = () => {},
  onUpdate = () => {},
  triggerPos,
  triggerPosBot,
}) => {
  const { updateActiveSection } = useScrollContext();
  const { isMinimal } = useLayoutContext();
  const sectionRef = useRef();

  useScrollEnter({
    ref: sectionRef,
    id: `scroll-container-${isMinimal ? 'minimal' : 'creative'}-${id}`,
    onEnter: () => {
      onEnter();
      if (!ignore) {
        updateActiveSection(id);
        let section = [
          'hero',
          'partners',
          'services',
          'why-us',
          'team',
          'tools',
        ];
        if (typeof window !== 'undefined' && window.gtag) {
          window.gtag('event', 'scroll', {
            theme: isMinimal ? 'minimal' : 'playful',
            section: section[id],
          });
        }
      }
    },
    onEnterBack: onEnterBack,
    onLeave: onLeave,
    onUpdate: onUpdate,
    onLeaveBack: () => {
      onLeaveBack();
      if (!ignore) updateActiveSection(id > 0 ? id - 1 : 0);
    },
    // triggerPos: 'center',
    triggerPos: triggerPos || 'bottom-=25%',
    triggerPosBot: triggerPosBot,
    scrubTimeline: null,
  });

  return (
    <>
      <div
        id={`scroll-section-${id}`}
        data-section={id}
        ref={sectionRef}
        css={styles}
      >
        {children}
      </div>
    </>
  );
};

export default ScrollContainer;
