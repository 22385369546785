import React, { useRef, useState, createRef } from 'react';
import { Button, Container, Typography, Grid, Box } from '@material-ui/core';
import styles from './meetTheTeam.styles';
import useScrollAnimation from '../../hooks/use-scroll-animation';
import NetworkModal from './networkModal';
import ScrollContainer from '../scroll-container';

const MeetTheTeamSection = ({ meetTheTeamSection }) => {
  const [showNetworkModal, setShowNetworkModal] = useState(false);
  const { teamMember, title, hiringBottom } = meetTheTeamSection;
  const wrapper = useRef(null);
  const teamMemberRef = useRef(null);
  const titleRef = useRef(null);

  const teamMembersRef = useRef(
    [...Array(teamMember.length)].map(() => createRef())
  );

  useScrollAnimation(4, wrapper, [titleRef, ...teamMembersRef.current]);

  const handleOpen = () => {
    setShowNetworkModal(true);
  };

  const handleClose = () => {
    setShowNetworkModal(false);
  };

  return (
    <ScrollContainer id={4}>
      <Container
        maxWidth={false}
        className="section-padding"
        css={styles}
        id="section4"
      >
        <Container maxWidth="lg" className="side-padding">
          <Grid container ref={wrapper}>
            <Grid item xs={12} className="team-section-title" ref={titleRef}>
              <Typography variant="h5" component="h2">
                {title}
              </Typography>
            </Grid>
            {/* <Grid container className="member-wrapper" ref={teamMemberRef}> */}
            <Grid container className="member-wrapper">
              {teamMember.map((teamMember, index) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  key={teamMember.id}
                  className="teammate-container"
                  ref={teamMembersRef.current[index]}
                >
                  <div className="profile-img">
                    <img src={teamMember.profileImage.file.url} />
                  </div>
                  <Typography variant="h6" className="teammate-name">
                    {teamMember.name}
                  </Typography>
                  <Typography variant="body1">{teamMember.bio.bio}</Typography>
                  <a
                    href={teamMember.linkedinLink}
                    target="_blank"
                    rel="noopener"
                    className="linkedin-icon"
                  >
                    <img src={teamMember.linkedinIcon.file.url} />
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={4}>
              {/* <Box pt={3} onClick={handleOpen} className="hiring-img-container">
                <img
                  src="/images/start_spin.png"
                  className="hiring-img-lower"
                />
              </Box> */}
              <Button className="modal-link" onClick={handleOpen}>
                Our Network
              </Button>
            </Grid>
          </Grid>
          {showNetworkModal && (
            <NetworkModal
              onClose={handleClose}
              showNetworkModal={showNetworkModal}
            />
          )}
        </Container>
      </Container>
    </ScrollContainer>
  );
};

export default MeetTheTeamSection;
