import React, { useRef, useState, useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import DesktopVersion from './desktop3';
import MobileVersion from './mobile';
import styles, { activeStyles, changingStyles } from './partnerWith.styles';
import ScrollContainer from '../../components/scroll-container';
import { useLayoutContext } from '../../contexts/layoutContext';

const WhoWePartnerWith = ({ whoWeWorkWithSection }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );
  const { changing } = useLayoutContext();
  const [active, setActive] = useState(false);

  return (
    <ScrollContainer
      id={1}
      styles={[
        styles,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
      onEnter={() => {
        setActive(true);
      }}
      onLeaveBack={() => {
        setActive(false);
      }}
    >
      {showMdAndUp ? (
        <DesktopVersion content={whoWeWorkWithSection} active={active} />
      ) : (
        <MobileVersion content={whoWeWorkWithSection} active={active} />
      )}
    </ScrollContainer>
  );
};

export default WhoWePartnerWith;
