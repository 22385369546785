import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Typography } from '@material-ui/core';
import styles from './navigation.styles';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Link } from 'react-scroll';
import useScrollAnimation from '../../hooks/use-scroll-animation';

gsap.registerPlugin(ScrollTrigger);

const Navigation = ({ pageTitles }) => {
  const navRef = useRef(null);
  useScrollAnimation(null, navRef, [navRef]);

  useEffect(() => {
    gsap.from(navRef.current, {
      scrollTrigger: {
        trigger: navRef.current,
        start: 'top 76%',
        end: 'bottom top',
        toggleClass: 'navHide',
      },
    });
  }, []);

  return (
    <div css={styles} id="navbar">
      <div className="nav-wrapper navHide" ref={navRef}>
        {pageTitles.map((pageTitle, index) => {
          return (
            <Link
              activeClass="active"
              to={`scroll-section-${index}`}
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
              className="nav-item"
              key={index}
            >
              <Typography variant="caption">{pageTitle.navTitle}</Typography>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;
