import { keyframes } from '@emotion/react';

export const pulse = keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const arrowDownOutIn = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0;
    transform: translateY(60px);
  }
  50.00001% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const arrowDiagonalOutIn = keyframes`
  0% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(45deg);
  }
  50% {
    opacity: 0;
    transform: translate(45px, -45px) rotate(45deg);
  }
  50.00001% {
    opacity: 0;
    transform: translate(-45px, 45px) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(45deg);
  }
`;

export const arrowUpOutIn = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0;
    transform: translateY(-60px);
  }
  50.00001% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const arrowUpOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-60px);
  }
`;

export const arrowUpIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const arrowRightOutIn = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 0;
    transform: translateX(60px);
  }
  50.00001% {
    opacity: 0;
    transform: translateX(-60px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const bounceDown = keyframes`
  0% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(10px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0px);
  }
`;

export const bounceRight = keyframes`
  0% {
    transform: translateX(0px) rotate(-90deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateX(10px) rotate(-90deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(0px) rotate(-90deg);
  }
`;

export const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

export const floatRight = keyframes`
    0% {
        transform:translate(1px, -1px);
    }

    25% {
        transform:translate(1px, 1px);
    }

    50% {
        transform:translate(-1px, 1px);
    }

    75% {
        transform:translate(-1px, -1px);
    }

    100% {
        transform:translate(1px, -1px);
    }
`;

export const floatLeft = keyframes`
    0% {
        transform:translate(-1px, -1px);
    }

    25% {
        transform:translate(-1px, 1px);
    }

    50% {
        transform:translate(1px, 1px);
    }

    75% {
        transform:translate(1px, -1px);
    }

    100% {
        transform:translate(-1px, -1px);
    }
`;

export const scaleRotate = keyframes`
    0% {
        transform:scale(1);
    }

    50% {
        transform:scale(.99);
    }

    100% {
        transform:scale(1);
    }
`;

export const rotate1 = keyframes`
    0% {
        transform: rotate(-6deg);
    }

    33% {
        transform: rotate(-5deg);
    }
    
    66% {
        transform: rotate(-7deg);
    }

    100% {
        transform: rotate(-6deg);
    }
`;

export const rotate2 = keyframes`
    0% {
        transform: rotate(7deg);
    }

    33% {
        transform: rotate(6deg);
    }
    
    66% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(7deg);
    }
`;

export const rotate3 = keyframes`
    0% {
        transform: rotate(5deg);
    }

    33% {
        transform: rotate(6deg);
    }
    
    66% {
        transform: rotate(4deg);
    }

    100% {
        transform: rotate(5deg);
    }
`;
