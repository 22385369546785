import { css } from '@emotion/react';
import { initiallyHidden } from '../shared/shared.styles';

const styles = (theme) => css`
  text-align: center;
  background-color: ${theme.palette.supply.seaGreen.main};
  min-height: 100vh;

  .team-section-title {
    ${initiallyHidden}
    position: relative;
    margin-bottom: 6rem;
  }

  .profile-img {
    mask: url('images/profile-bg.svg') center center / cover;
    margin: 0 auto 4.5rem;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    max-width: 300px;
    aspect-ratio: 250 / 250;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .member-wrapper {
    // ${initiallyHidden}
    display: flex;
    justify-content: center;
  }

  .teammate-container {
    margin-bottom: 4.4rem;
    ${theme.breakpoints.up('sm')} {
      padding: 0 2rem;
    }
    ${theme.breakpoints.up('md')} {
      padding: 0 2rem;
    }
  }

  .teammate-name {
    margin: 3.5rem 0 2.5rem;
    color: ${theme.palette.supply.desertTan.main};
  }

  .linkedin-icon {
    margin: 3rem 0 2rem;
    display: inline-block;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 36px;
    aspect-ratio: 1 / 1;
    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 0%;
      height: 32px;
      background-color: #fff;
      transition: all 0.3s;
      border-radius: 12px;
      z-index: -1;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }

  .hiring-img-container {
    position: relative;
    aspect-ratio: 1 / 1;
    &:after {
      content: '';
      background-image: url(/images/our-network-words.png);
      width: 107px;
      height: 13px;
      background-repeat: no-repeat;
      display: block;
      z-index: 9;
      position: absolute;
      top: 54%;
      left: calc(50% - 58px);
      background-size: cover;
      pointer-events: none;
    }
  }

  .hiring-img-lower {
    max-width: 220px;
    transform: rotate(1deg);
    cursor: pointer;

    animation: SPIN 26s linear infinite;
    margin-left: -5px;
    margin-top: 15px;
    width: 145px;
    height: auto;
  }

  @keyframes SPIN {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .modal-link {
    margin-top: 4rem;
    font-weight: bold;
    background-color: ${theme.palette.supply.salmon.main};
    color: ${theme.palette.text.primary};
    transition: 0.25s ease;
    width: 100%;
    max-width: 277px;
    align-self: center;
    padding: 9px 30px;
    ${theme.breakpoints.up('md')} {
      align-self: auto;
    }
    &:hover {
      background-color: #c58876;
      transition: 0.25s ease;
    }
  }
`;

export default styles;
