import React from 'react';
import { css } from '@emotion/react';
import { pulse } from '../../components/shared/animations.styles';

const styles = (theme) => css`
  .background {
    fill: ${theme.palette.supply.salmon.main};
  }

  .border,
  .line1,
  .line2,
  .line3 {
    fill: ${theme.palette.supply.darkGray};
  }

  .line1,
  .line2,
  .line3 {
    opacity: 0;
  }
`;

const activeStyles = (theme) => css`
  .line1,
  .line2,
  .line3 {
    animation: ${pulse} 5s infinite forwards;
  }

  .line1 {
    animation-delay: 200ms;
  }
  .line2 {
    animation-delay: 300ms;
  }
  .line3 {
    animation-delay: 400ms;
  }
`;

const ChatIcon = ({ active }) => {
  return (
    <svg
      css={[styles, active ? activeStyles : null]}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 283.5 283.5"
    >
      <path
        className="border"
        d="M235.7,80.1h-51.9v-7.4c0-11.5-7.9-20.8-17.7-20.8H47.7c-9.7,0-17.7,9.3-17.7,20.8v82
		c0,11.5,7.9,20.8,17.7,20.8h15.4V208c0,1.9,1.3,3.6,3.1,4.2c0.4,0.1,0.8,0.2,1.3,0.2c1.4,0,2.8-0.7,3.6-1.9l23.5-35h5v7.4
		c0,11.5,7.9,20.8,17.7,20.8l71.5,0l23.5,35c0.8,1.2,2.2,1.9,3.6,1.9c0.4,0,0.9-0.1,1.3-0.2c1.8-0.6,3.1-2.3,3.1-4.2v-32.5h15.4
		c9.7,0,17.7-9.3,17.7-20.8v-82C253.4,89.4,245.5,80.1,235.7,80.1L235.7,80.1z"
      />
      <path
        className="background"
        d="M71.9,171.1c0-2.4-2-4.4-4.4-4.4H47.7c-4.8,0-8.9-5.5-8.9-12l0-82c0-6.5,4.1-12,8.9-12
	h118.5c4.8,0,8.9,5.5,8.9,12v82c0,6.5-4.1,12-8.9,12H92.3c-1.5,0-2.8,0.8-3.6,1.9l-16.8,25L71.9,171.1z"
      />
      <path
        className="background"
        d="M244.7,182.9c0,6.5-4.1,12-8.9,12h-19.8c-2.4,0-4.4,2-4.4,4.4v22.6l-16.8-25
	c-0.8-1.3-2.2-1.9-3.6-1.9h-73.9c-4.8,0-8.9-5.5-8.9-12v-7.4h57.8c9.7,0,17.7-9.3,17.7-20.8l0-65.9h51.9c4.8,0,8.9,5.5,8.9,12
	L244.7,182.9L244.7,182.9z"
      />
      <path
        className="line3"
        d="M147.3,146H66c-2.4,0-4.4-2-4.4-4.4c0-2.4,2-4.4,4.4-4.4h81.3c2.4,0,4.4,2,4.4,4.4
	C151.7,144,149.8,146,147.3,146L147.3,146z"
      />
      <path
        className="line2"
        d="M62.2,113c0-2.4,2-4.4,4.4-4.4h81.3c2.4,0,4.4,2,4.4,4.4c0,2.4-2,4.4-4.4,4.4l-81.3,0
	C64.2,117.4,62.2,115.4,62.2,113L62.2,113z"
      />
      <path
        className="line1"
        d="M62.2,84.5c0-2.4,2-4.4,4.4-4.4h81.3c2.4,0,4.4,2,4.4,4.4c0,2.4-2,4.4-4.4,4.4H66.6
	C64.2,88.8,62.2,86.9,62.2,84.5L62.2,84.5z"
      />
    </svg>
  );
};

export default ChatIcon;
