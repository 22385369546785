import { css } from '@emotion/react';

const styles = (theme) => css`
  .slidein-title-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    .word-container {
      position: relative;
      display: flex;
      // height: 75px;
      height: ${75 * theme.ui.lineHeight.m}px;
      width: 100%;

      .word-inner-wrapper {
        position: relative;
        display: flex;
        flex-shrink: 0;
        white-space: nowrap;
        width: 100%;
        height: ${75 * theme.ui.lineHeight.m + 10}px;
        margin-top: -5px;
        overflow: hidden;
        transform: translate3d(0, 0, 0);

        > span {
          height: 100%;
          flex-shrink: 0;
        }
      }

      ${theme.breakpoints.up('sm')} {
        margin: 0;
        height: ${95 * theme.ui.lineHeight.m}px;
        .word-inner-wrapper {
          height: ${95 * theme.ui.lineHeight.m + 20}px;
          margin-top: -10px;
        }
      }
      ${theme.breakpoints.up('md')} {
        height: ${120 * theme.ui.lineHeight.m}px;
        .word-inner-wrapper {
          height: ${120 * theme.ui.lineHeight.m + 30}px;
          margin-top: -15px;
        }
      }
      ${theme.breakpoints.up('lg')} {
        height: ${150 * theme.ui.lineHeight.m}px;
        .word-inner-wrapper {
          height: ${150 * theme.ui.lineHeight.m + 30}px;
          margin-top: -15px;
        }
      }

      .fading-text {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        height: ${75 * theme.ui.lineHeight.m + 10}px;
        padding: 5px 0;
        transform: translate3d(0, -90px, 1px);
        transition: transform 1000ms ${theme.transitions.easing.outExpo};
        will-change: transform;

        // h1 {
        //   line-height: 1;
        // }

        ${theme.breakpoints.up('sm')} {
          padding: 10px 0;
          height: ${95 * theme.ui.lineHeight.m + 20}px;
          transform: translate3d(0, -120px, 1px);
        }
        ${theme.breakpoints.up('md')} {
          padding: 15px 0;
          height: ${120 * theme.ui.lineHeight.m + 30}px;
          transform: translate3d(0, -160px, 1px);
        }
        ${theme.breakpoints.up('lg')} {
          padding: 15px 0;
          height: ${150 * theme.ui.lineHeight.m + 30}px;
          transform: translate3d(0, -190px, 1px);
        }
      }
    }
  }
`;

export const inlineStyles = (theme) => css`
  .slidein-title-wrapper {
    flex-direction: row;

    .word-container {
      width: auto;
      margin-left: ${theme.spacing(1)};

      ${theme.breakpoints.up('sm')} {
        margin-left: ${theme.spacing(2)};
      }
      ${theme.breakpoints.up('md')} {
        margin-left: ${theme.spacing(3)};
      }
      ${theme.breakpoints.up('lg')} {
        margin-left: ${theme.spacing(4)};
      }

      &:first-of-type {
        margin-left: 0;
      }

      .word-inner-wrapper {
        width: auto;
      }

      .fading-text {
        width: auto;
      }
    }
  }
`;

export const activeStyles = (theme) => css`
  .slidein-title-wrapper {
    .word-container {
      .fading-text {
        transform: translate3d(0, 0px, 1px);
      }

      &:nth-of-type(2) {
        .fading-text {
          transition-delay: 100ms;
        }
      }
      &:nth-of-type(3) {
        .fading-text {
          transition-delay: 200ms;
        }
      }
      &:nth-of-type(4) {
        .fading-text {
          transition-delay: 300ms;
        }
      }
    }
  }
`;

export const changingStyles = (theme) => css`
  .slidein-title-wrapper {
    .word-container {
      &:nth-of-type(1) {
        .fading-text {
          transition-delay: 300ms;
        }
      }
      &:nth-of-type(2) {
        .fading-text {
          transition-delay: 400ms;
        }
      }
      &:nth-of-type(3) {
        .fading-text {
          transition-delay: 500ms;
        }
      }
      &:nth-of-type(4) {
        .fading-text {
          transition-delay: 600ms;
        }
      }
    }
  }
`;

export default styles;
