import iconClover from '../../assets/svg/icon-clover.inline.svg';
import iconStar from '../../assets/svg/icon-star.inline.svg';

export const categories = {
  design: {
    items: [
      'UX/Strategy',
      'Creative direction',
      'Motion/3D',
      'Digital/Visual',
      'Product UI',
      'Writers',
      'Illustrators',
    ],
    icons: [iconClover, iconClover, iconStar, iconStar],
  },
  technology: {
    items: [
      'Software Engineer',
      'Fullstack/Backend',
      'Native Mobile',
      'Front-end Developers',
      'Tech Direction',
      'Web3 / AI',
    ],
    icons: [iconClover, iconClover, iconClover, iconStar, iconStar, iconStar],
  },
  management: {
    items: [
      'Product Managers',
      'Growth/New Biz',
      'Creative Producers',
      'Program/Account Mgmt',
      'Managing Director',
      'Project Managers',
    ],
    icons: [iconClover, iconClover, iconClover, iconStar, iconStar, iconStar],
  },
  brands: {
    items: ['Marketing in-house', 'Product teams', 'Ecommerce'],
    icons: [iconClover, iconClover, iconClover, iconStar, iconStar, iconStar],
  },
  agencies: {
    items: [
      'Design Studios',
      'Creative Technology',
      'Digital Advertising',
      'Product Consultancies',
    ],
    icons: [
      iconClover,
      iconClover,
      iconClover,
      iconClover,
      iconStar,
      iconStar,
      iconStar,
    ],
  },
  startuptech: {
    items: [
      'Social',
      'Web3 / AI',
      'Mobile Apps',
      'Tools / Platforms',
      'SAAS',
      'Blockchain',
    ],
    icons: [
      iconClover,
      iconClover,
      iconClover,
      iconClover,
      iconStar,
      iconStar,
      iconStar,
    ],
  },
};
