import React from 'react';
import { ChatBuilder } from '@papercups-io/chat-builder';
import { useStaticQuery, graphql } from 'gatsby';
import Chat from './chat';

const CHAT_ACCOUNT_TOKEN = process.env.GATSBY_CHAT_ACCOUNT_TOKEN;

// https://codepen.io/ig_design/pen/BajVZre

const ChatContainer = ({ showChatModal, onCloseChat }) => {
  const { contentfulChat } = useStaticQuery(graphql`
    query ChatCopyQuery {
      contentfulChat {
        chatDescription {
          chatDescription
        }
        chatDisclaimer {
          chatDisclaimer
        }
        chatHeadline
        introText
        disableChatToggle
        chatDisabledCopy {
          chatDisabledCopy
        }
      }
    }
  `);

  const { introText } = contentfulChat;

  const config = {
    accountId: CHAT_ACCOUNT_TOKEN,
    greeting: introText,
    // customer: {
    //   name: 'Demo User',
    //   // Ad hoc metadata
    //   metadata: {
    //     page: 'github',
    //   },
    // },
    // NB: we override these values during development if we want to test against our local server
    // baseUrl: 'http://localhost:4000',
    // For the demo, we just point at our demo staging environment
    // baseUrl: 'baseUrl: http://127.0.0.1:8000',
  };

  return (
    <ChatBuilder
      config={config}
      onChatLoaded={() => console.log('Chat loaded!')}
      onChatClosed={() => console.log('Chat closed!')}
      onChatOpened={() => console.log('Chat opened!')}
      onMessageReceived={(message) => console.log('Message received', message)}
      onMessageSent={(message) => console.log('Message sent!', message)}
    >
      {({
        config,
        state,
        onClose,
        onSendMessage,
        onToggleOpen,
        scrollToRef,
        onChatLoaded,
      }) => {
        return (
          <Chat
            config={config}
            state={state}
            onClose={onClose}
            onSendMessage={onSendMessage}
            onToggleOpen={onToggleOpen}
            scrollToRef={scrollToRef}
            onChatLoaded={onChatLoaded}
            copy={contentfulChat}
            onCloseChat={onCloseChat}
          />
        );
      }}
    </ChatBuilder>
  );
};

export default ChatContainer;
