import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Accordion = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.supply.darkGray}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.supply.darkGray}`,
    marginBottom: -1,
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
      // minHeight: 56,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    padding: theme.spacing(2),
    '&$expanded': {
      margin: 0,
      backgroundColor: theme.palette.supply.eraser.main,
      color: theme.palette.supply.cream.main,
      // margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  image: {
    height: '180px',
    margin: '0 auto',
  },
  iconWrapper: {
    position: 'relative',
    width: '50px',
    height: '100%',
  },
  icon: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    fontSize: '32px',
    lineHeight: 1,
    fontFamily: 'Whyte-Inktrap, Helvetica, sans-serif, sans-serif',
  },
}));

export default function CustomizedAccordions({ items }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(items[0].id);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {items.map((item, i) => (
        <Accordion
          key={`accordion-part-${i}`}
          square
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant="h5">{item.title}</Typography>
            <div className={classes.iconWrapper}>
              {expanded === item.id ? (
                <div
                  className={classes.icon}
                  style={{ fontFamily: `'Whyte', Helvetica, sans-serif` }}
                >
                  ↓
                </div>
              ) : (
                <div className={classes.icon}>+</div>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <img
              className={classes.image}
              src={item.iconImageMinimalTheme.file.url}
            />
            <Typography variant="body1">
              {item.description.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
