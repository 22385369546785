import React, { useRef, useEffect } from 'react';
import { useLayoutContext } from '../contexts/layoutContext';
import gsap from 'gsap';
import Navigation from '../components/layout/navigation';
import HeroSection from '../components/hero';
import WhoWePartnerWith from '../components/partner-with';
import Services from '../components/services';
import WhyUsSection from '../components/why-us';
import GivingBackSection from '../components/giving-back';
import ToolsSection from '../components/tools';
import MeetTheTeamSection from '../components/team';
import Footer from '../components/footer';
import ChatComponent from '../components/chat/chatComponent';
import ScrollContainer from '../components/scroll-container';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const SCROLL_TRIGGER_ID = `chatAnimation`;
gsap.registerPlugin(ScrollTrigger);

const Creative = ({ data, matches }) => {
  const { firstLoadOut } = useLayoutContext();
  const timeline = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    //set up the timeline animations using scroll trigger
    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: SCROLL_TRIGGER_ID,
        start: 'top 70%',
        end: 'bottom bottom',
        trigger: wrapperRef.current,
      },
    });

    return () => {
      if (!timeline.current) return;
      timeline.current.kill();
      const scrollTriggerRef = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (scrollTriggerRef) {
        scrollTriggerRef.kill(true);
      }
    };
  }, []);

  const {
    contentfulHeroSection,
    contentfulGivingBackSection,
    contentfulServicesSection,
    contentfulTools,
    contentfulWhoWeWorkWithSection,
    contentfulWhyUsSection,
    contentfulMeetTheTeam,
    contentfulFooter,
  } = data;

  const pageTitles = [
    { navTitle: contentfulHeroSection.navTitle },
    { navTitle: contentfulWhoWeWorkWithSection.whoWeWorkWithTitle },
    { navTitle: contentfulServicesSection.title },
    { navTitle: contentfulWhyUsSection.title },
    { navTitle: contentfulMeetTheTeam.title },
    { navTitle: contentfulTools.title },
  ];

  return (
    <React.Fragment>
      {matches && <Navigation pageTitles={pageTitles} />}
      <HeroSection heroSection={contentfulHeroSection} />
      {/* {!firstLoadOut && ( */}
      {/* <React.Fragment> */}
      <WhoWePartnerWith whoWeWorkWithSection={contentfulWhoWeWorkWithSection} />
      <Services servicesSection={contentfulServicesSection} />
      <ScrollContainer id={3}>
        <WhyUsSection whyUsSection={contentfulWhyUsSection} />
        <GivingBackSection givingBackSection={contentfulGivingBackSection} />
        {/* <NetworkSection networkSection={contentfulNetworkSection} /> */}
      </ScrollContainer>
      <MeetTheTeamSection meetTheTeamSection={contentfulMeetTheTeam} />
      <ToolsSection toolsSection={contentfulTools} />
      <Footer footer={contentfulFooter} />
      {/* </React.Fragment> */}
      {/* )} */}
      <ChatComponent ref={wrapperRef} />
    </React.Fragment>
  );
};

export default Creative;
