import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, Button } from '@material-ui/core';
import { styles, activeStyles } from './shapes.styles';

import useScrollEnter from '../../hooks/use-scroll-enter';

const Shapes = ({ content }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  const shapesRef = useRef(null);

  const [showShapes, setShowShapes] = useState(false);

  useScrollEnter({
    ref: shapesRef,
    id: 'why-us-shapes-scroll',
    onUpdate: (p) => {
      if (!showShapes && p.progress > 0.3) {
        setShowShapes(true);
      }
    },
    onLeaveBack: () => {
      setShowShapes(false);
    },
  });

  return (
    <div
      className="shapes-container"
      css={[styles, showShapes ? activeStyles : null]}
      ref={shapesRef}
    >
      {/* <div className="shapes-inner"> */}
      <div className="circle">
        <Typography variant="body1">Gender</Typography>
        <svg
          className="tick"
          viewBox="0 0 77 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.62476 22.9286L28.0293 43.2069L71.6575 5.44727"
            stroke="#1A201F"
            strokeWidth="9.72853"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Typography variant="body1">Diversity</Typography>
      </div>
      <div className="triangle">
        <svg
          className="bg"
          viewBox="0 0 230 199"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M115.105 0.527832L229.446 198.573H0.763668L115.105 0.527832Z"
            fill="#366BD1"
          />
        </svg>
        <Typography variant="body1" className="title">
          Cultural
        </Typography>
        <svg
          className="tick"
          viewBox="0 0 77 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.62476 22.9286L28.0293 43.2069L71.6575 5.44727"
            stroke="#1A201F"
            strokeWidth="9.72853"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Typography variant="body1">Diversity</Typography>
      </div>
      <div className="square">
        <Typography variant="body1">All</Typography>
        <svg
          className="tick"
          viewBox="0 0 77 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.62476 22.9286L28.0293 43.2069L71.6575 5.44727"
            stroke="#1A201F"
            strokeWidth="9.72853"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Typography variant="body1">Diversity</Typography>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Shapes;
