import React, { useState, useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery, Popover, Box } from '@material-ui/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ScrollContainer from '../../components/scroll-container';
import { useLayoutContext } from '../../contexts/layoutContext';
import ToolsDesktop from './desktop';
import ToolsMobile from './mobile';

gsap.registerPlugin(ScrollTrigger);

const Tools = ({ content }) => {
  const theme = useTheme();

  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  const { changing } = useLayoutContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const [active, setActive] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onEnter = useCallback(() => {
    setActive(true);
  }, []);

  const onLeave = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <ScrollContainer id={5} onEnter={onEnter} onLeaveBack={onLeave}>
      {showMdAndUp ? (
        <ToolsDesktop
          content={content}
          active={active}
          changing={changing}
          popoverOpen={open}
        />
      ) : (
        <ToolsMobile
          content={content}
          active={active}
          changing={changing}
          handlePopoverOpen={handlePopoverOpen}
        />
      )}

      <Popover
        id="mouse-over-popover2"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
      >
        <Box
          p={3}
          style={{
            backgroundColor: theme.palette.supply.darkGray,
            color: theme.palette.supply.cream.main,
          }}
        >
          <Typography>
            Coming Soon - Fine tuning some of the data to make this tool as
            helpful, current and accurate as possible.
          </Typography>
          <img
            src={`/images/sal-cover-minimal.jpg`}
            style={{ width: '100%', marginTop: '1rem' }}
          />
        </Box>
      </Popover>
    </ScrollContainer>
  );
};

export default Tools;
