import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useSprings, animated } from 'react-spring';
import { useTheme } from '@material-ui/core/styles';
import {
  Typography,
  useMediaQuery,
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import styles, { activeStyles, changingStyles } from './desktop.styles';
import SlideInTitle from '../slidein-title';
import useWindowSize from '../../hooks/use-window-size';

gsap.registerPlugin(ScrollTrigger);

const colors = ['orange', 'cobalt', 'green', 'eraser', 'salmon'];

const fn = (hovering, boxWidth, boxSizes, popoverOpen) => (i) => ({
  width: `${
    hovering
      ? hovering === i + 1
        ? boxWidth
        : boxSizes[1]
      : popoverOpen
      ? i == 0
        ? boxWidth
        : boxSizes[1]
      : boxSizes[0]
  }px`,
});

const ToolsDesktop = ({ content, active, changing, popoverOpen }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );
  const showSmAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm)
  );
  const windowSize = useWindowSize();
  const [hovering, setHovering] = useState(null);

  const boxesWrapper = useRef();

  const boxWidth = useMemo(() => {
    return showMdAndUp ? 550 : showSmAndUp ? 400 : 300;
  }, [showMdAndUp, showSmAndUp]);

  // --- --- --- --- --- --- --- ---
  // frame lerp
  // --- --- --- --- --- --- --- ---

  const [boxSizes, setBoxSizes] = useState([0, 0, 0]);

  useEffect(() => {
    let rect = boxesWrapper.current.getBoundingClientRect();
    setBoxSizes([
      rect.width / content.toolLinkItem.length,
      (rect.width - boxWidth) / (content.toolLinkItem.length - 1),
    ]);
  }, [boxWidth, boxesWrapper.current, windowSize.width]);

  const [widthSprings, widthApi] = useSprings(
    content.toolLinkItem.length,
    fn(null, boxWidth, boxSizes, popoverOpen)
  );

  useEffect(() => {
    widthApi(fn(hovering, boxWidth, boxSizes, popoverOpen));
  }, [boxWidth, boxSizes, hovering, popoverOpen]);

  // const [hoveringBtn, setHoveringBtn] = useState(false);
  // const [hoveringBtnPos, setHoveringBtnPos] = useState({ x: 0, y: 0 });

  // const onMouseOver = () => {
  //   setHoveringBtn(true);
  // };

  // const onMouseOut = () => {
  //   setHoveringBtn(false);
  // };

  // const onMouseMove = (e) => {
  //   if (hoveringBtn) {
  //     setHoveringBtnPos({ x: e.clientX, y: e.clientY });
  //   }
  // };

  return (
    <div
      css={[
        styles,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
    >
      <Grid container className="content">
        <Grid item xs={10} md={8} className="header">
          {/* <Typography variant="h1">{content.titleMinimalTheme}</Typography> */}

          <SlideInTitle id={5} active={active} inline={showMdAndUp}>
            {content.titleMinimalTheme.split(' ').map((title, index) => (
              <Typography key={`tools-title-line-${index}`} variant="h1">
                {title}
              </Typography>
            ))}
          </SlideInTitle>
        </Grid>
        <Grid item xs={2} md={4}></Grid>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="body2" className="intro">
            {content.description.description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={6} lg={8}></Grid>
        <Grid item xs={12}>
          <div
            className="box-container"
            ref={boxesWrapper}
            onMouseOut={() => {
              setHovering(null);
            }}
          >
            {widthSprings.map((springs, i) => (
              <animated.div
                key={`tool-${i}`}
                className={`tool ${
                  hovering === i + 1 || (i === 0 && popoverOpen) ? 'active' : ''
                }`}
                onMouseOver={() => {
                  setHovering(i + 1);
                }}
                style={springs}
              >
                <div className="tool-content">
                  <svg
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.4215 32.4909L18.678 21.1473L28.2149 26.82L31.5187 20.9663L28.4691 19.6115L20.841 16.0065C23.9385 14.3353 26.4893 12.9176 28.454 11.69L31.1157 10.0267L27.7892 4.70318L18.1798 11.3246L18.4661 0.21875L11.8437 0.479538L12.0731 3.24415L12.1206 4.44822L12.6982 11.5776L5.21275 7.35476L2.9315 5.96036L0 11.8457L9.94102 16.4728L0.0307269 22.0486L3.35725 27.3722L6.01902 25.7089C8.17379 24.3624 10.5029 22.7308 13.1092 20.8379C13.0377 22.5568 12.9503 25.5194 12.8945 29.5198L12.8229 32.6488L19.4215 32.4909Z"
                      fill={
                        theme.palette.supply[colors[(i + 1) % colors.length]]
                          .main
                      }
                    />
                  </svg>
                  <Typography variant="h5">
                    {content.toolLinkItem[i].title}
                  </Typography>
                  <Typography variant="body2">
                    {content.toolLinkItem[i].description.description}
                  </Typography>

                  {content.toolLinkItem[i].linkActive ? (
                    // content.toolLinkItem[i].link === '/salaries' ? (
                    //   <Button
                    //     variant="contained"
                    //     color="secondary"
                    //     onMouseOver={onMouseOver}
                    //     onMouseMove={onMouseMove}
                    //     onMouseOut={onMouseOut}
                    //     aria-owns={open ? 'mouse-over-popover2' : undefined}
                    //     aria-haspopup="true"
                    //     className={`salary-button`}
                    //   >
                    //     {content.toolLinkItem[i].linkText}
                    //   </Button>
                    // ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      href={content.toolLinkItem[i].link}
                      target="_blank"
                    >
                      {content.toolLinkItem[i].linkText}
                    </Button>
                  ) : (
                    <Button variant="contained" color="secondary" disabled>
                      {content.toolLinkItem[i].linkText}
                    </Button>
                  )}
                </div>
              </animated.div>
            ))}
          </div>

          {/* <Box
            className={`hover-box ${hoveringBtn ? 'active' : ''}`}
            p={3}
            style={{
              top: `${hoveringBtnPos.y}px`,
              left: `${hoveringBtnPos.x}px`,
            }}
          >
            <Typography>
              Coming Soon - Fine tuning some of the data to make this tool as
              helpful, current and accurate as possible.
            </Typography>
            <img
              src={`/images/sal-cover-minimal.jpg`}
              style={{ width: '100%', marginTop: '1rem' }}
            />
          </Box> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default ToolsDesktop;
