import React, { useRef, useState, useCallback } from 'react';
import useScrollEnter from '../../hooks/use-scroll-enter';
import { useTheme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import styles, { activeStyles, changingStyles } from './team.styles';
import ScrollContainer from '../../components/scroll-container';
import SlideInTitle from '../slidein-title';
import { useChatContext } from '../../contexts/chatContext';
import { useLayoutContext } from '../../contexts/layoutContext';
import LinkedinIcon from '../../assets/svg/linkedin.inline.svg';

const MeetTheTeamSection = ({ meetTheTeamSection }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );
  const { setShowChat } = useChatContext();
  const { changing } = useLayoutContext();
  const { teamMember, titleMinimalTheme, ctaCopy } = meetTheTeamSection;
  const [active, setActive] = useState(false);

  return (
    <ScrollContainer
      id={4}
      styles={[
        styles,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
      onEnter={() => {
        setActive(true);
      }}
      onLeaveBack={() => {
        setActive(false);
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent={showMdAndUp ? 'center' : 'flex-start'}
            className="header"
          >
            {/* <Typography variant="h1">{titleMinimalTheme}</Typography> */}

            <SlideInTitle id={4} active={active} inline={showMdAndUp}>
              {titleMinimalTheme.split(' ').map((title, index) => (
                <Typography key={`team-title-line-${index}`} variant="h1">
                  {title}
                </Typography>
              ))}
            </SlideInTitle>

            <Typography
              variant="h6"
              className={`hiring-pill ${active ? 'active' : ''}`}
            >
              We're Hiring
            </Typography>
          </Grid>
          <Grid container>
            <Grid item container xs={12}>
              {teamMember.map((t, i) => (
                <Grid
                  key={`team-member-${i}`}
                  item
                  xs={12}
                  sm={4}
                  className="profile"
                >
                  <div className="profile-content">
                    <div className="image-wrapper">
                      <img src={t.profileImage.file.url} />
                    </div>
                    <div className="name">
                      <Typography variant="h5">{t.name}</Typography>
                      <a
                        href={t.linkedinLink}
                        target="_blank"
                        rel="noopener"
                        className="linkedin-icon"
                      >
                        {/* <img src="images/minimal-linkedin-icon.png" /> */}
                        <LinkedinIcon />
                      </a>
                    </div>
                    <Typography variant="body1">{t.bio.bio}</Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setShowChat(true);
              }}
            >
              {ctaCopy}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ScrollContainer>
  );
};

export default MeetTheTeamSection;
