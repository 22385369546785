import { css } from '@emotion/react';
import { minimalPointerWhite } from '../../components/shared/shared.styles';

const styles = (theme) => css`
  flex-grow: 1;
  display: flex;
  border: 1px solid ${theme.palette.supply.darkGray};
  margin-top: ${theme.spacing(8)};
  ${theme.breakpoints.up('md')} {
    margin-top: ${theme.spacing(10)};
  }

  .leftContainer {
    .tabButton {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing(2, 3)};
      border-right: 1px solid ${theme.palette.supply.darkGray};
      border-bottom: 1px solid ${theme.palette.supply.darkGray};
      border-radius: 0;
      transition: all 200ms ${theme.transitions.easing.inOutSine};

      &:last-child {
        border-bottom: none;
      }

      .iconWrapper {
        position: relative;
        width: 50px;
        height: 100%;

        .icon {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 52px;
          line-height: 1;
          font-family: Whyte-Inktrap, Helvetica, sans-serif, sans-serif;
          transition: all 200ms ${theme.transitions.easing.inOutSine};
          transform: rotateX(0deg);
          opacity: 1;
          color: ${theme.palette.supply.darkGray};

          &.arrow {
            font-family: 'Whyte', Helvetica, sans-serif;
            transform: rotateX(0deg) translateX(-10px);
            opacity: 0;
            color: ${theme.palette.supply.cream.main};
          }
        }
      }

      &.active {
        background-color: ${theme.palette.supply.eraser.main};
        color: ${theme.palette.supply.cream.main};
        cursor: ${minimalPointerWhite};

        .iconWrapper {
          .icon {
            transform: rotateX(0deg);
            opacity: 0;
          }
          .arrow {
            transform: rotateX(0deg) translateX(0px);
            opacity: 1;
          }
        }
      }
    }
  }

  .rightContainer {
    position: relative;

    .tabContents {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      padding: ${theme.spacing(2)};
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(4)};
      }

      .imgWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      .tabImg {
        height: 250px;
        transform-origin: center center;
        opacity: 0;
        transform: scale(0.8) rotate(12deg) translateZ(1px);
        will-change: opacity, transform;
        transition: transform 300ms ${theme.transitions.easing.outExpo},
          opacity 200ms ${theme.transitions.easing.inOutSine};
      }

      .tabCopy {
        opacity: 0;
        transform: translate3d(0, 50px, 1px);
        will-change: opacity, transform;
        transition: transform 300ms ${theme.transitions.easing.outExpo},
          opacity 200ms ${theme.transitions.easing.inOutSine};
      }

      &.active {
        opacity: 1;
        pointer-events: auto;

        .tabImg {
          opacity: 1;
          transform: scale(1) rotate(0deg) translateZ(1px);
        }

        .tabCopy {
          opacity: 1;
          transform: translate3d(0, 0px, 1px);
        }
      }
    }
  }
`;

export default styles;
