import { css, keyframes } from '@emotion/react';
import {
  minimalInitiallyHidden,
  minimalRevealed,
  minimalCursor,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${theme.palette.background.default};
  padding: ${theme.spacing(0)};
  overflow: hidden;

  .sticky-wrapper {
    position: relative;
    padding: ${theme.spacing(2, 0, 12, 0)};
  }

  .top {
    padding: ${theme.spacing(0, 2)};
    margin-bottom: ${theme.spacing(4)};

    .header {
      margin: ${theme.spacing(10, 0, 6, 0)};
    }

    .intro {
      ${minimalInitiallyHidden}
    }
  }

  .salary-button {
    background-color: #dad9d5;
    color: #a1a19d;
    :hover {
      background-color: #dad9d5;
      color: #a1a19d;
      cursor: ${minimalCursor};
    }
  }

  .toolsScroller {
    display: flex;
    overflow: auto;
    ${minimalInitiallyHidden}
    transform: translate3d(100px, 0px, 1px);
    padding: ${theme.spacing(0, 2)};
    overflow-y: overlay;

    ::-webkit-scrollbar {
      width: 15px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      margin: ${theme.spacing(0, 2)};
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.palette.supply.darkGray};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.palette.supply.darkGray};
    }

    .tool {
      display: flex;
      position: sticky;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 300px;
      background-color: ${theme.palette.supply.cream.main};
      border: 1px solid ${theme.palette.supply.darkGray};
      padding: ${theme.spacing(5, 3)};
      flex-shrink: 0;
      aspect-ratio: 1/1.5;
      border-right: none;
      will-change: transform;

      &:last-child {
        border-right: 1px solid ${theme.palette.supply.darkGray};
      }

      ${theme.breakpoints.up('sm')} {
        aspect-ratio: 1/1.4;
        padding: ${theme.spacing(6, 4)};
        width: 340px;
      }
    }
  }
`;

export const activeStyles = (theme) => css`
  .top {
    .intro {
      ${minimalRevealed}
    }
  }

  .toolsScroller {
    ${minimalRevealed}
    transition-delay: 500ms;
  }
`;

export const changingStyles = (theme) => css`
  .top {
    .intro {
      transition-delay: 700ms;
    }
  }

  .toolsScroller {
    .tool {
      &:nth-of-type(1) {
        transition-delay: 800ms;
      }
      &:nth-of-type(2) {
        transition-delay: 900ms;
      }
      &:nth-of-type(3) {
        transition-delay: 1000ms;
      }
      &:nth-of-type(4) {
        transition-delay: 1100ms;
      }
    }
  }
`;

export default styles;
