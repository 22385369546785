import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, Button } from '@material-ui/core';
import styles from './part2.styles';
import useScrollEnter from '../../hooks/use-scroll-enter';
import Arrow from './arrow';
import Shapes from './shapes';
import OrgsSupportedDialog from './orgsSupported';

const WhyUsPart2 = ({ content }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  const diversityRef = useRef(null);

  const [showArrow, setShowArrow] = useState(false);

  const [showOrgsModal, setShowOrgsModal] = useState(false);

  const handleOpen = () => {
    setShowOrgsModal(true);
  };

  const handleClose = () => {
    setShowOrgsModal(false);
  };

  useScrollEnter({
    ref: diversityRef,
    id: 'arrowScroll',
    onEnter: () => {
      setShowArrow(true);
    },
    onLeaveBack: () => {
      setShowArrow(false);
    },
  });

  return (
    <>
      <Grid item container xs={12} css={styles}>
        <Grid item md={7} xs={12}>
          <Typography variant="caption" className="caption">
            {content.title} — 002
          </Typography>
          <Typography variant={'h2'}>{content.leftSideTitle}</Typography>
          <Typography variant="body2">
            {content.leftSideCopy.leftSideCopy}
          </Typography>
        </Grid>
        <Grid item md={1} xs={12}></Grid>
        <Grid item md={4} xs={12} className="imageContainer">
          <Shapes />
        </Grid>
        <Grid
          item
          xs={10}
          sm={12}
          className="pushingDiversity"
          ref={diversityRef}
        >
          <Typography variant={'h2'}>
            <Arrow active={showArrow} />
            {content.rightSideTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={showMdAndUp ? 'row' : 'column-reverse'}>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpen}
              >
                {/* Organizations we support */}
                {/* {content.orgsModalTitle} */}
                {content.orgsModalLinkText}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="body2">
                {content.rightSideCopy.rightSideCopy}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showOrgsModal && (
        <OrgsSupportedDialog
          onClose={handleClose}
          showOrgsModal={showOrgsModal}
        />
      )}
    </>
  );
};

export default WhyUsPart2;
