import React, { useRef, useState, useCallback } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import styles, { activeStyles, changingStyles } from './whyUs.styles';
import ScrollContainer from '../../components/scroll-container';
import SlideInTitle from '../slidein-title';
import WhyUsPart2 from './part2';
import WhyUsPart1 from './part1';
import { useLayoutContext } from '../../contexts/layoutContext';

const WhyUs = ({ whyUsSection, givingBackSection }) => {
  const [active, setActive] = useState(false);
  const { changing } = useLayoutContext();

  return (
    <ScrollContainer
      id={3}
      styles={[
        styles,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
      onEnter={() => {
        setActive(true);
      }}
      onLeaveBack={() => {
        setActive(false);
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <div className="head">
          <SlideInTitle id={3} active={active} inline={true}>
            {whyUsSection.title.split(' ').map((part, index) => (
              <Typography key={`why-us-title-line-${index}`} variant="h1">
                {part}
              </Typography>
            ))}
          </SlideInTitle>
        </div>

        <Grid container direction="column" className="mainContent">
          <WhyUsPart1 content={whyUsSection} />
          <WhyUsPart2
            content={{ title: whyUsSection.title, ...givingBackSection }}
          />
        </Grid>
      </Container>
    </ScrollContainer>
  );
};

export default WhyUs;
