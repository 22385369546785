import { useEffect, useCallback, useState, useRef, forwardRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ChatButton from './chatButton';
import ArrowAnimation from './arrowAnimation';
import { useLayoutContext } from '../../contexts/layoutContext';

import styles from './chatComponent.styles';

gsap.registerPlugin(ScrollTrigger);
const SCROLL_TRIGGER_ID = `chatAnimation`;

const ChatComponent = forwardRef((props, ref) => {
  const chatboxRef = useRef(null);
  // const arrowRef = useRef(null);
  const timeline = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);

  const { firstAnimateIn } = useLayoutContext();

  const handleOpen = () => {
    setShowChatModal(true);
  };

  const handleClose = () => {
    setShowChatModal(false);
  };

  useEffect(() => {
    return () => {
      if (!timeline.current) return;
      timeline.current.kill();
      const scrollTriggerRef = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (scrollTriggerRef) {
        scrollTriggerRef.kill(true);
      }
    };
  }, []);

  useEffect(() => {
    if (!firstAnimateIn) {
      animateIn();
    }
  }, [firstAnimateIn]);

  const animateIn = useCallback(() => {
    //set up the timeline animations using scroll trigger
    timeline.current = gsap.timeline(
      {
        scrollTrigger: {
          id: SCROLL_TRIGGER_ID,
          start: 'top 50%',
          end: 'bottom bottom',
          trigger: ref.current,
        },
      },
      [ref]
    );

    //animate the chatbox
    timeline.current.to(chatboxRef.current, {
      duration: 0.5,
      delay: firstAnimateIn ? 0 : 1,
      y: 0,
      z: 1,
      ease: 'back',
    });
  }, []);

  useCallback(() => {
    if (!showChatModal) {
      ScrollTrigger.addEventListener('scrollStart', () => setIsScrolling(true));
      ScrollTrigger.addEventListener('scrollEnd', () => setIsScrolling(false));
      return () => ScrollTrigger.kill();
    }
  }, []);

  return (
    <div css={styles}>
      <div
        className={
          isScrolling ? 'chatbox-container mobile-hide' : 'chatbox-container'
        }
        ref={chatboxRef}
      >
        <div className="squiggly-arrow">
          <ArrowAnimation />
        </div>
        <div className="chatbox">
          <ChatButton
            showChatModal={showChatModal}
            onClose={handleClose}
            onOpen={handleOpen}
          />
        </div>
      </div>
    </div>
  );
});

export default ChatComponent;
