import {
  messageDataStyles,
  agentMessageDataStyles,
  introMessageStyles,
  introMessageDataStyles,
  newChatMsgStyles,
  lastChatMsgStyles,
} from './chat.styles';
import Emoji from 'react-emoji-render';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';

export const ChatMessages = ({ messages = [], customerId, scrollToRef }) => {
  return (
    <div className="msg-container">
      {messages.map((message, idx) => {
        const key = message.id || idx;
        const isMe =
          message.customer_id === customerId ||
          (!!message.sent_at && message.type === 'customer');

        const isAgent =
          (message.source === 'slack' || message.type === 'bot') && !isMe;

        return (
          <ChatMessage
            key={key}
            isCustomer={!isAgent}
            message={message}
            isLast={idx === messages.length - 1}
          />
        );
      })}

      <div key="scroll-el" ref={scrollToRef} />
    </div>
  );
};

export const ChatMessage = ({ isCustomer, message, isLast }) => {
  const { type } = message;
  return (
    <div
      css={[
        newChatMsgStyles,
        isLast ? lastChatMsgStyles : null,
        type === 'bot' ? introMessageStyles : null,
      ]}
    >
      <MessageData isAgent={!isCustomer} message={message} />
      <Typography variant="body1">
        <Emoji text={message.body} />
      </Typography>
    </div>
  );
};

export const MessageData = ({ isAgent, message }) => {
  const { sent_at, created_at, type } = message;

  const sentAtAgent = sent_at ? format(new Date(sent_at), 'Pp') : null;
  const createdAtAgent = created_at ? format(new Date(created_at), 'Pp') : null;

  return (
    <div
      css={[
        messageDataStyles,
        isAgent ? agentMessageDataStyles : null,
        type === 'bot' ? introMessageDataStyles : null,
      ]}
    >
      {type !== 'bot' ? (
        <div className="avatar">
          {/* {!isAgent && <img src="/images/minimal-chat-avatar.png" />} */}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="border" x="0.5" y="0.5" width="19" height="19" />
            <rect x="3" y="13" width="14" height="13" rx="3" />
            <circle cx="10" cy="8" r="3" />
          </svg>
        </div>
      ) : null}
      {!!(sentAtAgent || createdAtAgent) && (
        <Typography variant="body2" className="label">
          {sentAtAgent || createdAtAgent}
        </Typography>
      )}
    </div>
  );
};
