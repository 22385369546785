import { css, keyframes } from '@emotion/react';
import {
  minimalInitiallyHidden,
  minimalRevealed,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.background.default};
  padding: ${theme.spacing(2, 2, 12, 2)};
  min-height: 100vh;

  ${theme.breakpoints.down('md')} {
  }
  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(10, 4, 16, 4)};
  }

  .head {
    ${theme.breakpoints.down('md')} {
      margin: ${theme.spacing(10, 0, 8, 0)};
    }
    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacing(10)};
    }
  }

  .mainContent {
    ${minimalInitiallyHidden}
  }
`;

export const activeStyles = (theme) => css`
  .mainContent {
    ${minimalRevealed}
  }
`;

export const changingStyles = (theme) => css`
  .mainContent {
    transition-delay: 700ms;
  }
`;

export default styles;
