import { css } from '@emotion/react';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {
  arrowDownOutIn,
  arrowUpOutIn,
  arrowUpOut,
  arrowUpIn,
} from '../../components/shared/animations.styles';

export const newStyles = (theme) => css`
  // button-block-wrapper

  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  height: 50px;
  width: 100vw;
  transition: width 500ms ${theme.transitions.easing.outCubic};
  // z-index: 40;
  z-index: 100;
  overflow: hidden;
  transform: translateZ(1px);
  will-change: width;

  ${theme.breakpoints.up('sm')} {
    height: 75px;
    width: ${(8 / 12) * 100}vw;
  }
  ${theme.breakpoints.up('md')} {
    height: 90px;
  }

  .buttons-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .buttons {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      overflow: hidden;
      ${theme.breakpoints.up('sm')} {
        width: ${(8 / 12) * 100}vw;
      }
    }
  }

  .button {
    display: flex;
    width: 50%;
    height: 100%;
    transition: width 300ms ${theme.transitions.easing.outCubic};
    border-radius: 0;
    justify-content: space-between;
    transform: translate(0, 100%);
    opacity: 0;

    .buttonCopy {
      opacity: 1;
      transition: opacity 200ms ${theme.transitions.easing.outCubic};
    }

    &.learn-more {
      ${theme.breakpoints.up('sm')} {
        width: 45%;
        &:hover {
          width: 50%;
          .arrow-icon {
            animation: ${arrowDownOutIn} 0.5s 1 forwards;
            animation-delay: 200ms;
          }
        }
      }
    }

    > button {
      border-radius: 0;
    }

    .scroll-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: ${theme.spacing(1.5, 2)};
      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(1.5, 4)};
      }
    }

    &.talk {
      > button {
        position: absolute;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        background-color: ${theme.palette.supply.salmon.main};
        &:hover {
          background-color: ${lighten(theme.palette.supply.salmon.main, 0.1)};
        }

        .chat-icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0;
          padding: ${theme.spacing(1)};
          ${theme.breakpoints.up('sm')} {
            padding: ${theme.spacing(2.5)};
          }
        }
      }

      // ← ↑ → ↓

      ${theme.breakpoints.up('sm')} {
        width: 55%;
        > button {
          .chat-icon {
            width: 75px;
            height: 75px;
          }
        }
        &:hover {
          width: 80%;
          .arrow-icon {
            animation: ${arrowUpOutIn} 0.5s 1 forwards;
            animation-delay: 300ms;
          }
        }
      }
      ${theme.breakpoints.up('md')} {
        &:hover {
          width: 60%;
        }
      }
      ${theme.breakpoints.up('md')} {
        > button {
          .chat-icon {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }

  .chatbox-wrapper {
    position: absolute;
    right: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // background-color: ${theme.palette.supply.salmon.main};
    bottom: 0;
    transform: translate3d(0, 100%, 0);
    z-index: 100;

    ${theme.breakpoints.up('sm')} {
      width: ${(8 / 12) * 66}vw;
    }
    ${theme.breakpoints.up('md')} {
      width: ${(8 / 12) * 57}vw;
    }

    .header {
      position: relative;
      display: flex;
      border-radius: 0;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      overflow: hidden;
      flex-shrink: 0;

      ${theme.breakpoints.up('sm')} {
        height: 75px;
      }

      ${theme.breakpoints.up('md')} {
        height: 90px;
      }

      &:hover {
        .arrow-icon {
          animation: ${arrowDownOutIn} 0.5s 1 forwards;
          animation-delay: 300ms;
        }
      }

      .top-border {
        position: absolute;
        display: flex;
        width: calc(100% - ${theme.spacing(4)});
        left: ${theme.spacing(2)};
        height: 1px;
        top: 49px;
        background-color: ${theme.palette.supply.darkGray};

        ${theme.breakpoints.up('sm')} {
          top: 89px;
          width: calc(100% - ${theme.spacing(8)});
          left: ${theme.spacing(4)};
        }
      }

      > button {
        position: absolute;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        background-color: ${theme.palette.supply.salmon.main};
        border-radius: 0;
        &:hover {
          background-color: ${lighten(theme.palette.supply.salmon.main, 0.1)};
        }
      }
    }

    .chatbox-container {
      position: absolute;
      top: 50px;
      height: calc(100% - 50px);
      width: 100%;
      background-color: ${theme.palette.supply.salmon.main};

      ${theme.breakpoints.up('sm')} {
        top: 75px;
        height: calc(100% - 75px);
      }

      ${theme.breakpoints.up('md')} {
        top: 90px;
        height: calc(100% - 90px);
      }
    }
  }
`;

export const newCollapsedStyles = (theme) => css`
  transition-delay: 100ms;

  width: 50px;

  ${theme.breakpoints.up('sm')} {
    width: 75px;
  }
  ${theme.breakpoints.up('md')} {
    width: 90px;
  }

  .button {
    .buttonCopy {
      opacity: 0;
    }

    &.talk {
      .arrow-icon {
        animation: ${arrowUpOut} 0.25s 1 forwards;
        animation-delay: 600ms;
      }
      > button {
        .chat-icon {
          opacity: 1;
          transition-delay: 800ms;
        }
      }

      &:hover {
        .arrow-icon {
          animation: ${arrowUpOut} 0.25s 1 forwards;
        }
      }
    }
  }
`;

export const newChatStyles = (theme) => css`
  .buttons-wrapper {
    .buttons {
      .button {
        ${theme.breakpoints.up('sm')} {
          &.learn-more {
            width: 34%;
            &:hover {
              width: 34%;
            }
          }

          &.talk {
            width: 66%;
            &:hover {
              width: 66%;
            }
          }
        }
        ${theme.breakpoints.up('md')} {
          &.learn-more {
            width: 43%;
            &:hover {
              width: 43%;
            }
          }

          &.talk {
            width: 57%;
            &:hover {
              width: 57%;
            }
          }
        }
      }
    }
  }
`;
