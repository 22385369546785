import React, { useRef, useEffect, useState, useContext } from 'react';
import { graphql } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Creative from '../home/creative';
import Professional from '../home/professional';
import { SEO } from '../components/seo';
import { LayoutProvider, useLayoutContext } from '../contexts/layoutContext';
import { ScrollProvider } from '../contexts/scrollContext';
import { ChatProvider } from '../contexts/chatContext';
import Layout from '../components/layout/layout';
import TopBar from '../components/layout/topBar';
import playfulTheme from '../themes/playfulTheme';
import minimalTheme from '../themes/minimalTheme';
import useIsClient from '../hooks/use-is-client';

export const PAGE_DATA_QUERY = graphql`
  query pageDataQuery {
    contentfulTicker {
      id
      tickerOne
      tickerTwo {
        raw
      }
      tickerThree {
        raw
      }
    }
    contentfulHeroSection {
      introCopy {
        raw
      }
      introPart1 {
        introPart1
      }
      introPart2 {
        introPart2
      }
      headline
      withA
      endOfHeadline
      navTitle
      cta1Copy
      cta2Copy
      cta2CopyMobile
      titleTopParts
      titleChangingParts
      titleBottomParts
    }
    contentfulWhoWeWorkWithSection {
      whoWeWorkWithTitle
      titleMinimalTheme
      talentclientToggle
      talentclientToggleMinimalTheme
      talentDescription {
        talentDescription
      }
      clientDescription {
        clientDescription
      }
      talentItem {
        id
        jobCategory
        jobTitle
      }
      clientItem {
        id
        specialty
        specialtyItem
      }
      questionTooltipCopy {
        questionTooltipCopy
      }
    }
    contentfulServicesSection {
      title
      serviceItem {
        title
        id
        iconImage {
          file {
            url
          }
        }
        iconImageMinimalTheme {
          file {
            url
          }
        }
        description {
          description
        }
        link
        linkText
      }
      description {
        description
      }
    }
    contentfulWhyUsSection {
      title
      titleCardColumns
      statisticItem {
        value
        measurement
        showInMinimalTheme
        statistic {
          id
          statistic
        }
        image {
          title
          file {
            url
          }
        }
      }
      headerMinimalTheme
      description {
        childMarkdownRemark {
          html
        }
      }
      descriptionTwo {
        descriptionTwo
      }
      roldxMobileImage {
        file {
          url
        }
      }
      roldxVideoDesktop {
        file {
          url
        }
      }
      buttonCopy
      link
    }
    contentfulGivingBackSection {
      rightSideTitle
      leftSideTitle
      leftSideCopy {
        leftSideCopy
      }
      rightSideCopy {
        rightSideCopy
      }
      orgsModalLinkText
      orgsModalTitle
      badges {
        id
        file {
          url
        }
      }
    }
    # contentfulNetworkSection {
    #   title
    #   description {
    #     description
    #   }
    #   networkAttributeItem {
    #     id
    #     title
    #     description {
    #       description
    #     }
    #     backgroundRectangle {
    #       file {
    #         url
    #       }
    #     }
    #   }
    # }
    contentfulOurNetworkModal {
      title
      titleTopMinimalTheme
      titleBottomMinimalTheme
      bodyCopy {
        bodyCopy
      }
      bodyCopyPart2 {
        bodyCopyPart2
      }
    }
    contentfulMeetTheTeam {
      title
      titleMinimalTheme
      ctaCopy
      teamMember {
        id
        name
        bio {
          bio
        }
        linkedinLink
        linkedinIcon {
          file {
            url
          }
        }
        profileImage {
          file {
            url
          }
        }
      }
      hiringBottom {
        file {
          url
        }
      }
    }
    contentfulTools {
      title
      titleMinimalTheme
      description {
        description
      }
      toolLinkItem {
        id
        title
        description {
          description
        }
        link
        linkText
        linkActive
        icon {
          file {
            url
          }
        }
      }
    }

    contentfulFooter {
      logo {
        file {
          url
        }
      }
      logoCaption
      ctaCopy
      showCta
      footerLinks {
        id
        linkText
        linkUrl
      }
      dialogTitle
      dialogCopy {
        dialogCopy
      }
      dialogCtaCopy
      dialogCtaLink
    }
  }
`;

const HomeContent = ({ matches, data }) => {
  const { isMinimal, firstLoadIn } = useLayoutContext();
  const { isClient, key } = useIsClient();

  if (!isClient) return null;
  return (
    <Layout
      key={key}
      isMinimal={isMinimal}
      theme={isMinimal ? minimalTheme : playfulTheme}
    >
      <div
        style={{
          opacity: firstLoadIn ? 0 : 1,
        }}
      >
        <TopBar />

        {isMinimal ? (
          <Professional matches={matches} data={data} />
        ) : (
          <Creative matches={matches} data={data} />
        )}
      </div>
    </Layout>
  );
};

const Home = ({ data }) => {
  const theme = useTheme();
  const matches = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  return (
    <>
      <LayoutProvider>
        <ScrollProvider>
          <ChatProvider>
            <SEO />
            <HomeContent matches={matches} data={data} />
          </ChatProvider>
        </ScrollProvider>
      </LayoutProvider>
    </>
  );
};

export default Home;

// export const Head = () => <SEO />;
