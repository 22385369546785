import React from 'react';
import { Dialog, Zoom, Box, Typography, Grid, Button } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { closeChatBtnStyles } from '../chat/chatButton.styles';
import styles from './orgsSupported.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const OrgsSupportDialog = ({ showOrgsModal, onClose }) => {
  const { contentfulGivingBackSection } = useStaticQuery(graphql`
    query ModalContentQuery {
      contentfulGivingBackSection {
        orgsModalTitle
        orgsModalCopy {
          orgsModalCopy
        }
        orgsModalLogos {
          description
          file {
            url
          }
        }
      }
    }
  `);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom in={showOrgsModal} ref={ref} {...props} />;
  });

  const theme = useTheme();
  const showMed = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  return (
    <Dialog
      BackdropProps={{
        style: { backgroundColor: 'rgba(255, 255, 255, 0.85)' },
      }}
      open={showOrgsModal}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      css={styles}
    >
      <Button
        css={closeChatBtnStyles}
        onClick={onClose}
        className="close-modal"
        color="primary"
        size="small"
        variant="text"
        endIcon={<img src="/images/closechaticon.svg" />}
      >
        {showMed && <Typography variant="body1">Close</Typography>}
      </Button>
      <Grid
        container
        className="inner-top"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className="orgs-copy">
          <Typography variant="body1">
            {contentfulGivingBackSection.orgsModalCopy.orgsModalCopy}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        className="grid-img-grid"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {contentfulGivingBackSection.orgsModalLogos.map((image) => (
          <Grid
            item
            xs={12}
            sm={4}
            mt={3}
            key={image.file.url}
            className="grid-img-wrap"
          >
            <a href={image.description} target="_blank">
              <img src={image.file.url} className="grid-img" />
            </a>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          marginTop: '15px',
        }}
      >
        <Grid item xs={12} sm={2}>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={12} sm={2} style={{ marginBottom: '4.5rem' }}>
          <Typography variant="body1">Updating more soon</Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default OrgsSupportDialog;
