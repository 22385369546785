import { css } from '@emotion/react';

const styles = (theme) => css`
  display: flex;
  background-color: ${theme.palette.background.default};
  min-height: calc(100vh - 30px);
  // min-height: calc(100vh - env(safe-area-inset-bottom) - 30px);

  ${theme.breakpoints.up('sm')} {
    min-height: 0;
    height: calc(100vh - 30px);
  }
  ${theme.breakpoints.up('md')} {
    min-height: 0;
    height: calc(100vh - ${theme.ui.topBar.height}px);
  }

  .grid {
    padding: 0;
    flex-wrap: nowrap;
    height: 100%;
  }

  .left-col {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    ${theme.breakpoints.up('sm')} {
      height: 100%;
      border-right: 1px solid ${theme.palette.text.primary};
      padding-bottom: 0px;
    }

    .top,
    .bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .top {
      height: 100%;
      padding: ${theme.spacing(0, 2, 4)};

      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(3)};
      }
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(4)};
      }
      ${theme.breakpoints.up('lg')} {
        padding: ${theme.spacing(4, 12, 4, 4)};
      }
    }
    .bottom {
      padding: ${theme.spacing(6, 2)};

      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(3)};
        height: 440px;
        flex-shrink: 0;
      }
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(4)};
        height: 380px;
        flex-shrink: 0;
      }
      ${theme.breakpoints.up('lg')} {
        padding: ${theme.spacing(4, 12, 4, 4)};
      }

      .hero-intro {
        filter: grayscale(1);
      }
    }
    .bottom-border {
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 100%;
      border-top: 1px solid ${theme.palette.text.primary};
    }
  }

  .right-col {
    display: flex;
    flex-direction: column;
    height: 100%;
    // border-bottom: 1px solid ${theme.palette.text.primary};
    padding-bottom: 0px;

    ${theme.breakpoints.up('sm')} {
      border-bottom: none;
      padding-bottom: 90px;
    }

    .top {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: ${theme.spacing(4, 0, 2, 2)};

      ${theme.breakpoints.up('sm')} {
        align-items: flex-end;
        flex-direction: row;
        padding: ${theme.spacing(3, 3, 6, 3)};
      }
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(4, 4, 8, 4)};
      }
    }
  }

  /*
  ** LOGO
  */

  .logo {
    width: ${150 * 0.75}px;
    height: ${70 * 0.75}px;
    background-image: url(/images/supply-footer-logo.svg);
    background-size: ${95 * 0.75}px ${48 * 0.75}px;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    border: 1px solid ${theme.palette.text.primary};
    border-radius: ${35 * 0.75}px;

    ${theme.breakpoints.up('sm')} {
      width: 150px;
      height: 70px;
      background-size: 95px 48px;
      border-radius: 35px;
    }
  }

  /*
  ** HERO TITLE
  */

  .word-container {
    position: relative;
    display: flex;
    height: ${40 * theme.ui.lineHeight.m}px;
    line-height: ${theme.ui.lineHeight.m};

    .word-inner-wrapper {
      position: relative;
      display: flex;
      flex-shrink: 0;
      white-space: nowrap;
      width: 100%;
      margin-top: -5px;
      overflow: hidden;
      transform: translate3d(0, 0, 0);

      height: ${40 * theme.ui.lineHeight.m + 10}px;

      > span {
        height: 100%;
        flex-shrink: 0;
      }
    }

    &.scrolling-text {
      // height: 40px;
      height: ${40 * theme.ui.lineHeight.l}px;
      // line-height: 1.07;
      // line-height: 1;
      .word-inner-wrapper {
        // height: 50px;
        height: ${40 * theme.ui.lineHeight.l + 10}px;
        h1,
        h2,
        h3,
        h4 {
          line-height: ${theme.ui.lineHeight.l};
        }
      }
    }

    ${theme.breakpoints.up('sm')} {
      margin: 0;
      height: ${50 * theme.ui.lineHeight.m}px;
      .word-inner-wrapper {
        height: ${50 * theme.ui.lineHeight.m + 20}px;
        margin-top: -10px;
      }

      &.scrolling-text {
        height: ${50 * theme.ui.lineHeight.l}px;
        .word-inner-wrapper {
          height: ${50 * theme.ui.lineHeight.l + 20}px;
        }
      }
    }
    ${theme.breakpoints.up('md')} {
      margin: 0;
      height: ${70 * theme.ui.lineHeight.m}px;
      .word-inner-wrapper {
        height: ${70 * theme.ui.lineHeight.m + 20}px;
        margin-top: -10px;
      }
      &.scrolling-text {
        height: ${70 * theme.ui.lineHeight.l}px;
        .word-inner-wrapper {
          height: ${70 * theme.ui.lineHeight.l + 20}px;
        }
      }
    }
    ${theme.breakpoints.up('lg')} {
      height: ${84 * theme.ui.lineHeight.m}px;
      .word-inner-wrapper {
        height: ${84 * theme.ui.lineHeight.m + 16}px;
        margin-top: -8px;
      }
      &.scrolling-text {
        height: ${84 * theme.ui.lineHeight.l}px;
        .word-inner-wrapper {
          height: ${84 * theme.ui.lineHeight.l + 16}px;
        }
      }
    }

    .fading-text {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      height: 50px;
      padding: 5px 0;

      transform: translateZ(1px);
      will-change: transform;

      ${theme.breakpoints.up('sm')} {
        padding: 10px 0;
        height: 70px;
      }
      ${theme.breakpoints.up('md')} {
        padding: 10px 0;
        height: 90px;
      }
      ${theme.breakpoints.up('lg')} {
        padding: 8px 0;
        height: 100px;
        // padding: 10px 0;
        // height: 130px;
      }

      h2,
      h3 {
        font-weight: bold;
      }
    }
  }

  .rich-text-section {
    position: relative;
    width: 100%;
    z-index: 2;
    margin-top: ${theme.spacing(8)};

    span {
      opacity: 0;
      display: inline-block;
    }
  }
`;

export const iosStyles = (theme) => css`
  min-height: calc(100vh - 110px);

  ${theme.breakpoints.up('sm')} {
    height: calc(100vh - 110px);
  }
  ${theme.breakpoints.up('md')} {
    height: calc(100vh - 110px);
  }
`;

export default styles;
