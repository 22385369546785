import React, {
  useEffect,
  useRef,
  useState,
  createRef,
  useCallback,
  forwardRef,
} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Typography, Button, useMediaQuery } from '@material-ui/core';
import {
  newStyles,
  newCollapsedStyles,
  newChatStyles,
} from './actionButtons.styles';
import { Link } from 'react-scroll';
import { useTheme } from '@material-ui/core/styles';
import { useLayoutContext } from '../../contexts/layoutContext';
import { useChatContext } from '../../contexts/chatContext';
import useOnClickOutside from '../../hooks/use-on-click-outside';
import ChatContainer from '../chatBox/chatContainer';
import ChatIcon from './chatIcon';
import useWindowSize from '../../hooks/use-window-size';
// import { useSpring, animated } from 'react-spring';

gsap.registerPlugin(ScrollTrigger);
const SCROLL_TRIGGER_ID = `chatAnimation`;

const actionButtons = forwardRef(({ content }, ref) => {
  const { firstAnimateIn } = useLayoutContext();
  const { showChat, setShowChat } = useChatContext();
  const theme = useTheme();
  const windowSize = useWindowSize();

  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );
  const showSmAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm)
  );

  const inTimeline = useRef(null);
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);
  const chatWrapper = useRef(null);
  const buttonsRefs = useRef([...Array(2)].map(() => createRef()));
  const [expandedButtons, setExpandedButtons] = useState(true);
  // const [showChat, setShowChat] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [height, setHeight] = useState(false);
  const [animtedIn, setAnimatedIn] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);

  useOnClickOutside(chatWrapper, () => {
    // if (showChat) hideChatAnim();
    if (showChat) setShowChat(false);
  });

  useEffect(() => {
    if (showChat) {
      document.documentElement.style.overflow = 'hidden';
    }
    return () => (document.documentElement.style.overflow = 'unset');
  }, [showChat]);

  const updateHeight = useCallback(() => {
    const chatHeight =
      window.innerHeight - (expandedButtons ? 44 - scrollTop : 0);
    setHeight(showMdAndUp ? chatHeight : window.innerHeight);
  }, [expandedButtons, scrollTop, windowSize.height]);

  useEffect(() => {
    updateHeight();
  }, [expandedButtons]);

  useEffect(() => {
    let _scrollTop = document.documentElement.scrollTop;
    setExpandedButtons(_scrollTop < 20);

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    gsap.set(chatWrapper.current, {
      height: `${height}px`,
    });

    // let topPos = window.innerHeight - (expandedButtons ? 44 : 0);
    if (!showChat) gsap.set(chatWrapper.current, { y: height });
  }, [height]);

  useEffect(() => {
    updateHeight();

    return () => {
      if (inTimeline.current) inTimeline.current.kill();
      const scrollTriggerRef = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (scrollTriggerRef) {
        scrollTriggerRef.kill(true);
      }
    };
  }, []);

  const onScroll = useCallback(
    (e) => {
      let _scrollTop = document.documentElement.scrollTop;
      if (_scrollTop < 20) setScrollTop(_scrollTop);
      setExpandedButtons(_scrollTop < 20);
    },
    [expandedButtons]
  );

  useEffect(() => {
    if (!firstAnimateIn) {
      animateIn();
    }
  }, [firstAnimateIn]);

  const animateIn = useCallback(() => {
    //set up the timeline animations using scroll trigger
    inTimeline.current = gsap.timeline(
      {
        scrollTrigger: {
          id: SCROLL_TRIGGER_ID,
          start: 'top 50%',
          end: 'bottom bottom',
          trigger: ref.current,
        },
      },
      [ref]
    );

    inTimeline.current.to([...buttonsRefs.current.map((ref) => ref.current)], {
      stagger: {
        amount: 0.1,
      },
      delay: firstAnimateIn ? 0.5 : 1.5,
      duration: 0.5,
      y: 0,
      opacity: 1,
      ease: 'expo.out',
      onComplete: () => {
        setAnimatedIn(true);
      },
    });
  }, []);

  const showChatAnim = useCallback(() => {
    const buttonHeight = showMdAndUp ? 90 : showSmAndUp ? 75 : 50;
    const startPos = height - (expandedButtons ? buttonHeight : 0);

    // setShowChat(true);
    setChatActive(true);

    gsap
      .timeline()
      .delay(0.15)
      .set(containerRef.current, { overflow: 'visible' })
      .set(chatWrapper.current, {
        // height: `${window.innerHeight}px`,
        height: `${height}px`,
      })
      .set(chatWrapper.current, { y: startPos })
      .to(chatWrapper.current, {
        duration: 0.35,
        y: 0,
        ease: 'cubic.out',
      });
  }, [showMdAndUp, expandedButtons, height]);

  const hideChatAnim = useCallback(
    (forceHeight) => {
      const buttonHeight = showMdAndUp ? 90 : showSmAndUp ? 75 : 50;

      gsap.to(chatWrapper.current, {
        duration: 0.35,
        // y:
        //   window.innerHeight -
        //   (expandedButtons && !forceHeight ? buttonHeight + 44 - scrollTop : 0),
        // y: height,
        y:
          height -
          (showMdAndUp && expandedButtons && !forceHeight ? buttonHeight : 0),
        ease: 'cubic.out',
        onComplete: () => {
          gsap.set(containerRef.current, { overflow: 'hidden' });
          gsap.set(chatWrapper.current, { y: window.innerHeight });
          // setShowChat(false);
          setChatActive(false);
        },
      });
    },
    [expandedButtons, showMdAndUp, height]
  );

  useEffect(() => {
    updateHeight();
  }, [scrollTop]);

  const toggleChat = () => {
    // if (showChat) {
    //   hideChatAnim();
    // } else {
    //   showChatAnim();
    // }
    setShowChat(!showChat);
  };

  useEffect(() => {
    if (showChat) {
      showChatAnim();
    } else {
      hideChatAnim();
    }
  }, [showChat]);

  // const [props, set] = useSpring(() => ({
  //   width: '0px',
  //   config: { duration: 100 },
  // }));

  // useEffect(() => {
  //   if (expandedButtons) {
  //     set({ width: `${(8 / 12) * window.innerWidth}px` });
  //   } else {
  //     set({ width: '90px' });
  //   }
  // }, [expandedButtons]);

  return (
    <div
      className="button-block-wrapper"
      css={[
        newStyles,
        expandedButtons ? null : newCollapsedStyles,
        // showChat ? newChatStyles : null,
        chatActive ? newChatStyles : null,
      ]}
      // style={props}
      ref={containerRef}
    >
      <div className="buttons-wrapper" ref={wrapperRef}>
        <div className="buttons">
          {/* <div className="button link" ref={buttonsRefs.current[0]}></div> */}

          <Button
            ref={buttonsRefs.current[0]}
            variant="contained"
            color="default"
            className={`button learn-more`}
          >
            <Link
              to={`scroll-section-1`}
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="scroll-link"
            >
              <Typography variant="button">{content.cta1Copy}</Typography>
              <span className="arrow-icon">↓</span>
            </Link>
          </Button>
          <div className="button talk" ref={buttonsRefs.current[1]}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                toggleChat();
              }}
            >
              <Typography variant="button" className="buttonCopy">
                {showMdAndUp ? content.cta2Copy : content.cta2CopyMobile}
              </Typography>
              <span className="arrow-icon">↑</span>
              <span className="chat-icon">
                <ChatIcon active={!expandedButtons} />
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div className="chatbox-wrapper" ref={chatWrapper}>
        {animtedIn && (
          <>
            <div className="header">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  toggleChat();
                }}
              >
                <Typography variant="button" className="buttonCopy">
                  {showMdAndUp ? content.cta2Copy : content.cta2CopyMobile}
                </Typography>
                <span className="arrow-icon">↓</span>
              </Button>
              <span className="top-border"></span>
            </div>

            <div className="chatbox-container">
              <ChatContainer
                showChatModal={showChat}
                // onCloseChat={hideChatAnim}
                onCloseChat={() => {
                  setShowChat(false);
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default actionButtons;
