import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import GlobeBG from './globeBG';
import styles from './reach.styles';
import ScrollContainer from '../../components/scroll-container';

const MeetTheTeamSection = ({ content }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md)
  );

  const [active, setActive] = useState(false);
  const [progress, setProgress] = useState(0);
  const requestRef = useRef();
  const previousTimeRef = useRef(0);
  const progressTarget = useRef(0);

  const onEnter = useCallback(() => {
    setActive(true);
  }, []);

  const onLeave = useCallback(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    if (active) {
      requestRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(requestRef.current);
    }
  }, [active]);

  const onUpdate = useCallback((e) => {
    progressTarget.current = e.progress;
  }, []);

  const animate = (time) => {
    if (!active) return;

    if (time - previousTimeRef.current > 1000 / 60) {
      setProgress(
        (prevProgress) =>
          prevProgress + (progressTarget.current - prevProgress) * 0.2
      );
      previousTimeRef.current = time;
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  const letterSpacing = useMemo(() => {
    return `${progress * (showMdAndUp ? 30 : 6) - (showMdAndUp ? 14 : 2)}px`;
  }, [progress, showMdAndUp]);

  return (
    <ScrollContainer
      id={'reach'}
      ignore={true}
      styles={styles}
      onEnter={onEnter}
      onEnterBack={onEnter}
      onLeave={onLeave}
      onLeaveBack={onLeave}
      onUpdate={onUpdate}
    >
      <div className="innerWrapper">
        <GlobeBG />

        <div className="innerContent">
          <Typography variant="h5">{content.titleTopMinimalTheme}</Typography>
          <div className="copy">
            <Typography variant="body1">{content.bodyCopy.bodyCopy}</Typography>
            {!!content.bodyCopyPart2.bodyCopyPart2 && (
              <Typography variant="body1">
                {content.bodyCopyPart2.bodyCopyPart2}
              </Typography>
            )}
          </div>
          <Typography variant={showMdAndUp ? 'h1' : 'h2'}>
            <span
              style={{
                letterSpacing: letterSpacing,
              }}
            >
              {content.titleBottomMinimalTheme}
            </span>
          </Typography>
        </div>
      </div>
    </ScrollContainer>
  );
};

export default MeetTheTeamSection;
