import { css } from '@emotion/react';
import { lighten } from '@material-ui/core';
import {
  minimalPointer,
  minimalPointerWhite,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  padding-bottom: ${theme.spacing(4)};

  .form-wrapper {
    display: flex;
    flex-direction: column;
  }

  .form-item-left,
  .form-item-right {
    width: 100%;
  }

  .form-item-right {
    .MuiFormGroup-root {
      flex-direction: row;
    }
  }

  .left-form-fields {
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(2, 0)};

    div {
      margin-bottom: 1rem;
    }

    .error-label {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  .selectors-wrapper {
    display: flex;
    flex-direction: column;
  }

  .select-headline {
    margin-bottom: 1.8rem;
  }

  .select-group {
    margin-bottom: 3rem;
  }

  .select-item {
    margin: 0 1.6rem 1.6rem 0;
    transition: 0.25s ease;
    &:hover {
      .MuiFormControlLabel-label {
        background-color: ${lighten(theme.palette.supply.salmon.main, 0.2)};
        transition: 0.25s ease;
        cursor: ${minimalPointer};
      }
    }
    .MuiFormControlLabel-label {
      border: 1px solid;
      border-radius: 100px;
      padding: 0.6rem 2rem;
    }

    .Mui-checked + .MuiFormControlLabel-label {
      background-color: ${theme.palette.text.primary};
      color: ${theme.palette.supply.salmon.main};
      border-color: ${theme.palette.text.primary};
    }
    &:hover {
      .Mui-checked + .MuiFormControlLabel-label {
        background-color: ${lighten(theme.palette.text.primary, 0.1)};
        transition: 0.25s ease;
        cursor: ${minimalPointerWhite};
      }
    }
  }

  .form-sent-success {
    text-align: center;
    margin: ${theme.spacing(4, 0, 0)};
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(8, 0, 0)};
    }
  }

  .btn-container {
    &:nth-of-type(2) {
      margin-left: auto;
      ${theme.breakpoints.down('sm')} {
        margin-top: 2rem;
      }
      .MuiButton-endIcon {
        ${theme.breakpoints.down('lg')} {
          display: none;
        }
      }
    }
  }
`;

export default styles;
