import React, { useState } from 'react';
import { Typography, Popover, Box } from '@material-ui/core';
import styles, { popoverStyles } from './partnerWith.styles';

const InfoButton = ({ content }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        className="info-icon"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      ></Box>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        css={popoverStyles}
      >
        <Box p={3}>
          <Typography>{content}</Typography>
        </Box>
      </Popover>
    </>
  );
};

export default InfoButton;
