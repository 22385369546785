import React, { useRef, createRef, useEffect } from 'react';
import { useLayoutContext } from '../../contexts/layoutContext';
import { Typography } from '@material-ui/core';
import styles, {
  inlineStyles,
  activeStyles,
  changingStyles,
} from './slidein.styles';

const SlideInTitle = ({ id = '', active, inline, children }) => {
  const { changing } = useLayoutContext();
  const titleLines = useRef(
    children.length ? children.map(() => createRef()) : children
  );

  return (
    <div
      css={[
        styles,
        inline ? inlineStyles : null,
        active ? activeStyles : null,
        changing ? changingStyles : null,
      ]}
    >
      <Typography
        variant="h1"
        component="div"
        className="slidein-title-wrapper"
      >
        {children.length > 1 ? (
          children.map((child, index) => (
            <div key={`title-${id}-line-${index}`} className="word-container">
              <div className="word-inner-wrapper">
                <div ref={titleLines.current[index]} className="fading-text">
                  {child}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div key={`title-${id}-line-0`} className="word-container">
            <div className="word-inner-wrapper">
              <div ref={titleLines} className="fading-text">
                {children}
              </div>
            </div>
          </div>
        )}
      </Typography>
    </div>
  );
};

export default SlideInTitle;
