import { css, keyframes } from '@emotion/react';

const styles = (theme) => css`
  border: 1px solid ${theme.palette.supply.darkGray};
  padding: ${theme.spacing(6, 2)};

  h2 {
    margin: ${theme.spacing(4, 0)};
    max-width: 50%;
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(4, 0, 0)};
      max-width: 100%;
    }
  }

  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(10, 8)};
  }

  .caption {
    display: block;
    margin: ${theme.spacing(0, 0, 6, 0.5)};
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(0, 0, 4, 1.5)};
    }
  }

  .video-container {
    aspect-ratio: 810 / 581;
    margin: ${theme.spacing(4, 0, 0)};

    img,
    video {
      width: 100%;
    }

    video {
      border: 1px solid ${theme.palette.supply.darkGray};
    }

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(4)};
      border: 1px solid ${theme.palette.supply.darkGray};
      margin: ${theme.spacing(8, 0, 0)};
    }
  }

  a {
    margin-top: ${theme.spacing(4)};
    width: 100%;
  }
`;

export default styles;
