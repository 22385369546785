import React, { useRef } from 'react';
import HeroSection from '../minimalComponents/hero';
import WhoWePartnerWith from '../minimalComponents/partner-with';
import Services from '../minimalComponents/services';
import Stats from '../minimalComponents/stats';
import WhyUs from '../minimalComponents/why-us';
import WideReach from '../minimalComponents/reach';
import Team from '../minimalComponents/team';
import Tools from '../minimalComponents/tools';
import Footer from '../minimalComponents/footer';
import ActionButtons from '../minimalComponents/actionButtons';

const Professional = ({ data, matches }) => {
  const wrapperRef = useRef(null);

  const {
    contentfulHeroSection,
    contentfulGivingBackSection,
    contentfulServicesSection,
    contentfulTools,
    contentfulWhoWeWorkWithSection,
    contentfulWhyUsSection,
    contentfulMeetTheTeam,
    contentfulOurNetworkModal,
    contentfulFooter,
  } = data;

  return (
    <React.Fragment>
      <HeroSection heroSection={contentfulHeroSection} />
      {/* {!firstLoadOut && ( */}
      {/* <React.Fragment> */}
      <WhoWePartnerWith whoWeWorkWithSection={contentfulWhoWeWorkWithSection} />
      <Services servicesSection={contentfulServicesSection} />
      <Stats statsSection={contentfulWhyUsSection} />
      <WhyUs
        whyUsSection={contentfulWhyUsSection}
        givingBackSection={contentfulGivingBackSection}
      />
      <Team meetTheTeamSection={contentfulMeetTheTeam} />
      <WideReach content={contentfulOurNetworkModal} />
      <Tools content={contentfulTools} />
      <Footer footer={contentfulFooter} />
      <ActionButtons ref={wrapperRef} content={contentfulHeroSection} />
      {/* </React.Fragment> */}
      {/* )} */}
    </React.Fragment>
  );
};

export default Professional;
