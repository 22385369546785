import { css, keyframes } from '@emotion/react';
import { lighten, darken } from '@material-ui/core';
import {
  minimalCursorWhite,
  minimalPointerWhite,
  minimalInitiallyHidden,
  minimalRevealed,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.supply.darkGray};
  color: ${theme.palette.supply.cream.main};
  padding: ${theme.spacing(2, 2, 12, 2)};
  cursor: ${minimalCursorWhite};
  min-height: 100vh;

  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(10, 4, 16, 4)};
  }

  .header {
    position: relative;

    ${theme.breakpoints.down('md')} {
      margin: ${theme.spacing(10, 0, 8, 0)};
    }
    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacing(16)};
    }

    .hiring-pill {
      position: absolute;
      top: -${theme.spacing(4)};
      right: 5%;
      background: ${theme.palette.supply.eraser.main};
      border-radius: 50px;
      padding: ${theme.spacing(1, 2)};
      color: ${theme.palette.supply.darkGray};
      text-transform: uppercase;
      transform: rotate(20deg) scale(0.8);
      transition: all 250ms ${theme.transitions.easing.outBack};
      transform-origin: center center;
      opacity: 0;
      
      ${theme.breakpoints.up('md')} {
        top: -${theme.spacing(2)};
        right: 10%;
      }
      
      &.active {
        opacity: 1;
        transform: rotate(10deg) scale(1);
        transition-delay: 800ms;
      }
    }
  }

  .profile {
    border: 1px solid ${theme.palette.supply.cream.main};
    border-bottom: none;
    ${minimalInitiallyHidden}

    &:last-child {
      border-bottom: 1px solid ${theme.palette.supply.cream.main};
    }

    .image-wrapper {
      position: relative;=
      width: 100%;
      aspect-ratio: 1 / 1;
      overflow: hidden;

      img {
        transform-origin: center center;
        transform: translateZ(1px) scale(1);
        // transform: translateZ(1px) scale(1.15);
        // transition: transform 5000ms ${theme.transitions.easing.inOutSine};
        // transition: transform 5000ms ease-in-out;
        height: 100%;
      }
    }

    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: ${theme.spacing(4, 0)};
      transition: color 200ms ${theme.transitions.easing.inOutSine};
      h5 {
        width: 50%;
      }
      a {
        cursor: ${minimalPointerWhite};

        svg {
          width: 30px;
          .background {
            transition: fill 200ms ${theme.transitions.easing.inOutSine};
          }
        }
      }
    }

    .profile-content {
      position: relative;
      height: 100%;
      padding: ${theme.spacing(2, 2, 6, 2)};
      background: ${theme.palette.supply.darkGray};
      transition: background 200ms ${theme.transitions.easing.inOutSine};
    }

    ${theme.breakpoints.up('sm')} {
      border-bottom: 1px solid ${theme.palette.supply.cream.main};
      border-right: none;
      &:last-child {
        border-right: 1px solid ${theme.palette.supply.cream.main};
      }
    }

    ${theme.breakpoints.up('md')} {
      .profile-content {
        padding: ${theme.spacing(4, 4, 6, 4)};
      }
    }

    &:hover {
      .profile-content {
        background: ${darken(theme.palette.supply.darkGray, 0.2)};
      }
      .name {
        color: ${theme.palette.supply.salmon.main};
        svg {
          .background {
            fill: ${theme.palette.supply.salmon.main};
          }
          &:hover {
            .background {
              fill: ${theme.palette.supply.eraser.main};
            }
          }
        }
      }

      // .image-wrapper {
      //   img {
      //     transform: translateZ(1px) scale(1.15);
      //   }
      // }
    }
  }

  button {
    margin-top: ${theme.spacing(4)};
    width: 100%;
  }
`;

export const activeStyles = (theme) => css`
  .profile {
    ${minimalRevealed}
    &:nth-of-type(2) {
      transition-delay: 500ms;
    }
    &:nth-of-type(3) {
      transition-delay: 600ms;
    }
  }
`;

export const changingStyles = (theme) => css`
  .profile {
    &:nth-of-type(1) {
      transition-delay: 700ms;
    }
    &:nth-of-type(2) {
      transition-delay: 800ms;
    }
    &:nth-of-type(3) {
      transition-delay: 900ms;
    }
  }

  .header {
    .hiring-pill {      
      &.active {
        transition-delay: 1000ms;
      }
    }
`;

export default styles;
