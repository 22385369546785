import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  TextField,
  Button,
} from '@material-ui/core';
import { ChatMessages } from './chatUtils';
import EmailContainer from '../email/email-container';
import { newChatBaseStyles } from './chat.styles';

const Chat = ({ state, onSendMessage, scrollToRef, onCloseChat, copy }) => {
  const [message, setMessageBody] = useState('');
  const [checked, setChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { messages = [], customerId, isLoaded } = state;
  const emailDescriptionRef = useRef(null);
  const emailButtonRef = useRef(null);
  const chatDisclaimerRef = useRef(null);
  const formRef = useRef(null);
  const sendButtonRef = useRef(null);

  const handleChangeMessage = (e) => setMessageBody(e.target.value);

  copy.disableChatToggle = true;

  useEffect(() => {
    setTimeout(() => {
      setAnchorEl(sendButtonRef.current);
    }, 670);
  }, [sendButtonRef]);

  const handleSendMessage = (e) => {
    onSendMessage({ body: message });
    setMessageBody('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage();
  };

  const chatWrapperRef = useRef();
  const chatScrollTO = useRef();
  const [chatScrolling, setChatScrolling] = useState(true);

  const emailWrapperRef = useRef();
  const emailScrollTO = useRef();
  const [emailScrolling, setEmailScrolling] = useState(true);

  const onScroll = useCallback((e) => {
    if (emailScrollTO.current) clearTimeout(emailScrollTO.current);
    setEmailScrolling(true);
    emailScrollTO.current = setTimeout(() => {
      setEmailScrolling(false);
    }, 1000);
  }, []);

  const onChatScroll = useCallback((e) => {
    if (chatScrollTO.current) clearTimeout(chatScrollTO.current);
    setChatScrolling(true);
    chatScrollTO.current = setTimeout(() => {
      setChatScrolling(false);
    }, 1000);
  }, []);

  useEffect(() => {
    emailWrapperRef.current.addEventListener('scroll', onScroll);
    chatWrapperRef.current.addEventListener('scroll', onChatScroll);

    return () => {
      if (emailScrollTO.current) clearTimeout(emailScrollTO.current);
      if (emailWrapperRef.current)
        emailWrapperRef.current.removeEventListener('scroll', onScroll);

      if (chatScrollTO.current) clearTimeout(chatScrollTO.current);
      if (chatWrapperRef.current)
        chatWrapperRef.current.removeEventListener('scroll', onChatScroll);
    };
  }, []);

  return (
    <div css={newChatBaseStyles}>
      <div
        className={`chat-body email ${checked ? 'is-active' : ''} ${
          emailScrolling ? 'is-scrolling' : ''
        }`}
        ref={emailWrapperRef}
      >
        <EmailContainer setChecked={setChecked} />
      </div>
      <div className={`chat-body chat ${!checked ? 'is-active' : ''}`}>
        <Typography ref={emailDescriptionRef} variant="body1">
          {copy.chatDescription.chatDescription}
        </Typography>

        <div
          className={`message-interior ${chatScrolling ? 'is-scrolling' : ''}`}
          ref={chatWrapperRef}
        >
          {isLoaded ? (
            <ChatMessages
              messages={messages}
              customerId={customerId}
              scrollToRef={scrollToRef}
            />
          ) : (
            <div className="loading-messages">
              <CircularProgress color="secondary" />
            </div>
          )}
        </div>

        <Grid container className="message-input-container">
          {copy.disableChatToggle ? (
            <Typography variant="body1" className="disabled-chat-copy">
              {/* {copy.chatDisabledCopy.chatDisabledCopy.replace(
                 'info@thesupply.com',
                 ''
               )}
               &nbsp;
               <a href="mailto:info@thesupply.com" target="_blank">
                 info@thesupply.com
               </a> */}
              We're working on some AI chatbot fun, for now, use the "send
              email" button below please.
            </Typography>
          ) : (
            <form onSubmit={handleSubmit} ref={formRef}>
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    value={message}
                    placeholder="type your message here"
                    onChange={handleChangeMessage}
                    variant="outlined"
                    className="chat-input"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    size="small"
                    onClick={(e) => handleSendMessage(e)}
                    className="chat-btn"
                    ref={sendButtonRef}
                  >
                    →
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>

        {copy.disableChatToggle ? (
          <></>
        ) : (
          <Typography
            ref={chatDisclaimerRef}
            variant="caption"
            className="disclaimer"
          >
            {copy.chatDisclaimer.chatDisclaimer}
          </Typography>
        )}
      </div>

      <div className="footer">
        <Button
          className="button email"
          variant="contained"
          color="primary"
          onClick={() => setChecked(!checked)}
        >
          <Typography variant="button">
            {checked ? 'Go Back' : 'Send Email'}
          </Typography>
          <span className="arrow-icon">↑</span>
        </Button>
        <Button
          className="button close"
          variant="contained"
          color="primary"
          onClick={onCloseChat}
        >
          <Typography variant="button">Close Chat</Typography>
          <span className="arrow-icon">×</span>
        </Button>
      </div>
    </div>
  );
};

export default Chat;

// {copy.disableChatToggle ? (
//   <Box className="d-pop-vis">
//     <Popover
//       id="disabled-chat-popover"
//       // style={{ pointerEvents: 'none' }}
//       open={open}
//       onClose={handlePopoverClose}
//       anchorEl={anchorEl}
//       anchorOrigin={{
//         vertical: 'top',
//         horizontal: 'center',
//       }}
//       transformOrigin={{
//         vertical: 'center',
//         horizontal: 'center',
//       }}
//     >
//       <Box p={3} style={{ backgroundColor: '#A0AEAA' }}>
//         <Typography
//           variant="body1"
//           className="disabled-chat-copy"
//         >
//           {copy.chatDisabledCopy.chatDisabledCopy}
//           &nbsp;
//           <a
//             href="mailto:info@thesupply.com"
//             target="_blank"
//           >
//             info@thesupply.com
//           </a>
//         </Typography>
//       </Box>
//     </Popover>
//     <Button
//       fullWidth
//       className="send-msg-btn"
//       variant="contained"
//       color="primary"
//       ref={sendButtonRef}
//       disabled
//       aria-owns={'disable-chat-popover'}
//       aria-haspopup="true"
//     >
//       Send
//     </Button>
//   </Box>
// ) : (
//   <Button
//     fullWidth
//     onClick={(e) => handleSendMessage(e)}
//     className="send-msg-btn"
//     variant="contained"
//     color="primary"
//     ref={sendButtonRef}
//   >
//     Send
//   </Button>
// )}
