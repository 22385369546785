import { css } from '@emotion/react';
import { initiallyHidden } from '../shared/shared.styles';

const styles = (theme) => css`
  background-color: ${theme.palette.supply.cream.main};
  min-height: 100vh;

  .tools-description {
    ${initiallyHidden}
  }
  .tools-header-border {
    border: 3px solid;
    text-align: center;
    border-radius: 20px;
    padding: 4rem 2rem 4.5rem;
    margin-bottom: 8rem;
    ${theme.breakpoints.up('md')} {
      border-radius: 170px;
      padding: 6rem 2rem 6rem;
    }
  }

  .tools-title {
    margin-top: 0.5rem;
    div {
      display: inline-block;
      margin: 0 1rem;
    }
  }

  .tool-item-container {
    ${initiallyHidden}
    text-align: center;
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 4rem;
    }
    ${theme.breakpoints.up('md')} {
      text-align: left;
    }

    .star {
      position: relative;
      display: inline-block;
      width: 48px;
      aspect-ratio: 1 / 1;
    }
  }

  .tool-name {
    margin: 0.5rem 0 1rem;
  }

  .tool-link {
    margin-top: 1.1rem;
    color: ${theme.palette.supply.orange.main};

    a,
    span {
      position: relative;
      color: ${theme.palette.supply.orange.main};
      text-decoration: none;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 1px;
        width: 100%;
        height: 1px;
        background: ${theme.palette.supply.orange.main};
        transform-origin: center right;
        transform: scaleX(0);
        transition: transform 0.2s ease-in-out;
      }
      &:hover {
        &:after {
          transform-origin: center left;
          transform: scaleX(1);
        }
      }
    }
  }

  .line {
    height: 3px;
    width: 0px;
    display: inline-block;
    vertical-align: middle;
    background-color: ${theme.palette.text.primary};
    overflow: hidden;
    opacity: 0;
    margin: 0 1rem;
    transition: all 0.5s ease;
    transform: translate(0%, 0);
  }

  .line-animate {
    width: 200px;
    opacity: 1;
    transition: all 0.5s ease;
  }
`;

export default styles;
