import React, { useEffect, useState, useRef, createRef } from 'react';
import { stripCharacters } from '../../utils/utils';
import useScrollAnimation from '../../hooks/use-scroll-animation';
import gsap from 'gsap';
import { categories } from './categories';
import { Container, Popover, Typography, Grid, Box } from '@material-ui/core';
import TabContent from './TabContent';
import styles, { popoverStyles } from './whoWePartnerWith.styles';
import PartnerTabs from './PartnerTabs';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ScrollContainer from '../scroll-container';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pb={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const WhoWePartnerWith = ({ whoWeWorkWithSection }) => {
  const [value, setValue] = useState(0);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const smAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm)
  );

  //animations that are scroll triggered
  const wrapper = useRef(null);
  const headline = useRef(null);
  const buttons = useRef(null);
  const talentTabHeadlines = useRef(
    whoWeWorkWithSection.talentItem.map(() => createRef())
  );
  const clientTabHeadlines = useRef(
    whoWeWorkWithSection.clientItem.map(() => createRef())
  );

  //the scroll triggered animation
  useScrollAnimation(1, wrapper, [
    headline,
    buttons,
    ...talentTabHeadlines.current,
  ]);

  //when the tab changes
  useEffect(() => {
    let tl = gsap.timeline({ pause: true });
    let elements =
      value === 0 ? talentTabHeadlines.current : clientTabHeadlines.current;
    function handleAnimateIn(els) {
      for (let [index, value] of els.entries()) {
        const delayVal = index ? 0.45 - index / 15 : 0;
        const anim = tl.to(
          [...value.current.getElementsByClassName('title-char')],
          0.5,
          {
            y: 0,
            opacity: 1,
            stagger: 0.014,
            rotate: 0,
            ease: 'Power3.easeOut',
          },
          `-=${delayVal}`
        );
        tl.add(anim);
      }
      tl.play();
    }
    handleAnimateIn(elements);
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  function getItems(categoryStr) {
    const category = stripCharacters(categoryStr);
    return categories[category].items;
  }

  function getIcons(categoryStr) {
    const category = stripCharacters(categoryStr);
    return categories[category].icons;
  }

  function onSetItemActive(index) {
    setCurrentCategoryIndex(index);
  }

  function onSetItemInactive() {
    setCurrentCategoryIndex(null);
  }

  return (
    <ScrollContainer id={1}>
      <Container
        maxWidth={false}
        css={styles}
        className="section-padding"
        ref={wrapper}
      >
        <Container maxWidth="lg" className="side-padding mobile-container">
          <Grid container className="partner-section-container">
            <Grid item xs={12}>
              <Typography
                className="partner-with-headline"
                variant="h6"
                component="h2"
                ref={headline}
              >
                {whoWeWorkWithSection.whoWeWorkWithTitle}
              </Typography>
              <PartnerTabs
                handleChange={handleChange}
                sections={whoWeWorkWithSection.talentclientToggle}
              />
              <TabPanel value={value} index={0}>
                {whoWeWorkWithSection.talentItem.map((talentItem, index) => (
                  <TabContent
                    key={`${stripCharacters(
                      talentItem.jobCategory
                    )}-item-${index}`}
                    items={getItems(talentItem.jobCategory)}
                    title={talentItem.jobCategory}
                    icons={getIcons(talentItem.jobCategory)}
                    onSetActive={onSetItemActive}
                    onSetInactive={onSetItemInactive}
                    index={index}
                    value={value}
                    inactive={
                      currentCategoryIndex !== null &&
                      index !== currentCategoryIndex
                    }
                    ref={talentTabHeadlines.current[index]}
                  />
                ))}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {whoWeWorkWithSection.clientItem.map((clientItem, index) => (
                  <TabContent
                    key={`${stripCharacters(
                      clientItem.specialty
                    )}-item-${index}`}
                    items={getItems(clientItem.specialty)}
                    title={clientItem.specialty}
                    icons={getIcons(clientItem.specialty)}
                    onSetActive={onSetItemActive}
                    onSetInactive={onSetItemInactive}
                    index={index}
                    inactive={
                      currentCategoryIndex !== null &&
                      index !== currentCategoryIndex
                    }
                    ref={clientTabHeadlines.current[index]}
                  />
                ))}
              </TabPanel>
              <Box mt={smAndUp ? 6 : 0}>
                <Box
                  className="tooltip-container"
                  pt={3}
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onClick={handlePopoverOpen}
                >
                  <img
                    src={`images/info-icon.svg`}
                    alt="hover for more info"
                    className="info-tooltip"
                  />
                </Box>
                <Popover
                  id="mouse-over-popover"
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  css={popoverStyles}
                >
                  <Box p={3}>
                    <Typography>
                      {
                        whoWeWorkWithSection.questionTooltipCopy
                          .questionTooltipCopy
                      }
                    </Typography>
                  </Box>
                </Popover>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </ScrollContainer>
  );
};

export default WhoWePartnerWith;
