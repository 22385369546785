import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { useGesture } from '@use-gesture/react';
import styles from './stats.styles';

const colors = ['orange', 'green', 'salmon', 'eraser', 'cobalt'];

let statCover = (content) => {
  return {
    value: '+',
    content: (
      <>
        <Grid container spacing={2}>
          {content.titleCardColumns.map((col, i) => (
            <Grid
              item
              container
              xs={12}
              md={12 / content.titleCardColumns.length}
              alignContent="flex-end"
              key={`col-${i}`}
              className="stats-cover"
            >
              <Typography variant="body2">{col}</Typography>
            </Grid>
          ))}
        </Grid>
      </>
    ),
    color: 'cream',
  };
};

const Stat = ({
  index,
  content,
  moveCB,
  hoverCB,
  offset,
  rotation,
  zIndex,
  totalStats,
}) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.lg));
  const isMd = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));

  const [dragging, setDragging] = useState(false);
  const [hovering, setHovering] = useState(false);

  const [movementStyles, movementApi] = useSpring(() => ({
    x: 0,
    y: 0,
    rotate: 0,
    transform: `translate3d(0px, 0px, 0px) rotate(0deg)`,
    // config: { mass: 5, tension: 350, friction: 40 },
    config: { mass: 1, tension: 800, friction: 80 },
  }));

  useEffect(() => {
    movementApi({
      x: offset[0] || 0,
      y: offset[1] || 0,
      rotate: rotation || 0,
      transform: `translate3d(${offset[0] || 0}px, ${
        (offset[1] || 0) - (hovering ? 50 : 0)
      }px, 0px) rotate(-${rotation || 0}deg)`,
    });
  }, [offset, rotation, hovering]);

  const onDrag = ({ down, tap, movement: [mx, my] }) => {
    setDragging(down);

    if (tap) {
      moveCB(index, true, false, [0, -20]);
      hoverCB(index, true);
    } else if (down) {
      moveCB(index, true, false, [mx, my]);
    } else {
      moveCB(index, false, false, [0, 0]);
    }
  };

  const onHover = ({ hovering, ...state }) => {
    setHovering(hovering);
    hoverCB(index, hovering);
    if (!hovering) moveCB(index, false, true, [0, 0]);
  };

  const onMove = ({ xy: [px, py], dragging, hovering }) => {
    if (!dragging && hovering) {
      moveCB(index, false, true, [
        (px / window.innerWidth) * 50,
        (py / window.innerHeight) * 50,
      ]);
    }
  };

  const config = {
    drag: { filterTaps: true },
    hover: { mouseOnly: true },
    move: { mouseOnly: true },
  };

  const bind = useGesture(
    {
      onDrag: onDrag,
      onHover: onHover,
      onMove: onMove,
    },
    config
  );

  return (
    <animated.div
      className="statBox"
      {...bind()}
      style={{
        ...movementStyles,
        marginLeft: `${
          index * ((isLg ? 50 : isMd ? 33 : 0) / (totalStats - 1))
        }%`,
        color: theme.palette.supply[content.color].main,
        zIndex,
        cursor: dragging ? 'grabbing' : 'grab',
      }}
    >
      <div className="stat">
        <Typography variant="h3">{content.value}</Typography>
        {content.measurement && (
          <Typography variant="h5">{content.measurement}</Typography>
        )}
      </div>

      {content.statistic?.statistic ? (
        <Typography variant="body2">{content.statistic.statistic}</Typography>
      ) : content.content ? (
        content.content
      ) : null}
    </animated.div>
  );
};

const StatsSection = ({ statsSection }) => {
  const theme = useTheme();
  const wrapperRef = useRef();
  const isSm = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const stats = useMemo(() => {
    let _stats = statsSection.statisticItem.filter((s) => s.showInMinimalTheme);
    _stats.forEach((stat, i) => {
      stat.color = colors[i % colors.length];
    });
    _stats.push(statCover(statsSection));
    return _stats;
  }, [statsSection]);

  const [offsets, setOffsets] = useState([]);
  const [zIndexes, setZIndexed] = useState([]);
  const [rotations, setRotations] = useState([]);
  const [isGrabbing, setIsGrabbing] = useState(true);
  const [canGrab, setCanGrab] = useState(true);

  const updateZIndexes = (index) => {
    let _zIndexes = [];
    for (let i = 0; i < stats.length; i++) {
      let diff = i <= index ? index - i : i;
      _zIndexes.push(stats.length - diff);
    }
    setZIndexed(_zIndexes);
  };

  const hoverCB = (index, hovering) => {
    let _rotations = [];
    // let _offsets = [];

    if (!canGrab) return;

    updateZIndexes(index);

    for (let i = 0; i < stats.length; i++) {
      if (hovering) {
        // if (i === index) {
        //   _rotations.push(((i + 1) / (index + 1)) * 4);
        // } else {
        //   _rotations.push(0);
        // }

        if (i === index) {
          _rotations.push(4);
        } else if (i < index) {
          _rotations.push(((i + 1) / (index + 1)) * 2);
        } else {
          _rotations.push(0);
        }
      } else {
        _rotations.push(0);
      }
    }

    setRotations(_rotations);
  };

  const moveCB = (index, grabbing, hovering, position) => {
    let _offsets = [];

    if (!grabbing && isGrabbing) {
      setCanGrab(false);
      setTimeout(() => setCanGrab(true), 300);
    }

    setIsGrabbing(grabbing);

    if ((canGrab || grabbing) && !isSm) updateZIndexes(index);

    for (let i = 0; i < stats.length; i++) {
      if (grabbing || hovering) {
        // _offsets.push([position[0], position[1]]);

        // if (i === index) {
        //   _offsets.push([position[0], position[1]]);
        // } else {
        //   _offsets.push([0, 0]);
        // }

        if (i === index) {
          _offsets.push([position[0], position[1]]);
        } else if (i < index) {
          _offsets.push([
            // (i / index) * position[0] * 0.3,
            // (i / index) * position[1] * 0.2,
            ((i + 1) / (index + 1)) * position[0] * 0.1,
            ((i + 1) / (index + 1)) * position[1] * 0.05,
          ]);
        } else if (i > index) {
          // _offsets.push([0, 0]);
          _offsets.push([
            ((i + 1) / (index + 1)) * position[0] * 0.02,
            ((i + 1) / (index + 1)) * position[1] * -0.02,
          ]);
        }
      } else {
        _offsets.push([0, 0]);
      }
    }

    setOffsets(_offsets);
  };

  return (
    <div css={styles} id="section3">
      <div className="inner-content" ref={wrapperRef}>
        {stats.map((stat, index) => (
          <Stat
            key={`stat-${index}`}
            index={index}
            content={stat}
            moveCB={moveCB}
            hoverCB={hoverCB}
            offset={offsets[index] || [0, 0]}
            zIndex={zIndexes[index] || 'auto'}
            rotation={rotations[index] || 0}
            totalStats={stats.length}
          />
        ))}
      </div>
    </div>
  );
};

export default StatsSection;
