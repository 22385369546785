import React from 'react';
import { css } from '@emotion/react';
import { darken } from '@material-ui/core';

const styles = (theme) => css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1/0.666;
  left: 50%;
  transform: translateX(-50%);
  max-width: none;
  z-index: 0;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;

    path {
      stroke: ${darken(theme.palette.supply.cream.main, 0.5)};
      opacity: 0.2;

      &.vertical {
        animation: lighten 3s linear infinite;

        &:nth-of-type(1) {
          animation-delay: 3.6s;
        }
        &:nth-of-type(2) {
          animation-delay: 3.4s;
        }
        &:nth-of-type(3) {
          animation-delay: 3.2s;
        }
        &:nth-of-type(4) {
          animation-delay: 3s;
        }
      }
    }
  }

  @keyframes lighten {
    0% {
      opacity: 0.2;
      transition-timing-function: ${theme.transitions.easing.outQuad};
    }
    15% {
      opacity: 1;
      transition-timing-function: ${theme.transitions.easing.inQuad};
    }
    30% {
      opacity: 0.2;
      transition-timing-function: ${theme.transitions.easing.outQuad};
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const GlobeBG = () => {
  return (
    <div css={styles}>
      <svg
        width="1200"
        height="806"
        viewBox="0 0 1200 806"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="vertical"
          d="M1199.5 403.421C1199.5 514.373 1132.47 614.88 1023.99 687.672C915.5 760.462 765.6 805.5 600 805.5C434.4 805.5 284.5 760.462 176.015 687.672C67.5257 614.88 0.5 514.373 0.5 403.421C0.5 292.47 67.5257 191.963 176.015 119.171C284.5 46.3805 434.4 1.34277 600 1.34277C765.6 1.34277 915.5 46.3805 1023.99 119.171C1132.47 191.963 1199.5 292.47 1199.5 403.421Z"
          stroke="#F8F7F2"
        />
        <path
          className="vertical"
          d="M997.253 403.421C997.253 625.489 819.391 805.5 600 805.5C380.609 805.5 202.747 625.489 202.747 403.421C202.747 181.354 380.609 1.34277 600 1.34277C819.391 1.34277 997.253 181.354 997.253 403.421Z"
          stroke="#F8F7F2"
        />
        <path
          className="vertical"
          d="M855.68 403.421C855.68 514.51 827.026 615.058 780.725 687.819C734.42 760.586 670.512 805.5 600 805.5C529.488 805.5 465.58 760.586 419.275 687.819C372.974 615.058 344.32 514.51 344.32 403.421C344.32 292.333 372.974 191.784 419.275 119.024C465.58 46.2572 529.488 1.34277 600 1.34277C670.512 1.34277 734.42 46.2572 780.725 119.024C827.026 191.784 855.68 292.333 855.68 403.421Z"
          stroke="#F8F7F2"
        />
        <path
          className="vertical"
          d="M693.882 403.421C693.882 514.564 683.32 615.168 666.251 687.973C657.716 724.38 647.56 753.807 636.3 774.121C630.67 784.278 624.777 792.133 618.692 797.444C612.611 802.753 606.362 805.5 600 805.5C593.638 805.5 587.389 802.753 581.307 797.444C575.223 792.133 569.329 784.278 563.7 774.121C552.44 753.807 542.284 724.38 533.749 687.973C516.68 615.168 506.118 514.564 506.118 403.421C506.118 292.279 516.68 191.675 533.749 118.869C542.284 82.4629 552.44 53.0355 563.7 32.7218C569.329 22.5648 575.223 14.7094 581.307 9.39843C587.389 4.09028 593.638 1.34277 600 1.34277C606.362 1.34277 612.611 4.09028 618.692 9.39843C624.777 14.7094 630.67 22.5648 636.3 32.7218C647.56 53.0355 657.716 82.4629 666.251 118.869C683.32 191.675 693.882 292.279 693.882 403.421Z"
          stroke="#F8F7F2"
        />
      </svg>
    </div>
  );
};

export default GlobeBG;
