import { css, keyframes } from '@emotion/react';
import {
  minimalCursorWhite,
  minimalPointerWhite,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: block;
  position: relative;
  background-color: ${theme.palette.supply.darkGray};
  color: ${theme.palette.supply.cream.main};
  padding: ${theme.spacing(12, 2)};
  cursor: ${minimalCursorWhite};

  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(16, 4)};
  }

  .statBox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.spacing(2)};
    width: 100;
    height: 360px;
    background-color: ${theme.palette.supply.darkGray};
    border: 1px solid ${theme.palette.supply.cream.main};
    margin-top: -270px;
    transform-origin: center left;
    cursor: ${minimalPointerWhite};
    ${theme.breakpoints.up('md')} {
      touch-action: none;
    }

    * {
      pointer-events: none;
      user-select: none;
    }

    &:nth-of-type(1) {
      margin-top: 0px;
    }

    ${theme.breakpoints.up('md')} {
      width: 66.666%;
      padding: ${theme.spacing(2, 4)};
      margin-top: -290px;
    }
    ${theme.breakpoints.up('lg')} {
      width: 50%;
      padding: ${theme.spacing(2, 6)};
      margin-top: -280px;
    }

    .stat {
      display: flex;
      align-items: flex-end;

      h3 {
        ${theme.breakpoints.down('sm')} {
          font-size: 6rem;
        }
      }

      h5 {
        margin-bottom: ${theme.spacing(1)};
        ${theme.breakpoints.down('sm')} {
          font-size: 2.2rem;
        }
      }
    }
  }
`;

export default styles;
