import { css } from '@emotion/react';
import {
  minimalInitiallyHidden,
  minimalRevealed,
  minimalCursor,
} from '../../components/shared/shared.styles';

const styles = (theme) => css`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${theme.palette.background.default};
  padding: ${theme.spacing(10, 4, 16)};

  .content {
    padding: ${theme.spacing(0, 2)};

    .header {
      margin-bottom: ${theme.spacing(8)};
    }

    .intro {
      ${minimalInitiallyHidden}
    }
  }

  .hover-box {
    position: fixed;
    width: 300px;
    transform: scale(0.8) rotate(-5deg) translate3d(40px, -40%, 1px);
    z-index: 9999;
    background-color: ${theme.palette.supply.darkGray};
    color: ${theme.palette.supply.cream.main};
    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ${theme.transitions.easing.inOutQuad},
      transform 250ms ${theme.transitions.easing.outBack} 500ms;

    &.active {
      opacity: 1;
      transform: scale(1) rotate(2.5deg) translate3d(40px, -40%, 1px);
      transition: opacity 250ms ${theme.transitions.easing.inOutQuad},
        transform 250ms ${theme.transitions.easing.outBack};
    }
  }

  .box-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 550px;
    background-color: ${theme.palette.supply.cream.main};
    border: 1px solid ${theme.palette.supply.darkGray};
    overflow: hidden;
    margin-top: ${theme.spacing(8)};

    .tool {
      position: relative;
      flex-shrink: 0;
      flex-grow: 0;
      border-right: 1px solid ${theme.palette.supply.darkGray};
      will-change: width;
      ${minimalInitiallyHidden}
      overflow: hidden;
      &:last-child {
        overflow: visible;
        border-right: none;
      }

      .tool-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: ${theme.spacing(8)};

        p,
        a,
        button {
          opacity: 0;
          transition: opacity 0.25s ${theme.transitions.easing.inQuad};

          &.salary-button {
            background-color: #dad9d5;
            color: #a1a19d;
            :hover {
              background-color: #dad9d5;
              color: #a1a19d;
              cursor: ${minimalCursor};
            }
          }
        }
      }

      &.active {
        .tool-content {
          p {
            transition: opacity 0.75s ${theme.transitions.easing.outQuad} 0.2s;
            opacity: 1;
          }
          a,
          button {
            opacity: 1;
            transition: opacity 0.75s ${theme.transitions.easing.outQuad} 0.3s;
          }
        }
      }
    }
  }
`;

export const activeStyles = (theme) => css`
  .content {
    .intro {
      ${minimalRevealed}
    }
  }

  .box-container .tool {
    ${minimalRevealed}
    transition-delay: 500ms;
  }
`;

export const changingStyles = (theme) => css`
  .content {
    .intro {
      transition-delay: 700ms;
    }
  }

  .box-container {
    .tool {
      &:nth-of-type(1) {
        transition-delay: 800ms;
      }
      &:nth-of-type(2) {
        transition-delay: 900ms;
      }
      &:nth-of-type(3) {
        transition-delay: 1000ms;
      }
      &:nth-of-type(4) {
        transition-delay: 1100ms;
      }
    }
  }
`;

export default styles;
